import React, { useEffect, useState } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { IRow } from '@models/Forms/IForms';
import { IFieldElem } from '@models/IFormData';
import { FormulaManager } from '@utils/managers/FormulaManager';
import Column from '../Column/Column';
import './Row.scss';
import { handlerFieldWatch } from '@utils/fieldWatchUtils';
import { IUniqueKey } from '@molecules/formbuilder/interfaces/IUniqueKey';
import { IDocumentScheme } from '@models/document/IDocumentScheme';

export interface IRowProps<TFieldValues extends object = object> extends IUniqueKey {
    row?: IRow;
    formMethods: UseFormReturn<TFieldValues>;
    setError: (errors?: string[]) => void;
    isEdit: boolean;
    isNew: boolean;
    fields: Record<string, IFieldElem>;
    includedFields: number[];
    docId?: string;
    docScheme?: IDocumentScheme;
}

const Row = <TFieldValues extends object = object>({
    row,
    formMethods,
    setError,
    isEdit,
    isNew,
    fields,
    includedFields,
    docId,
    uniqueKey,
    docScheme,
    ...props
}: IRowProps<TFieldValues>) => {
    let rules = row?.visibilityRules;
    const visibilityMng = new FormulaManager(rules!);
    visibilityMng.Init(fields, formMethods);
    const activated = React.useRef(false);
    const [visibility, setVisibility] = useState<boolean>(false);
    const InitFormulas = async () => {
        let result = await visibilityMng.EvalFormulaValues(isEdit, isNew);
        if (activated.current) {
            setVisibility(result);
        }
    };
    useEffect(() => {
        activated.current = true;
        InitFormulas();
        return () => {
            activated.current = false;
        };
    }, []);
    const watch = useWatch({
        name: visibilityMng.GetWatchFields(),
    });

    useEffect(() => {
        handlerFieldWatch(watch, isEdit, isNew, visibilityMng, setVisibility, activated);
    }, [watch, isEdit, isNew]);

    return row && row.columns && visibility ? (
        <div className="form-row" data-testid={row.id ? `row-${row.id}` : undefined}>
            {row.columns.map((item, i) => (
                <Column
                    column={item}
                    fields={fields}
                    docId={docId}
                    formMethods={formMethods}
                    setError={setError}
                    isEdit={isEdit}
                    isNew={isNew}
                    key={uniqueKey + i}
                    includedFields={includedFields}
                    uniqueKey={uniqueKey}
                    docScheme={docScheme}
                />
            ))}
        </div>
    ) : (
        <></>
    );
};

export default Row;
