import React from 'react';
import { IBaseAction } from '@models/actions/IBaseAction';
import { DocBaseActionExecutor } from '@organisms/actions/DocBaseActionExecutor';
import { ModalSize } from '@/types/ModalSize';
import { ICreateDocumentLinkExecutorProps } from './ICreateDocumentLinkExecutorProps';
import { CreateDocumentLinkExecutorModal } from './CreateDocumentLinkExecutorModal';

export class CreateDocumentLinkExecutor extends DocBaseActionExecutor {
    private _modalProps?: ICreateDocumentLinkExecutorProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            docId: objId ?? '',
            actionKey: action.key ?? '',
            title: action.options?.title ?? '',
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'max',
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <CreateDocumentLinkExecutorModal {...this._modalProps} /> : <div />;
    };
}
