import React from 'react';
import { IBaseAction } from '@models/actions/IBaseAction';
import { IActionExecutor } from '@organisms/actions/IActionExecutor';
import { ModalSize } from '@/types/ModalSize';
import { RemoveFromControlModal } from './RemoveFromControlModal';

export class RemoveFromControlExecutor implements IActionExecutor {
    private _completeHandler?: (isSucceed: boolean) => void;
    private _objId: string = '';
    private _okButtonText: string = '';
    private _cancelButtonText: string = '';
    private _modalSize: ModalSize = 'm';

    run = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        raiseVisualElement?: () => void,
        modalSize?: ModalSize | null,
    ) => {
        this._completeHandler = completeHandler;
        this._objId = objId;
        this._okButtonText = action.options?.okButtonText ?? 'ОК';
        this._cancelButtonText = action.options?.cancelButtonText ?? 'Отмена';
        this._modalSize = modalSize ?? action.options?.modalSize ?? 'm';
    };

    visualElement = () => {
        return (
            <RemoveFromControlModal
                completeHandler={this._completeHandler}
                objId={this._objId}
                okButtonText={this._okButtonText}
                cancelButtonText={this._cancelButtonText}
                modalSize={this._modalSize}
            />
        );
    };
}
