import React from 'react';
import Moment from 'moment';
import Checkbox from '../Checkbox';
import { getValueBoolean } from '@utils/documentUtils';
import './DisplayField.scss';
import { ValueType } from '@/types/ValueType';
import clsx from 'clsx';

export interface IDisplayFieldProps {
    type: ValueType;
    value: any;
    label?: string;
    floatPoints?: number;
    trueText?: string;
    falseText?: string;
    className?: string;
}

const DisplayField: React.FC<IDisplayFieldProps> = ({
    type,
    value,
    label,
    floatPoints,
    trueText,
    falseText,
    className,
}: IDisplayFieldProps) => {
    const fieldDisplayValue = (type: ValueType, fieldVal: any, label?: string) => {
        switch (type.toLowerCase()) {
            case ValueType.NoSecDateTime: {
                let val = fieldVal as Date;
                if (val) {
                    return Moment(val).format('DD.MM.YYYY HH:mm');
                }
                return '';
            }
            case ValueType.Date: {
                if (fieldVal === undefined || fieldVal === null) return '';

                let val = fieldVal.toString().match(/^(\d{1,2}).(\d{1,2}).(\d{4})$/)
                    ? Moment(fieldVal, 'DD.MM.YYYY').toDate()
                    : (fieldVal as Date);

                if (val) {
                    return Moment(val).format('DD.MM.YYYY');
                }
                return '';
            }
            case ValueType.YesNo: {
                if (fieldVal !== undefined && fieldVal !== null) {
                    const value = fieldVal.toString();
                    if (value === '1' || value === 'True') {
                        return trueText ?? 'Да';
                    }
                    if (value === '0' || value === 'False') {
                        return falseText ?? 'Нет';
                    }
                }
                return '';
            }
            case ValueType.Money: {
                if (fieldVal !== undefined && fieldVal !== null) {
                    return (+fieldVal)
                        ?.toLocaleString('ru-Ru', {
                            minimumFractionDigits: floatPoints ? floatPoints : 2,
                            maximumFractionDigits: floatPoints ? floatPoints : 2,
                        })
                        .replace(',', '.');
                }
                return '';
            }
            case ValueType.Double:
            case ValueType.Integer:
            case ValueType.LongText:
            case ValueType.Year:
            case ValueType.AddressBook:
            case ValueType.Text:
            case ValueType.FileSize: {
                if (fieldVal !== undefined && fieldVal !== null) {
                    return (
                        <div
                            className={clsx('content-text', className)}
                            dangerouslySetInnerHTML={{ __html: fieldVal?.toString() }}
                        />
                    );
                }
                return '';
            }
            case ValueType.Base64Text: {
                if (fieldVal !== undefined && fieldVal !== null) {
                    return (
                        <div
                            className={clsx('content-text', className)}
                            dangerouslySetInnerHTML={{ __html: decodeURIComponent(escape(atob(fieldVal?.toString()))) }}
                        />
                    );
                }
                return '';
            }
            case ValueType.Flow: {
                let val = fieldVal?.toString();
                if (val !== undefined && val !== null) {
                    return val;
                }
                return '';
            }
            case ValueType.Url: {
                let val = fieldVal?.toString();
                if (val !== undefined && val !== null) {
                    return <a href={val}>{val}</a>;
                }
                return '';
            }
            case ValueType.DateTime: {
                let val = fieldVal as Date;
                if (val) {
                    return Moment(val).format('DD.MM.YYYY HH:mm:ss');
                }
                return '';
            }
            case ValueType.Boolean:
                if (fieldVal) {
                    return (
                        <div>
                            <Checkbox disabled={true} defaultChecked={getValueBoolean(fieldVal!)} label={label} />
                        </div>
                    );
                } else {
                    return (
                        <div>
                            <Checkbox disabled={true} defaultChecked={false} label={label} />
                        </div>
                    );
                }

            default:
                return (
                    <div className={className}>
                        {type} --- {fieldVal?.toString()}
                    </div>
                );
        }
    };
    return type ? fieldDisplayValue(type, value, label) : <></>;
};

export default DisplayField;
