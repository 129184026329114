import React, { ReactNode } from 'react';
import './Tag.scss';
import { Variant } from '@/types/ColorVariants';
import { MdClose } from 'react-icons/md';
import clsx from 'clsx';

export interface ITagProps {
    children: React.ReactNode;
    /** Цвет тега. */
    variant?: 'grey' | Variant;
    /** Иконка в начале тега. */
    icon?: ReactNode;
    /** Коллбек клика по тегу. */
    onClick?: () => void;
    /** Коллбек клика по кнопке удаления. */
    onRemove?: () => void;
    /** Дизейбл клика по тегу и кнопке удаления. */
    disabled?: boolean;
    /** Максимальная длина строки */
    maxLength?: number;
    /** Стили для контента бейджа */
    style?: React.CSSProperties;
}

// FIXME: Elements must have sufficient color contrast
const Tag: React.FC<ITagProps> = ({
    children,
    icon,
    onClick,
    onRemove,
    disabled,
    variant = 'default',
    maxLength = 32,
    style,
}) => {
    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        onClick && onClick();
    };

    const handleRemove = (e: React.MouseEvent) => {
        e.stopPropagation();
        onRemove && onRemove();
    };

    // -------------------------------------------------------------------------------------------------------------------

    const clickableClass = onClick && !disabled ? 'rf-tag--clickable' : '';

    // -------------------------------------------------------------------------------------------------------------------

    const overMaxLength = typeof children === 'string' && children.length > maxLength;

    // -------------------------------------------------------------------------------------------------------------------

    return (
        <div
            className={clsx('rf-tag', clickableClass, `rf-tag--${variant}`)}
            onClick={onClick && !disabled ? handleClick : undefined}
            style={style}
            data-testid="rf-tag"
        >
            {!!icon && <div className="rf-tag__icon">{icon}</div>}
            {overMaxLength ? children.slice(0, maxLength) + '...' : children}
            {!!onRemove && (
                <button
                    type="button"
                    className="rf-tag__remove"
                    onClick={!disabled ? handleRemove : undefined}
                    disabled={disabled}
                    aria-label="Удалить"
                    data-testid="rf-tag__remove"
                >
                    <MdClose />
                </button>
            )}
        </div>
    );
};

export default Tag;
