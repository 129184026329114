import React from 'react';
import { ActivityBaseActionExecutor } from '@organisms/actions/ActivityBaseActionExecutor';
import { IBaseAction } from '@models/actions/IBaseAction';
import { getActivityIdFromCompositeId } from '@utils/helpers';
import { ModalSize } from '@/types/ModalSize';
import { ITakeToWorkProps } from './ITakeToWorkProps';
import { TakeToWorkModal } from './TakeToWorkModal';

export class TakeToWorkExecutor extends ActivityBaseActionExecutor {
    private _modalProps?: ITakeToWorkProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            objId: getActivityIdFromCompositeId(objId),
            actionKey: action.key,
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'm',
            hideComment: action.options?.hideComment ?? false,
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <TakeToWorkModal {...this._modalProps} /> : <></>;
    };
}
