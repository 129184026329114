import React from 'react';
import { IBaseAction } from '@models/actions/IBaseAction';
import { DocBaseActionExecutor } from '@organisms/actions/DocBaseActionExecutor';
import { ModalSize } from '@/types/ModalSize';
import { IRegisterModalExecutorProps } from './IRegisterModalExecutorProps';
import { RegisterModalExecutorModal } from './RegisterModalExecutorModal';

export class RegisterModalExecutor extends DocBaseActionExecutor {
    private _modalProps?: IRegisterModalExecutorProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            docId: objId ?? '',
            actionKey: action.key ?? '',
            saveButtonText: action.options?.saveButtonText ?? 'Сохранить',
            modalSize: modalSize ?? action.options?.modalSize ?? 'max',
            openInNewTab: action.options?.openInNewTab ?? false,
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <RegisterModalExecutorModal {...this._modalProps} /> : <div />;
    };
}
