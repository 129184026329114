import React from 'react';
import Chip from '@atoms/Chip';
import clsx from 'clsx';
import { IChipProps } from '@atoms/Chip/Chip';

import './AbookChipItem.scss';

export interface IAbookChipItemProps extends Omit<IChipProps, 'children' | 'onRemove'> {
    /** Значение */
    item?: string;
    /** Показывать иконку удаления
     * @default true
     */
    showRemoveIcon?: boolean;
    /** Функция удаления элемента */
    onRemove?: (item: string) => void;
    /** Класс */
    className?: string;
}

const AbookChipItem = ({ item, showRemoveIcon = true, onRemove, className, ...props }: IAbookChipItemProps) => {
    if (!item) {
        return null;
    }

    return (
        <div className={clsx('rf-abookchipitem', className)}>
            <Chip
                size="s"
                type="outline"
                maxLength={30}
                tooltipBackground={'white'}
                onRemove={showRemoveIcon ? () => onRemove?.(item) : undefined}
                iconPosition="right"
                {...props}
            >
                {item}
            </Chip>
        </div>
    );
};

export default AbookChipItem;
