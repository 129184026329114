import { ActFinishType } from '@/types/ActFinishType';
import { BaseApproveExecutor } from '../BaseApprove/BaseApproveExecutor';

export class ApproveExecutor extends BaseApproveExecutor {
    constructor() {
        super();

        this.approveModel = {
            commentIsRequired: false,
            actType: ActFinishType.Approve,
        };
    }
}
