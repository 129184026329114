import '../ActivityActions.scss';
import React, { useState } from 'react';
import MessageDialog, { IMessageDialogProps } from '@molecules/MessageDialog/MessageDialog';
import { ActivityExecutionService } from '@services/actions/ActivityExecutionService';
import { IRollbackPerformerProps } from './IRollbackPerformerProps';

export const RollbackPerformerModal: React.FC<IRollbackPerformerProps> = (props: IRollbackPerformerProps) => {
    const [errorText, setErrorText] = useState<string>();

    const messageBoxProp: IMessageDialogProps = {
        header: `${props.title}`,
        message: ``,
        showOkButton: true,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        size: props.modalSize,
        okClick: () => {
            let service = new ActivityExecutionService();
            return service
                .saveRollbackPerformer(props.actionKey, props.activityId)
                .then(() => {
                    props.completeHandler && props.completeHandler(true);
                })
                .catch((error) => setErrorText(error));
        },
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
    };

    return <MessageDialog {...messageBoxProp} errorText={errorText} />;
};
