import React from 'react';
import { IBaseAction } from '@models/actions/IBaseAction';
import { IActionExecutor } from '@organisms/actions/IActionExecutor';
import { ModalSize } from '@/types/ModalSize';
import { IEditRouteMapProps } from './IEditRouteMapProps';
import { EditRouteMapModal } from './EditRouteMapModal';

export class EditRouteMapExecutor implements IActionExecutor {
    private _modalProps?: IEditRouteMapProps;

    run = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        raiseVisualElement?: () => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            actionKey: action.key,
            activityKey: action.options?.activityKey,
            processKey: action.options?.processKey,
            documentKey: action.options?.documentKey,
            modalSize: modalSize ?? action.options?.modalSize ?? 'l',
            levelKey: action.options?.levelKey ?? '',
            nextlevelKey: action.options?.nextlevelKey ?? '',
            dueTimeMode: action.options?.dueTimeMode ?? '',
            termType: action.options?.termType ?? '',
            termCount: action.options?.termCount ?? 1,
            periodDate: action.options?.periodDate,
            tasktext: action.options?.tasktext,
            executorId: action.options?.executorId,
            executorDisplayName: action.options?.executorDisplayName,
            abookOptions: action.options?.abookOptions ?? [],
            completeHandler: completeHandler,
        };
    };

    visualElement = () => {
        return this._modalProps ? <EditRouteMapModal {...this._modalProps} /> : <></>;
    };
}
