import '../ActivityActions.scss';
import React, { useState } from 'react';
import { ActivityExecutionService } from '@services/actions/ActivityExecutionService';
import { useForm } from 'react-hook-form';
import ModalContainer, { IModalContainerProps } from '@molecules/ModalContainer/ModalContainer';
import { IRollbackDelegateProps } from './IRollbackDelegateProps';
import { IRollbackDelegateFormData } from './IRollbackDelegateFormData';
import TextareaControl from '@controls/TextareaControl';

export const RollbackDelegateModal: React.FC<IRollbackDelegateProps> = (props: IRollbackDelegateProps) => {
    const [comment, setComment] = useState<string>();
    const [errorText, setErrorText] = useState<string>();
    const [loading, setLoading] = useState<boolean>();

    const serv = new ActivityExecutionService();

    const onSubmit = (form: IRollbackDelegateFormData) => {
        setLoading(true);

        return serv
            .saveRollbackDelegate(props.actionKey, props.activityId, form?.comment)
            .then((res) => {
                setLoading(false);

                props.completeHandler && props.completeHandler(true);
            })
            .catch((err) => {
                setErrorText(err);
            })
            .finally(() => setLoading(false));
    };

    const formState = useForm<IRollbackDelegateFormData>({
        mode: 'onBlur', // "onChange"
    });

    const modalContainerProps: IModalContainerProps = {
        header: props.title,
        size: props.modalSize,
        showOkButton: true,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        isBusy: loading,
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
        errorText: errorText,
        formMethods: formState,
        onSubmit: onSubmit,
        okButtonDisabled: false,
    };

    return (
        <ModalContainer {...modalContainerProps}>
            <TextareaControl
                label="Комментарий"
                readOnly={false}
                required={true}
                rules={{ required: 'Обязательное поле' }}
                name="comment"
                rows={6}
                formState={formState.formState}
                control={formState.control}
                onValueChange={(val: any) => {
                    setComment(val);
                }}
            />
        </ModalContainer>
    );
};
