import './MailToExecutor.scss';
import React from 'react';
import { IBaseAction } from '@models/actions/IBaseAction';
import { DocBaseActionExecutor } from '@organisms/actions/DocBaseActionExecutor';
import { FilesService } from '@services/FilesService';
import ActionConfirmationModal from '@organisms/actions/ActionConfirmationModal/ActionConfirmationModal';

export class MailToExecutor extends DocBaseActionExecutor {
    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
    ) => {
        if (!action.options?.confirmation) {
            this.doAction(action, objId, completeHandler);
        }
    };

    private doAction(action: IBaseAction, objId: string, completeHandler: ((isSucceed: boolean) => void) | undefined) {
        FilesService.downloadFile(`documentExecution/mailTo/${objId}/${action.key}`);
        completeHandler && completeHandler(false);
    }

    visualElementInner = () => {
        return this._action?.options?.confirmation ? (
            <ActionConfirmationModal
                title={this._action.options.confirmation.title}
                message={this._action.options.confirmation.message}
                okButtonText={this._action.options.confirmation.okButtonText}
                cancelButtonText={this._action.options.confirmation.cancelButtonText}
                onOkClick={() => {
                    if (this._action) {
                        this.doAction(this._action, this._objId!, this._completeHandler);
                    }

                    return new Promise<void>((resolve, reject) => {
                        resolve();
                    });
                }}
                onCancelClick={() => {
                    this._completeHandler && this._completeHandler(false);
                }}
            />
        ) : (
            <></>
        );
    };
}
