import React from 'react';
import { IActivityAction, IBaseAction } from '@models/actions/IBaseAction';
import { ActivityBaseActionExecutor } from '@organisms/actions/ActivityBaseActionExecutor';
import { getActivityIdFromCompositeId } from '@utils/helpers';
import { ModalSize } from '@/types/ModalSize';
import { IRequestAddApprovalProps } from './IRequestAddApprovalProps';
import { RequestAddApprovalModal } from './RequestAddApprovalModal';

export class RequestAddApprovalExecutor extends ActivityBaseActionExecutor {
    private _modalProps?: IRequestAddApprovalProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        let activityAction = action as IActivityAction;
        this._modalProps = {
            activityId: getActivityIdFromCompositeId(objId),
            docId: activityAction?.docId ?? '-1',
            actionKey: action.key,
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'l',
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <RequestAddApprovalModal {...this._modalProps} /> : <></>;
    };
}
