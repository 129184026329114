import './WinnerSelectionTableDemand.scss';
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ICustomComponentProps } from '@molecules/formbuilder/controls/CustomComponent/ICustomComponentProps';
import DevExpressDataGrid from '@atoms/DevExpress/DataGrid/DevExpressDataGrid';
import { Column, DataGrid, Editing, Paging, Scrolling, Summary, TotalItem } from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { WinnerSelectionService } from '@services/WinnerSelectionService';
import { IOffer, IOfferAction, ITableDemand, OfferActionTypes } from '@models/winnerSelection/ITableDemand';
import clsx from 'clsx';
import OfferCritDetails from '../OfferCritDetails';
import Tooltip from '@atoms/Tooltip';
import Preloader from '@atoms/Preloader';
import {
    MdDownload,
    MdOutlineExpandMore,
    MdOutlineFileOpen,
    MdOutlineFindInPage,
    MdOutlineRateReview,
    MdOutlineVerified,
} from 'react-icons/md';
import Menu from '@atoms/Menu';
import { FilesService } from '@services/FilesService';
import OfferRate from '../OfferRate';
import OfferTechAnalysis from '../OfferTechAnalysis';
import OfferCustomForm from '../OfferCustomForm';
import OfferApproveToStageTwo from '../OfferApproveToStageTwo';
import { IListElement } from '@/types';
import Hint from '@atoms/Hint';
import { formatPrice } from '@utils/helpers';

export interface IWinnerSelectionTableDemandProps<TFieldValues extends object = object>
    extends ICustomComponentProps<TFieldValues> {}

const WinnerSelectionTableDemand = <TFieldValues extends object = object>({
    component,
    formMethods,
    isEdit,
    isNew,
    fields,
    docId,
    ...props
}: IWinnerSelectionTableDemandProps<TFieldValues>) => {
    const activated = React.useRef(false);
    const gridRef = useRef<DataGrid>(null);

    const [tableData, setTableData] = useState<ITableDemand>();
    const { data: items, offers, settings } = tableData ?? {};

    const [offerToEdit, setOfferToEdit] = useState<IOffer>();
    const [showRateModal, setShowRateModal] = useState<boolean>(false);
    const [showTechAnalysisModal, setShowTechAnalysisModal] = useState<boolean>(false);
    const [showCustomFormModal, setShowCustomFormModal] = useState<boolean>(false);
    const [showApproveToStageTwoModal, setShowApproveToStageTwoModal] = useState<boolean>(false);
    const [currentAction, setCurrentAction] = useState<IOfferAction>();
    const [errorText, setErrorText] = useState<string>();

    const tableKey = component.params?.tableKey;

    const loadData = useCallback(() => {
        gridRef.current?.instance.beginCustomLoading('Загрузка');

        if (!tableKey) {
            console.error('WinnerSelectionTableDemand. Ошибка загрузки данных (tableKey пустой)');
            return;
        }

        WinnerSelectionService.fetchTableDemandData(docId, tableKey)
            .then((response) => {
                setTableData(response.data);
            })
            .catch((reason) => {
                setErrorText(reason);
            })
            .finally(() => {
                gridRef.current?.instance.endCustomLoading();
            });
    }, [docId, tableKey]);

    useEffect(() => {
        activated.current = true;

        loadData();

        return () => {
            activated.current = false;
        };
    }, []);

    const gridStore = useMemo(() => {
        return new DataSource({
            store: new ArrayStore({
                key: 'critId',
                data: items,
            }),
        });
    }, [items]);

    const checkIsRatingWinner = useCallback((row: any): boolean => {
        const maxRating = Math.max(...Object.keys(row.data.offers).map((key) => row.data.offers[key].rating));

        return row.value.rating?.toFixed(4) === maxRating?.toFixed(4) && maxRating !== 0 && maxRating !== null;
    }, []);

    const checkIsPriceWinner = useCallback(
        (currentKey: string): boolean => {
            if (!offers || offers.length === 0) return false;

            if (!offers?.every((element) => element.currencyCode === offers[0].currencyCode)) {
                // если валюты различаются, то сравнение не производим
                return false;
            }

            // определяем победителей по минимальной цене
            const min = Math.min(...offers.map((item) => (item.price !== null ? item.price : -Infinity)))?.toFixed(2);
            const winners = offers.filter((item) => item.price?.toFixed(2) === min);

            return winners.some((item) => item.key === currentKey);
        },
        [offers],
    );

    const checkIsSummaryScoreWinner = useCallback(
        (currentKey: string): boolean => {
            if (!offers || offers.length === 0) return false;

            if (offers.every((item) => item.summaryScore === null || item.summaryScore === undefined)) {
                // если все значения пустые, то сравнение не производим
                return false;
            }

            // определяем победителей по максимальному итоговому рейтингу
            const max = Math.max(
                ...offers.map((item) => (item.summaryScore !== null ? item.summaryScore : -Infinity)),
            )?.toFixed(4);
            const winners = offers.filter((item) => item.summaryScore?.toFixed(4) === max);

            return winners.some((item) => item.key === currentKey);
        },
        [offers],
    );

    const checkIsMainResult = useCallback((resultValue: string): boolean => {
        return resultValue?.toLowerCase() === 'основной';
    }, []);

    const headerMenuLabel = useCallback((text: string, icon: ReactNode) => {
        return (
            <div>
                <div className="offer-header-menu__item">
                    {icon}
                    <span>{text}</span>
                </div>
            </div>
        );
    }, []);

    const categorySortingMethod = useCallback(
        (value1: any, value2: any) => {
            if (!settings?.categorySortOrder) {
                return value1.localeCompare(value2); // сортировка по алфавиту, если categorySortOrder не определен
            }

            // сортируем элементы в порядке указанном в categorySortOrder
            const indexValue1 = settings?.categorySortOrder.indexOf(value1);
            const indexValue2 = settings?.categorySortOrder.indexOf(value2);

            if (indexValue1 === -1 && indexValue2 === -1) {
                return 0;
            } else if (indexValue1 === -1) {
                return 1;
            } else if (indexValue2 === -1) {
                return -1;
            } else {
                return indexValue1 - indexValue2;
            }
        },
        [settings?.categorySortOrder],
    );

    const offersColumns = useMemo(
        () => {
            return offers?.map((offer, index) => {
                return (
                    <Column
                        key={offer.key}
                        dataField={`offers[${offer.key}]`}
                        allowSorting={false}
                        allowEditing={false}
                        minWidth={330}
                        width={'400px'}
                        headerCellRender={(p) => {
                            const isTotalWinner = checkIsSummaryScoreWinner(offer.key);
                            const isPriceWinner = checkIsPriceWinner(offer.key);
                            const isMainResult = checkIsMainResult(offer.result);

                            const headerMenuItems = offer.actions
                                .map((action) => {
                                    switch (OfferActionTypes[action.type]) {
                                        case OfferActionTypes.CustomForm:
                                            if (!action.visible) return null;

                                            return {
                                                label: headerMenuLabel(
                                                    action?.displayName ?? 'Просмотреть предложение',
                                                    <MdOutlineFindInPage size="24px" />,
                                                ),
                                                handler: () => {
                                                    setOfferToEdit(offer);
                                                    setCurrentAction(action);
                                                    setShowCustomFormModal(true);
                                                },
                                            };

                                        case OfferActionTypes.DownloadAttachments:
                                            if (!action.visible) return null;

                                            return {
                                                label: headerMenuLabel(
                                                    action.displayName ?? 'Скачать документацию',
                                                    <MdDownload size="24px" />,
                                                ),
                                                handler: () => {
                                                    FilesService.downloadFile(
                                                        `winnerSelection/downloadAttachments/${offer.key}/${tableKey}/${action.key}`,
                                                        {
                                                            title: 'Скачать документацию',
                                                            message: 'Выбранное предложение не содержит документацию.',
                                                            variant: 'default',
                                                        },
                                                    );
                                                },
                                            };

                                        case OfferActionTypes.OpenOffer:
                                            return {
                                                label: headerMenuLabel(
                                                    action?.displayName ?? 'Открыть предложение',
                                                    <MdOutlineFileOpen size="24px" />,
                                                ),
                                                handler: () => {
                                                    window.open(`./document/${offer.key}`, '_blank');
                                                },
                                                disabled: !action.visible,
                                            };

                                        case OfferActionTypes.TechAnalysisOffer:
                                            return {
                                                label: headerMenuLabel(
                                                    action?.displayName ?? 'Технический анализ',
                                                    <MdOutlineRateReview size="24px" />,
                                                ),
                                                handler: () => {
                                                    setOfferToEdit(offer);
                                                    setCurrentAction(action);
                                                    setShowTechAnalysisModal(true);
                                                },
                                                disabled: !action.visible,
                                            };

                                        case OfferActionTypes.RateOffer:
                                            return {
                                                label: headerMenuLabel(
                                                    action?.displayName ?? 'Оценить',
                                                    <MdOutlineRateReview size="24px" />,
                                                ),
                                                handler: () => {
                                                    setOfferToEdit(offer);
                                                    setCurrentAction(action);
                                                    setShowRateModal(true);
                                                },
                                                disabled: !action.visible,
                                            };

                                        case OfferActionTypes.ApproveToStageTwo:
                                            return {
                                                label: headerMenuLabel(
                                                    action?.displayName ?? 'Допустить ко второму этапу',
                                                    <MdOutlineVerified size="24px" />,
                                                ),
                                                handler: () => {
                                                    setOfferToEdit(offer);
                                                    setCurrentAction(action);
                                                    setShowApproveToStageTwoModal(true);
                                                },
                                                disabled: !action.visible,
                                            };
                                        default:
                                            return null;
                                    }
                                })
                                .filter((item) => item !== null) as IListElement[];

                            return (
                                <Menu list={headerMenuItems} position="bottom-end">
                                    <div
                                        className={clsx({
                                            'offer-cell-header': true,
                                            'offer-cell-header--winner': isTotalWinner,
                                        })}
                                    >
                                        <div className="offer-cell-header__name">
                                            <p className="offer-cell-header__title">{offer.name}</p>
                                        </div>
                                        <div className="offer-cell-header__info">
                                            <p className="offer-cell-header__desc">{`Предложение №${offer.number || ''} (${offer.type?.toLowerCase() || ''})`}</p>
                                            <div className="offer-cell-header__labels">
                                                {offer.price && settings?.priceVisible ? (
                                                    <div className="offer-cell-header__price">
                                                        <Tooltip openDelay={100} background="black" position="bottom">
                                                            <span
                                                                className={clsx({
                                                                    'offer-cell-price': true,
                                                                    'offer-cell-price--winner': isPriceWinner,
                                                                })}
                                                            >
                                                                {formatPrice(offer.price, offer.currencyCode)}
                                                            </span>
                                                            {`Цена предложения\nВалюта: ${offer.currencyText} (${offer.currencyCode})`}
                                                        </Tooltip>
                                                    </div>
                                                ) : null}
                                                {offer.result ? (
                                                    <div className="offer-cell-header__result">
                                                        <Tooltip openDelay={100} background="black" position="bottom">
                                                            <span
                                                                className={clsx({
                                                                    'offer-cell-result': true,
                                                                    'offer-cell-result--main': isMainResult,
                                                                })}
                                                            >
                                                                {offer.result}
                                                            </span>
                                                            {`Результат`}
                                                        </Tooltip>
                                                    </div>
                                                ) : null}
                                            </div>
                                            {offer.rank ? (
                                                <div className="offer-cell-header__rank">
                                                    <Tooltip openDelay={100} background="black" position="bottom">
                                                        <span
                                                            className={clsx(
                                                                'offer-cell-rank',
                                                                `offer-cell-rank--${offer.rank}`,
                                                            )}
                                                        >
                                                            {offer.rank}
                                                        </span>
                                                        {'Место предложения'}
                                                    </Tooltip>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="offer-cell-header__menu-button">
                                            <MdOutlineExpandMore size={'24px'} />
                                        </div>
                                    </div>
                                </Menu>
                            );
                        }}
                        cellRender={(p) => {
                            const isWinner = checkIsRatingWinner(p);

                            return (
                                <div
                                    className={clsx({
                                        'offer-cell': true,
                                        'offer-cell--winner': isWinner,
                                    })}
                                >
                                    {!p.data.isInfoCrit ? (
                                        <div className="offer-cell__scores">
                                            <div className={clsx('offer-cell__field', 'offer-cell__field-score')}>
                                                <div className="offer-cell__field-header">Балл</div>
                                                <div className="offer-cell__field-value">
                                                    <span>
                                                        {p.data.offers[offer.key].score?.toLocaleString('ru-RU', {
                                                            maximumFractionDigits: 4,
                                                        }) ?? '-'}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className={clsx('offer-cell__field', 'offer-cell__field-rating')}>
                                                <div className="offer-cell__field-header">Рейтинг</div>
                                                <div className="offer-cell__field-value">
                                                    <span>
                                                        {p.data.offers[offer.key].rating?.toLocaleString('ru-RU', {
                                                            maximumFractionDigits: 4,
                                                        }) ?? '-'}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}

                                    <div className="offer-cell__description">
                                        <div className={clsx('offer-cell__field', 'offer-cell__field-description')}>
                                            <div className="offer-cell__field-header">
                                                {p.data.isInfoCrit ? 'Комментарий' : 'Обоснование'}
                                            </div>
                                            <div className="offer-cell__field-value">
                                                {p.data.offers[offer.key].description}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="offer-cell__details">
                                        <OfferCritDetails
                                            tableKey={tableKey!}
                                            isEdit={p.data.offers[offer.key].allowEdit ?? false}
                                            disabledHint={settings?.offerCritDisabledHint}
                                            editButtonName={settings?.editOfferCritButtonName}
                                            offerId={offer.key}
                                            demandId={docId}
                                            value={p.value}
                                            rowData={p.data}
                                            onSubmit={() => {
                                                loadData();
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        }}
                    />
                );
            });
        },
        // prettier-ignore
        [
        checkIsMainResult, checkIsPriceWinner, checkIsRatingWinner, checkIsSummaryScoreWinner, docId, headerMenuLabel,
        loadData, offers, settings?.editOfferCritButtonName, settings?.offerCritDisabledHint, settings?.priceVisible,
        tableKey,
        ],
    );

    const offersSummaryColumns = useMemo(() => {
        return offers?.map((offer, index) => {
            const isWinner = checkIsSummaryScoreWinner(offer.key);
            return (
                <TotalItem
                    key={offer.key}
                    summaryType="custom"
                    showInColumn={`offers[${offer.key}]`}
                    alignment="center"
                    displayFormat={`${offer.summaryScore}`}
                    customizeText={(item: any) => {
                        return item.valueText !== undefined && item.valueText !== null && item.valueText !== 'null'
                            ? (+item.valueText).toLocaleString('ru-RU', { maximumFractionDigits: 4 })
                            : '-';
                    }}
                    cssClass={clsx({
                        'offer-summary__value': true,
                        'offer-summary--winner': isWinner,
                    })}
                />
            );
        });
    }, [checkIsSummaryScoreWinner, offers]);

    return (
        <div className="winnerSelectionTableDemand-wrapper">
            {errorText ? (
                <Hint icon="info" title={`Ошибка загрузки: ${errorText}`} variant="red" maxWidth="100%" />
            ) : null}
            {items !== undefined && offers !== undefined ? (
                offers.length === 0 ? (
                    <div className="winnerSelectionTableDemand__empty">
                        <h1>{settings?.emptyTextHeader}</h1>
                        <p>{settings?.emptyText}</p>
                    </div>
                ) : (
                    <>
                        <DevExpressDataGrid
                            id="winnerSelectionTableDemandGrid"
                            ref={gridRef}
                            dataSource={gridStore}
                            remoteOperations={true}
                            allowColumnResizing={true}
                            wordWrapEnabled={true}
                            hoverStateEnabled={true}
                            columnResizingMode="widget"
                        >
                            <Scrolling useNative={false} scrollByContent={true} />
                            <Paging enabled={false} />
                            <Editing allowUpdating={true} allowAdding={false} allowDeleting={false} mode="cell" />
                            <Column
                                dataField="category"
                                caption="Категория"
                                groupIndex={0}
                                sortIndex={0}
                                sortingMethod={categorySortingMethod}
                                allowEditing={false}
                                fixed={true}
                                fixedPosition={'left'}
                            />
                            <Column
                                dataField="isInfoCrit"
                                sortIndex={1}
                                defaultVisible={false}
                                visible={false}
                                sortOrder={'asc'}
                            />
                            <Column
                                dataField="critName"
                                caption="Критерий"
                                allowEditing={false}
                                cssClass="crit-name__header"
                                width={'300px'}
                                minWidth={200}
                                fixed={true}
                                fixedPosition={'left'}
                                cellRender={(p) => {
                                    return (
                                        <div className={'crit-cell'}>
                                            <p>{p.data.critName}</p>
                                            {!p.data.isInfoCrit && p.data.critWeight !== null && (
                                                <p>
                                                    Вес:{' '}
                                                    {p.data.critWeight.toLocaleString('ru-RU', {
                                                        maximumFractionDigits: 4,
                                                    })}
                                                </p>
                                            )}
                                        </div>
                                    );
                                }}
                            />
                            {offersColumns}
                            <Summary>
                                <TotalItem
                                    column="critName"
                                    summaryType="custom"
                                    showInColumn="critName"
                                    alignment="left"
                                    displayFormat="Общий рейтинг"
                                    cssClass="offer-summary__title"
                                />
                                {offersSummaryColumns}
                            </Summary>
                        </DevExpressDataGrid>
                        {offerToEdit && showRateModal ? (
                            <OfferRate
                                tableKey={tableKey!}
                                isEdit={
                                    offerToEdit.actions.some(
                                        (action) => action.type === OfferActionTypes[OfferActionTypes.RateOffer],
                                    ) ?? false
                                }
                                value={offerToEdit}
                                demandId={docId}
                                onSubmit={(value) => {
                                    loadData();
                                    setOfferToEdit(undefined);
                                    setCurrentAction(undefined);
                                    setShowRateModal(false);
                                }}
                                onClose={() => {
                                    setOfferToEdit(undefined);
                                    setCurrentAction(undefined);
                                    setShowRateModal(false);
                                }}
                            />
                        ) : null}
                        {offerToEdit && showTechAnalysisModal ? (
                            <OfferTechAnalysis
                                tableKey={tableKey!}
                                isEdit={
                                    offerToEdit.actions.some(
                                        (action) =>
                                            action.type === OfferActionTypes[OfferActionTypes.TechAnalysisOffer],
                                    ) ?? false
                                }
                                value={offerToEdit}
                                demandId={docId}
                                onSubmit={(value) => {
                                    loadData();
                                    setOfferToEdit(undefined);
                                    setCurrentAction(undefined);
                                    setShowTechAnalysisModal(false);
                                }}
                                onClose={() => {
                                    setOfferToEdit(undefined);
                                    setCurrentAction(undefined);
                                    setShowTechAnalysisModal(false);
                                }}
                            />
                        ) : null}
                        {offerToEdit && showCustomFormModal && currentAction ? (
                            <OfferCustomForm
                                tableKey={tableKey!}
                                value={offerToEdit}
                                action={currentAction}
                                demandId={docId}
                                onClose={() => {
                                    setOfferToEdit(undefined);
                                    setCurrentAction(undefined);
                                    setShowCustomFormModal(false);
                                }}
                            />
                        ) : null}
                        {offerToEdit && showApproveToStageTwoModal ? (
                            <OfferApproveToStageTwo
                                tableKey={tableKey!}
                                isEdit={
                                    offerToEdit.actions.some(
                                        (action) =>
                                            action.type === OfferActionTypes[OfferActionTypes.ApproveToStageTwo],
                                    ) ?? false
                                }
                                value={offerToEdit}
                                demandId={docId}
                                onSubmit={(value) => {
                                    loadData();
                                    setOfferToEdit(undefined);
                                    setCurrentAction(undefined);
                                    setShowApproveToStageTwoModal(false);
                                }}
                                onClose={() => {
                                    setOfferToEdit(undefined);
                                    setCurrentAction(undefined);
                                    setShowApproveToStageTwoModal(false);
                                }}
                            />
                        ) : null}
                    </>
                )
            ) : (
                <div className={'winnerSelectionTableDemand__loading'}>
                    <Preloader position="inline" size="l" />
                </div>
            )}
        </div>
    );
};

export default WinnerSelectionTableDemand;
