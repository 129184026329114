import React from 'react';
import { IBaseAction } from '@models/actions/IBaseAction';
import { ModalSize } from '@/types/ModalSize';
import { ActivityBaseActionExecutor } from '@organisms/actions/ActivityBaseActionExecutor';
import { IDocumentsBatchEditingTableProps } from './IDocumentsBatchEditingTableProps';
import { DocumentsBatchEditingTableModal } from './DocumentsBatchEditingTableModal';

export class DocumentsBatchEditingTableExecutor extends ActivityBaseActionExecutor {
    private _modalProps?: IDocumentsBatchEditingTableProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            displayName: action.displayName,
            actionKey: action.key ?? '',
            docId: objId,
            title: action.options?.title ?? '',
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'max',
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <DocumentsBatchEditingTableModal {...this._modalProps} /> : <div />;
    };
}
