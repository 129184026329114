import { ValueType } from '@/types/ValueType';
import { IGridStateSettings, IGridStateSettingsColumn } from '@models/response/IGridStateSettings';
import { IColumnProps } from 'devextreme-react/tree-list';
import { IDataGridDescriptionResponse } from '@models/response/IDataGridDescriptionResponse';
import { IDictionaryData } from '@models/dictionary/IDictionaryData';
import { IDictFilter } from '@models/Forms/IForms';
import dxDataGrid, { CellPreparedEvent as CellPreparedEventDataGrid } from 'devextreme/ui/data_grid';
import { IGridDescriptionResponse } from '@models/response/IGridDescriptionResponse';
import { CellPreparedEvent as CellPreparedEventTreeList } from 'devextreme/ui/tree_list';

export const getColumnFilterOperationsByColumnDataType = (dataType: string) => {
    switch (dataType) {
        case 'date':
        case 'datetime':
            return ['=', '<>', '<', '>', 'between'];
        case 'number':
            return ['=', '<>', '<', '>', '<=', '>=', 'between'];
        case 'boolean':
            return ['='];
        default:
            return ['contains', '=', '<>'];
    }
};

export const getColumnDataTypeByFieldDataType = (dataType: ValueType) => {
    switch (dataType) {
        case ValueType.Date:
            return 'date';
        case ValueType.DateTime:
        case ValueType.NoSecDateTime:
            return 'datetime';
        case ValueType.Money:
        case ValueType.Double:
        case ValueType.Integer:
            return 'number';
        case ValueType.Boolean:
            return 'boolean';
        default:
            return 'string';
    }
};

export const filterPredicate = (currentItem: IDictionaryData, filter: any) => {
    const value = filter.columnName
        ? currentItem.fields.find((x) => x.name === filter.columnName)?.value
        : currentItem.code;

    if (filter.exlude && filter.exlude?.codes?.indexOf(value) !== -1) return false;

    if (filter.codes?.indexOf(value) !== -1) return filter.isShow;

    return !filter.isShow;
};

export const filterResponseVoid = (originalItems: IDictionaryData[], filter: IDictFilter) => {
    if (filter.filter && filter.filter != '') {
        const fObj = JSON.parse(filter.filter.replace(/\'/g, '"'));
        const filteredObject = [] as IDictionaryData[];
        for (let j = 0; j < originalItems.length; j++) {
            const item = originalItems[j];
            const filteredChild = filterPredicate(item, fObj);
            if (filteredChild) filteredObject.push(item);
        }

        return filteredObject;
    } else {
        return originalItems;
    }
};

/**
 *
 * */
export const filterGridRowsByScript = (data: IDictionaryData[], script: string) => {
    let _data: IDictionaryData[] = [];
    data.forEach((value) => {
        let result = filterGridRowByScriptEval(value, data, script);
        if (result) {
            _data.push(value);
        }
    });
    return _data;
};

/**
 *
 * */
export const checkGridRowsByScript = (data: IDictionaryData[], script: string) => {
    for (let index = 0; index < data.length; index++) {
        const value = data[index];
        let result = filterGridRowByScriptEval(value, data, script);
        if (result) {
            return false;
        }
    }
    return true;
};

/**
 *
 * */
export const filterGridRowByScriptEval = (data: IDictionaryData, dataArray: IDictionaryData[], script: string) => {
    /**
     * WARNING! Begin sections of functions for templates, do not rename
     * */
    let subFunc = ` 
    function code (){
        return data.code;
    };

    function getDateInt (data) {
        let date = stringToDateFormat(data.split(' ')[0], 'dd.mm.yyyy');
        return date.getTime();
    };

    function getOnlyDateFromInt (data) {
        let date = new Date(+data);
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0).getTime();
    };

    function field (name) {
        let value = data.fields.find((field) => field.name === name)?.value;
        return value;
    };

    function columnValues (name) {
        let arr = [];
        dataArray.forEach((row)=>{
            let value = row.fields.find((field) => field.name === name)?.value;
            arr.push(value);
        });
        return arr;
    };
    function eliminateDuplicates(arr) {
        let i;
         let   len = arr.length;
         let   out = [];
         let  obj = {};
      
        for (i = 0; i < len; i++) {
          obj[arr[i]] = 0;
        }
        for (i in obj) {
          out.push(i);
        }
        return out;
      };

    function intersect (array1, array2){
        return array1 && array2 && array1.some((item) => array2.includes(item));
    };
`;

    return eval(subFunc + script);
};

export const onCellHoverChanged = (e: any) => {
    // для masterDetail ячеек тултип не показываем
    if (e.row && e.row.rowType === 'detail') return;

    e.cellElement.setAttribute('title', e.cellElement.innerText);
};

export const applyGridState = (
    settings: IGridStateSettings | undefined,
    gridDesc: IGridDescriptionResponse | undefined,
    objRef: React.RefObject<any>,
) => {
    if (settings) {
        gridDesc?.columns.forEach((column, index) => {
            let options = objRef.current?.instance.columnOption(index) as IColumnProps;

            const stateColumn = settings?.state.columns.filter(
                (x) => x.dataField?.toUpperCase() === options.dataField?.toUpperCase(),
            )[0];

            if (stateColumn !== undefined) {
                options.visible = stateColumn.visible;
                options.visibleIndex = stateColumn.visibleIndex;
                options.width = stateColumn.width;
            } else {
                options.visible = false;
            }

            objRef.current?.instance.columnOption(index, options);
        });
    }
};

export const applyStateSettings = async (grid?: dxDataGrid, settings?: IGridStateSettings) => {
    if (grid && settings) {
        grid.clearFilter();
        grid.clearSorting();

        // Ждём завершения операций с фильтрами/сортировкой
        await delay();
        applyColumnSettings(grid, settings);
        // Применяем фильтры с задержкой
        await delay();
        applyFilters(grid, settings);
    }
};

const delay = (ms?: number) => new Promise((resolve) => setTimeout(resolve, ms));

const applyColumnSettings = (grid: dxDataGrid, settings: IGridStateSettings) => {
    const columnsSettings = new Map<string, IGridStateSettingsColumn>();
    settings.state.columns.forEach((column) => {
        columnsSettings.set(column.dataField ? column.dataField?.toLowerCase()! : column.name?.toLowerCase()!, column);
    });

    for (let i = 0; i < grid.columnCount()!; ++i) {
        const column = grid.columnOption(i) as IColumnProps;
        if (!column) {
            continue;
        }

        if (column.dataField === 'actionColumn' || column.dataField === 'emptyColumn') {
            continue;
        }

        let columnSettings = column.dataField ? columnsSettings.get(column.dataField.toLowerCase()) : undefined;
        if (!columnSettings) {
            columnSettings = column.caption ? columnsSettings.get(column.caption.toLowerCase()) : undefined;
        }
        if (columnSettings) {
            column.visible = columnSettings.visible;
            column.visibleIndex = columnSettings.visibleIndex;
            if (columnSettings.width) {
                column.width = columnSettings.width;
            }
            column.sortIndex = columnSettings.sortIndex;
            column.sortOrder = columnSettings.sortOrder;
        } else {
            column.visible = false;
        }
        grid.columnOption(i, column);
    }
};

const applyFilters = (grid: dxDataGrid, settings: IGridStateSettings) => {
    if (settings.filters) {
        grid.option('filterValue', settings.filters);
    }
};

export const getStateSettings = (settings?: IGridStateSettings[]) => {
    if (settings) {
        let state = settings.find((x) => x?.selected);
        if (!state) {
            state = settings.find((x) => x?.default);
        }
        return state;
    }
    return undefined;
};

export const addSeconds = (date: Date, seconds: number): Date => {
    let _date = new Date(date);
    _date.setTime(_date.getTime() + seconds * 1000);
    return _date;
};

export const parseDateWithoutTz = (date: string): Date => {
    let _date = new Date(date);
    return addSeconds(_date, -_date.getTimezoneOffset() * 60);
};

export const getIsoDate = (date: Date): string => {
    return date.toISOString().substring(0, 10);
};

export function calculateFilterExpression(this: any, filterValue: any, selectedFilterOperation: any) {
    if (typeof filterValue == 'string') {
        filterValue = filterValue.trim();
    }

    if (this.dataType === 'boolean') {
        return [this.dataField, filterValue ? '=' : '<>', 1];
    }

    if (this.dataType === 'date') {
        if (Array.isArray(filterValue)) {
            if (selectedFilterOperation === 'between') {
                if (filterValue.length === 2 && filterValue[0] && filterValue[1]) {
                    let fr = parseDateWithoutTz(filterValue[0]);
                    let to = parseDateWithoutTz(filterValue[1]);
                    return [[this.dataField, '>', getIsoDate(fr)], 'and', [this.dataField, '<', getIsoDate(to)]];
                }
            }
        } else {
            if (selectedFilterOperation === '=') {
                let fr = parseDateWithoutTz(filterValue);
                let to = addSeconds(fr, 24 * 60 * 60);
                return [[this.dataField, '>=', getIsoDate(fr)], 'and', [this.dataField, '<', getIsoDate(to)]];
            } else if (selectedFilterOperation === '<>') {
                let fr = parseDateWithoutTz(filterValue);
                let to = addSeconds(fr, 24 * 60 * 60);
                return [[this.dataField, '<', getIsoDate(fr)], 'or', [this.dataField, '>=', getIsoDate(to)]];
            } else {
                let date = parseDateWithoutTz(filterValue);
                return [this.dataField, selectedFilterOperation, getIsoDate(date)];
            }
        }
    }

    // Invokes the default filtering behavior
    return this.defaultCalculateFilterExpression.apply(this, arguments);
}

export const getOriginalState = (description?: IDataGridDescriptionResponse) => {
    if (description) {
        let st: IGridStateSettings = {
            default: true,
            name: 'default',
            selected: false,
            state: {
                columns: [],
                allowedPageSizes: [10, 20, 30],
                filterPanel: {
                    filterEnabled: false,
                },
                filterValue: '',
                pageIndex: 0,
                pageSize: 10,
                searchText: '',
            },
            filters: undefined,
            sort: undefined,
        };

        description?.columns?.forEach((column, index) => {
            if (column.field !== 'emptyColumn' && !column.defaultHide) {
                st.state.columns.push({
                    dataField: column.field,
                    dataType: column.dataType,
                    name: column.header,
                    visible: !column.defaultHide,
                    visibleIndex: index,
                    width: column.width,
                });
            }
        });

        return st.state.columns.length > 0 ? st : undefined;
    }
    return undefined;
};

export const updateModeOn = (grid: dxDataGrid | undefined, isUpdating: React.RefObject<boolean>) => {
    if (grid) {
        if (!isUpdating.current) {
            isUpdating.current = true;
            grid?.beginUpdate();
        }
    }
};

export const updateModeOff = (grid: dxDataGrid | undefined, isUpdating: React.RefObject<boolean>) => {
    if (grid) {
        if (isUpdating.current) {
            isUpdating.current = false;
            grid?.endUpdate();
        }
    }
};

export const setDataValueAttr = (e: CellPreparedEventDataGrid | CellPreparedEventTreeList) => {
    // записываем значение ячейки в кастомный data-value атрибут ячейки
    if (!e.cellElement.getAttribute('data-value')) {
        e.cellElement.setAttribute('data-value', e.text);
    }
};

export const setDataKeyAttr = (e: CellPreparedEventDataGrid | CellPreparedEventTreeList) => {
    // записываем ключ элемента в кастомный data-key атрибут элемента строки
    if (!e.cellElement.parentElement?.getAttribute('data-key')) {
        e.cellElement.parentElement?.setAttribute('data-key', e.key);
    }
};
