import { IConfig } from '../models/IConfig';
import axios from 'axios';

export class ConfigService {
    static _config: IConfig;

    static async initAsync(): Promise<Readonly<IConfig>> {
        console.debug(`Инициализация конфигурации`);
        const basePath = import.meta.env.VITE_BASE_PATH as string;
        const response = await axios.get(basePath === '/' ? `config.json` : `${basePath}/config.json`);
        this._config = await (response.data as Promise<IConfig>);
        return Object.freeze(this._config);
    }

    static get(): Readonly<IConfig> {
        return Object.freeze(this._config);
    }
}

await ConfigService.initAsync();
