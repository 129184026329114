import './Menu.scss';
import React, { ReactNode, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { IListElement } from '@/types';
import List from './List';
import clsx from 'clsx';
import DropdownContent from '../DropdownContent';
import { IDropdownContentProps } from '../DropdownContent/DropdownContent';
import { autoUpdate, flip, offset, shift, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import { MenuContext } from '@atoms/Menu/MenuContext';

export interface IListProps extends Pick<IDropdownContentProps, 'position' | 'style' | 'containerRef'> {
    /** Кнопка открытия меню */
    children: ReactNode;
    /** Элементы меню */
    list?: IListElement[];
    /** Компонент вместо списка */
    content?: ReactNode;
    /** Класс */
    className?: string;
    /** При клике на элемент (children) переключать открытие/скрытие меню
     * Если false то при клике на элемент меню только показывать
     * @default true
     */
    toggleTarget?: boolean;
    /** Не активно при клике
     * @default false
     */
    disabled?: boolean;
    /** Срабатывает при изменении
     */
    onToggleMenu?: (show: boolean) => void;
    /** Ссылка на меню */
    menuRef?: React.Ref<IMenuRefActions>;
    /** Расстояние по оси X и Y
     * @default { mainAxis: 8, crossAxis: 0 }
     */
    offsetValue?: { mainAxis?: number; crossAxis?: number };
}

export interface IMenuRefActions {
    open: () => void;
    close: () => void;
    toggle: () => void;
}

const Menu: React.FC<IListProps> = ({
    list,
    children,
    content,
    className = '',
    toggleTarget = true,
    disabled = false,
    onToggleMenu,
    menuRef,
    offsetValue = { mainAxis: 8, crossAxis: 0 },
    ...props
}: IListProps) => {
    // Floating UI setup
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        whileElementsMounted: autoUpdate,
        placement: props.position || 'bottom',
        middleware: [offset(offsetValue), flip(), shift()],
    });

    const dismiss = useDismiss(context);

    const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

    /** Изменение состояния выпадающего списка */
    const onToggle = useCallback(() => {
        setIsOpen((show: boolean) => !show);
    }, [setIsOpen]);

    const onClose = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const onShow = useCallback(() => {
        setIsOpen(true);
    }, [setIsOpen]);

    useEffect(() => {
        onToggleMenu && onToggleMenu(isOpen);
    }, [isOpen]);

    useImperativeHandle(menuRef, () => ({
        open: () => {
            onShow();
        },
        close: () => {
            onClose();
        },
        toggle: () => {
            onToggle();
        },
    }));

    /** Клик по кнопке */
    const onClick = useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();
            if (disabled) {
                return;
            }

            if (toggleTarget) {
                onToggle();
            } else {
                setIsOpen(true);
            }
        },
        [onToggle, disabled, toggleTarget],
    );

    return (
        <MenuContext value={{ onClose, show: isOpen }}>
            <div className={clsx('rf-menu', className)} data-testid="rf-menu">
                <div ref={refs.setReference} className="rf-menu__toggle" onClick={onClick} {...getReferenceProps()}>
                    {children}
                </div>
                <DropdownContent
                    {...props}
                    style={{ ...floatingStyles, ...props.style }}
                    ref={refs.setFloating}
                    show={isOpen}
                    floatingContext={context}
                    getFloatingProps={getFloatingProps}
                >
                    {content ? content : list && list.length > 0 && <List list={list} className={className} />}
                </DropdownContent>
            </div>
        </MenuContext>
    );
};

export default Menu;
