import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOrderByItem } from '@molecules/Search/ResultsPager';
import { ISearchData } from '@models/search/ISearchData';
import { ISearchDictResultItem, ISearchDictResults } from '@models/search/ISearchDictResultItem';
import { ISearchDocResultItem, ISearchDocResults } from '@models/search/ISearchDocResultItem';
import {
    ISearchExtraBaseFilter,
    ISearchExtraFlowFieldWithValue,
    ISearchExtraFlowSetting,
} from '@models/search/ISearchExtraFilters';
import { ISearchReportResultItem, ISearchReportResults } from '@models/search/ISearchReportResultItem';
import { IFieldElem } from '@models/IFormData';
import { IForms } from '@models/Forms/IForms';

export const DEFAULT_PAGE_SIZE: number = 10;

export interface ISearchsState {
    foundDocuments: {
        items: ISearchDocResultItem[];
        totalCount: number | undefined;
        pageSize: number;
        pageIndex: number;
        orderBy?: IOrderByItem;
        isLoading: boolean;
    };
    foundReports: {
        items: ISearchReportResultItem[];
        totalCount: number | undefined;
        pageSize: number;
        pageIndex: number;
        orderBy?: IOrderByItem;
        isLoading: boolean;
    };
    foundDicts: {
        items: ISearchDictResultItem[];
        totalCount: number | undefined;
        pageSize: number;
        pageIndex: number;
        orderBy?: IOrderByItem;
        isLoading: boolean;
    };
    isGlobalSearchLoading: boolean;
    searchText: string;
    extraFilter?: ISearchExtraBaseFilter;
    baseRequisitesFields?: Record<string, IFieldElem>;
    baseRequisitesForms?: IForms;
    //extraFlowsFilters: ISearchExtraFlowsFilter;
}

const initialState: ISearchsState = {
    foundDocuments: {
        items: [] as ISearchDocResultItem[],
        totalCount: undefined,
        isLoading: false,
        pageIndex: 0,
        pageSize: DEFAULT_PAGE_SIZE,
    },
    foundReports: {
        items: [] as ISearchReportResultItem[],
        totalCount: undefined,
        isLoading: false,
        pageIndex: 0,
        pageSize: DEFAULT_PAGE_SIZE,
    },
    foundDicts: {
        items: [] as ISearchDictResultItem[],
        totalCount: undefined,
        isLoading: false,
        pageIndex: 0,
        pageSize: DEFAULT_PAGE_SIZE,
    },
    isGlobalSearchLoading: false,
    searchText: '',
    extraFilter: {
        flowsFields: {
            isLoading: false,
            isExpanded: false,
        },
    },
};

export const SearchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setSearchingData: (state, action: PayloadAction<ISearchData>) => {
            state.searchText = action.payload.text;
            state.extraFilter = action.payload.extraFilter;
        },

        setGlobalSearchLoading: (state, action: PayloadAction<boolean>) => {
            state.isGlobalSearchLoading = action.payload;
        },

        setBaseRequisitesFields: (
            state,
            action: PayloadAction<{ fields: Record<string, IFieldElem>; forms: IForms }>,
        ) => {
            state.baseRequisitesFields = action.payload.fields;
            state.baseRequisitesForms = action.payload.forms;
        },

        resetAllSearchResults: (state, action: PayloadAction) => {
            state.foundDocuments.items = [];
            state.foundDocuments.pageIndex = 0;
            state.foundDocuments.totalCount = undefined;

            state.foundReports.items = [];
            state.foundReports.pageIndex = 0;
            state.foundReports.totalCount = undefined;

            state.foundDicts.items = [];
            state.foundDicts.pageIndex = 0;
            state.foundDicts.totalCount = undefined;
        },

        setDocumentSearchResult: (state, action: PayloadAction<ISearchDocResults>) => {
            state.foundDocuments.items = action.payload.items;

            if (action.payload.totalCount) state.foundDocuments.totalCount = action.payload.totalCount;
        },
        setDocsSearchLoading: (state, action: PayloadAction<boolean>) => {
            state.foundDocuments.isLoading = action.payload;
        },
        setDocsPagerChanged: (state, action: PayloadAction<ISearchData>) => {
            if (state.foundDocuments.pageSize != (action.payload.pageSize ?? DEFAULT_PAGE_SIZE)) {
                state.foundDocuments.pageSize = action.payload.pageSize ?? DEFAULT_PAGE_SIZE;
            }
            if (state.foundDocuments.pageIndex != (action.payload.pageIndex ?? 0)) {
                state.foundDocuments.pageIndex = action.payload.pageIndex ?? 0;
            }
            if (state.foundDocuments.orderBy != action.payload.orderBy) {
                state.foundDocuments.orderBy = action.payload.orderBy;
            }
        },

        setDictsSearchLoading: (state, action: PayloadAction<boolean>) => {
            state.foundDicts.isLoading = action.payload;
        },
        setDictsSearchResult: (state, action: PayloadAction<ISearchDictResults>) => {
            state.foundDicts.items = action.payload.items;

            if (action.payload.totalCount) state.foundDicts.totalCount = action.payload.totalCount;
        },

        setDictsPagerChanged: (state, action: PayloadAction<ISearchData>) => {
            if (state.foundDicts.pageSize != (action.payload.pageSize ?? DEFAULT_PAGE_SIZE)) {
                state.foundDicts.pageSize = action.payload.pageSize ?? DEFAULT_PAGE_SIZE;
            }
            if (state.foundDicts.pageIndex != (action.payload.pageIndex ?? 0)) {
                state.foundDicts.pageIndex = action.payload.pageIndex ?? 0;
            }
            if (state.foundDicts.orderBy != action.payload.orderBy) {
                state.foundDicts.orderBy = action.payload.orderBy;
            }
        },

        setReportsSearchLoading: (state, action: PayloadAction<boolean>) => {
            state.foundReports.isLoading = action.payload;
        },
        setReportsSearchResult: (state, action: PayloadAction<ISearchReportResults>) => {
            state.foundReports.items = action.payload.items;

            if (action.payload.totalCount) state.foundReports.totalCount = action.payload.totalCount;
        },
        setReportPagerChanged: (state, action: PayloadAction<ISearchData>) => {
            if (state.foundReports.pageSize != (action.payload.pageSize ?? DEFAULT_PAGE_SIZE)) {
                state.foundReports.pageSize = action.payload.pageSize ?? DEFAULT_PAGE_SIZE;
            }
            if (state.foundReports.pageIndex != (action.payload.pageIndex ?? 0)) {
                state.foundReports.pageIndex = action.payload.pageIndex ?? 0;
            }
            if (state.foundReports.orderBy != action.payload.orderBy) {
                state.foundReports.orderBy = action.payload.orderBy;
            }
        },

        setExtraFlowsFilterLoading: (state, action: PayloadAction<boolean>) => {
            if (state.extraFilter && state.extraFilter.flowsFields)
                state.extraFilter.flowsFields.isLoading = action.payload;
        },

        setExtraFlowsFilterData: (state, action: PayloadAction<ISearchExtraFlowSetting[]>) => {
            if (!state.extraFilter) state.extraFilter = {};
            if (!state.extraFilter.flowsFields) state.extraFilter.flowsFields = {};

            state.extraFilter.flowsFields.flows = action.payload;
        },

        selectExtraFilterFlow: (state, action: PayloadAction<ISearchExtraFlowSetting | undefined>) => {
            if (state.extraFilter && state.extraFilter.flowsFields) {
                state.extraFilter.flowsFields.selectedFlow = action.payload;
                if (action.payload) {
                    state.extraFilter.flowsFields.fieldsValues = action.payload.fields.map(
                        (x) =>
                            ({
                                ...x,
                                value: undefined,
                            }) as ISearchExtraFlowFieldWithValue,
                    );
                } else {
                    state.extraFilter.flowsFields.fieldsValues = undefined;
                }
            }
        },

        clearExtraFilters: (state, action: PayloadAction<ISearchExtraBaseFilter>) => {
            state.extraFilter = action.payload;
        },
    },
});
export const {
    setSearchingData,
    setGlobalSearchLoading,
    setDocumentSearchResult,
    resetAllSearchResults,
    setBaseRequisitesFields,
} = SearchSlice.actions;
export default SearchSlice.reducer;
