import React, { useState } from 'react';
import { RouteMapExecutionService } from '@services/actions/RouteMapExecutionService';
import MessageDialog, { IMessageDialogProps } from '@molecules/MessageDialog/MessageDialog';
import { IRemoveRouteMapProps } from './IRemoveRouteMapProps';

export const RemoveRouteMapModal: React.FC<IRemoveRouteMapProps> = (props: IRemoveRouteMapProps) => {
    const [errorText, setErrorText] = useState<string>();
    const [loading, setLoading] = useState<boolean>();

    const serv = new RouteMapExecutionService();

    const onSubmit = () => {
        return serv
            .remove(props.actionKey, props.activityKey, props.processKey, props.documentKey)
            .then((res) => {
                setLoading(false);

                props.completeHandler && props.completeHandler(true);
            })
            .catch((err) => {
                setErrorText(err);
            })
            .finally(() => setLoading(false));
    };

    const modalContainerProps: IMessageDialogProps = {
        header: 'Удаление этапа',
        message: 'Вы действительно хотите удалить этап?',
        size: props.modalSize,
        showOkButton: true,
        showCancelButton: true,
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
        errorText: errorText,
        okClick: onSubmit,
    };

    return <MessageDialog {...modalContainerProps}></MessageDialog>;
};
