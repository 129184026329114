import React, { useEffect, useState } from 'react';
import { ICreateReportModel } from '@models/document/actionsExecution/ICreateReportModel';
import { IOption } from '@/types';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import { FilesService } from '@services/FilesService';
import Modal from '@atoms/Modal';
import clsx from 'clsx';
import Button from '@atoms/Button';
import { MdClose } from 'react-icons/md';
import Preloader from '@atoms/Preloader';
import Hint from '@atoms/Hint';
import { FormControl } from '@controls/index';
import Select from '@atoms/Select';
import { ICreateReportExecutorProps } from './ICreateReportExecutorProps';

export const CreateReportExecutorModal: React.FC<ICreateReportExecutorProps> = (props: ICreateReportExecutorProps) => {
    const [errorText, setErrorText] = useState<string>();
    const [isBusy, setIsBusy] = useState<boolean>();
    const [model, setModel] = useState<ICreateReportModel>();
    const [selectedFormatOption, setSelectedFormatOption] = useState<IOption>();
    const [formatOptions, setFormatOptions] = useState<IOption[]>();

    const service = new DocumentExecutionService(props.docId);

    useEffect(() => {
        setIsBusy(true);
        service
            .getCreateReportModel(props.actionKey)
            .then((res) => {
                setModel(res.data);
                const reportFormats = res.data.reportFormats;
                if (reportFormats) {
                    const options = Object.keys(reportFormats).map((key) => {
                        return {
                            value: key,
                            label: reportFormats[key],
                        } as IOption;
                    });
                    if (options) {
                        setFormatOptions(options);
                        setSelectedFormatOption(options[0]);
                    }
                }
            })
            .catch((error) => setErrorText(error))
            .finally(() => {
                setIsBusy(false);
            });
    }, []);

    const onOkClick = (event: React.MouseEvent) => {
        setIsBusy(true);
        setErrorText(undefined);

        if (model) {
            FilesService.downloadFile(
                `documentExecution/downloadReportDoc/${props.docId}/${props.actionKey}/${
                    selectedFormatOption?.value ?? ''
                }`,
            )
                .then(() => {
                    props.completeHandler && props.completeHandler(false);
                })
                .catch((reason) => {
                    setErrorText(reason);
                })
                .finally(() => {
                    setIsBusy(false);
                });
        } else {
            setErrorText('Не удалось получить модель данных.');
            setIsBusy(false);
        }
    };

    return (
        <Modal
            className={clsx('modal-dlg-container')}
            size={props.modalSize}
            header={
                <div className={clsx('box')}>
                    <div>{props.title}</div>
                    <div className={clsx('left')}>
                        <Button
                            buttonType="text"
                            textColor="neutral"
                            size="xs"
                            aria-label="Закрыть окно"
                            onClick={() => {
                                if (props.completeHandler) props.completeHandler(false);
                            }}
                            startAdornment={<MdClose size="24" />}
                        />
                    </div>
                </div>
            }
            footer={
                <div className="modal-dlg-buttons">
                    <Button size="s" aria-label={props.okButtonText} onClick={onOkClick}>
                        {props.okButtonText}
                    </Button>

                    <Button
                        buttonType="light"
                        size="s"
                        aria-label={props.cancelButtonText}
                        onClick={() => {
                            if (props.completeHandler) props.completeHandler(false);
                        }}
                    >
                        {props.cancelButtonText}
                    </Button>
                </div>
            }
        >
            {isBusy ? (
                <Preloader size="m" position="static" />
            ) : (
                <div>
                    {errorText && <Hint icon="info" title={`Ошибка: ${errorText}`} variant="red" maxWidth={'100%'} />}

                    {model?.isReportFormatSelectable ? (
                        <FormControl name="format" label="Формат отчёта">
                            <Select
                                placeholder="Формат отчёта"
                                values={selectedFormatOption ? [selectedFormatOption] : []}
                                multiselect={false}
                                options={formatOptions ?? []}
                                readOnly
                                onChange={(option) => {
                                    setSelectedFormatOption(option[0]);
                                }}
                            />
                        </FormControl>
                    ) : null}
                </div>
            )}
        </Modal>
    );
};
