import React, { ReactNode, useEffect, useState } from 'react';
import { IRowArr, IRows } from '@models/Forms/IForms';
import './SubForm.scss';
import Button from '@atoms/Button';
import FormBuilder from '@molecules/formbuilder/FormBuilder';
import { ITableForm } from '@models/Forms/ITableForm';
import { Controller, useForm } from 'react-hook-form';
import { IField, IFieldElem } from '@models/IFormData';
import { sendErrorMsg } from '@molecules/Errors';
import Modal from '@atoms/Modal';
import FormProviderControl from '@controls/FormProviderControl';
import HookFormProvider from '@controls/HookFormProvider';
import { convertListToMap } from '@utils/documentUtils';
import { IDocumentData } from '@models/document/IDocumentData';
import { MdClose } from 'react-icons/md';
import clsx from 'clsx';

export interface ISubFormProps {
    form: IRowArr;
    onClose: () => void;
    onSubmit?: (data: IField[]) => void;
    isEdit: boolean;
    children?: ReactNode;
    docId?: string;
    submitButtonCaption?: string;
    modalCaption?: string;
    formFields: IDocumentData;
}

const SubForm: React.FC<ISubFormProps> = ({
    form,
    onClose,
    onSubmit,
    isEdit,
    children,
    docId,
    submitButtonCaption,
    modalCaption,
    formFields,
}: ISubFormProps) => {
    const [rows, setRows] = useState<IRows>();
    const [fieldsData, setFieldsData] = useState<Record<string, IFieldElem>>();
    const methods = useForm<ITableForm>({
        mode: 'onBlur', // "onChange"
    });
    const disSubmit = React.useRef(false);

    useEffect(() => {
        let rows = { rows: form } as IRows;
        methods.reset(formFields);
        setFieldsData(convertListToMap(formFields?.fields));
        setRows(rows);
    }, [form]);

    const ModalHeader = ({ modalCaption, onClose }: { modalCaption?: string; onClose: () => void }) => (
        <div className="box">
            {modalCaption ? modalCaption : 'Редактирование полей документа'}
            <div className={clsx('left')}>
                <Button
                    startAdornment={<MdClose size="24px" />}
                    buttonType="text"
                    textColor="neutral"
                    size="xs"
                    aria-label="Закрыть окно"
                    onClick={onClose}
                />
            </div>
        </div>
    );

    const ModalFooter = ({ onClose }: { onClose: () => void }) => (
        <div className={'subFormModal_footer'}>
            {isEdit && (
                <Button type="submit" form="subForm" size="s" disabled={disSubmit.current}>
                    {submitButtonCaption ? submitButtonCaption : 'Сохранить'}
                </Button>
            )}
            <Button onClick={onClose} buttonType="light" size="s">
                {isEdit ? 'Отменить' : 'Ок'}
            </Button>
        </div>
    );

    const dirtyValues = (dirtyFields: any, allValues: ITableForm) => {
        return Object.keys(dirtyFields.fields).map((key) => allValues.fields[+key]);
    };

    const onSubmitHandler = (data: ITableForm) => {
        disSubmit.current = true;
        let dirtyFields = methods.formState.dirtyFields;
        let dirtyData = dirtyValues(dirtyFields, data);
        onSubmit && onSubmit(dirtyData);
    };

    return (
        <Modal
            size="xl"
            header={<ModalHeader modalCaption={modalCaption} onClose={onClose} />}
            footer={<ModalFooter onClose={onClose} />}
        >
            <FormProviderControl
                id="subForm"
                formMethods={methods}
                className="sub-form"
                onSubmit={onSubmit ? onSubmitHandler : () => {}}
            >
                <HookFormProvider controller={Controller}>
                    <div className="sub-form__wrapper">
                        {children}

                        <div className="sub-form__content">
                            <FormBuilder
                                fields={fieldsData!}
                                docId={docId}
                                setError={(errors?: string[]) => {
                                    if (errors) {
                                        sendErrorMsg({
                                            message: errors,
                                        });
                                    }
                                }}
                                rows={rows}
                                isEdit={isEdit}
                                isNew={false}
                                formMethods={methods}
                                includedFields={[]}
                                uniqueKey={docId ? docId : '-1'}
                            />
                        </div>
                    </div>
                </HookFormProvider>
            </FormProviderControl>
        </Modal>
    );
};

export default SubForm;
