import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { ITreeListDescriptionResponse } from '@models/response/ITreeListDescriptionResponse';
import { IGridExportSettings } from '@models/response/IGridExportSettings';
import { IGridResponse } from '@models/response/IGridResponse';
import { getLoadOptionsQuery } from '@utils/loadOptionsConverter';
import { IDataTreeService } from '@services/IDataTreeService';
import { FilesService } from '@services/FilesService';

export class DocumentAttachService extends IDataTreeService {
    documentId: string;

    constructor(docId: string) {
        super();
        this.documentId = docId;
    }

    async fetchScheme(data: any): Promise<AxiosResponse<ITreeListDescriptionResponse, any>> {
        return $api.get<ITreeListDescriptionResponse>(`/document/attachScheme/${this.documentId}/${data}`);
    }

    async fetchData(data: any, parentData: any, loadOptions: any): Promise<AxiosResponse<IGridResponse, any>> {
        return $api.get<IGridResponse>(`/document/attachData/${this.documentId}/${data}/${parentData}${loadOptions}`);
    }

    async fetchCount(tableKey: any): Promise<AxiosResponse<number, any>> {
        return $api.get<number>(`/document/attachCount/${this.documentId}/${tableKey}`);
    }

    async exportData(template: string, options: any, columns: IGridExportSettings[]): Promise<void> {
        const url = `document/export?template=${template}&${getLoadOptionsQuery(options)}`;
        await FilesService.downloadFilePost(url, columns);
    }
}
