import React from 'react';
import { IActivityAction, IBaseAction } from '@models/actions/IBaseAction';
import { getActivityIdFromCompositeId } from '@utils/helpers';
import { ModalSize } from '@/types/ModalSize';
import { ActivityBaseActionExecutor } from '@organisms/actions/ActivityBaseActionExecutor';
import { IChangePerformerProps } from './IChangePerformerProps';
import { ChangePerformerModal } from './ChangePerformerModal';

export class ChangePerformerExecutor extends ActivityBaseActionExecutor {
    private _modalProps?: IChangePerformerProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        let activityAction = action as IActivityAction;
        this._modalProps = {
            activityId: getActivityIdFromCompositeId(objId),
            docId: activityAction?.docId ?? '-1',
            actionKey: action.key,
            title: action.options?.title ?? '',
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'l',
            reasons: action.options?.reasons ?? [],
            addCustomReason: action.options?.addCustomReason ?? false,
            customReasonRequired: action.options?.customReasonRequired ?? false,
            customReasonMinLength: action.options?.customReasonMinLength ?? 0,
            customReasonLabel: action.options?.customReasonLabel ?? 'Причина замены',
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <ChangePerformerModal {...this._modalProps} /> : <></>;
    };
}
