import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IUserAvatarState {
    photoContent: string | null;
}

const initialState: IUserAvatarState = {
    photoContent: null,
};

export const UserAvatarSlice = createSlice({
    name: 'userAvatar',
    initialState,
    reducers: {
        setAvatar: (state, action: PayloadAction<IUserAvatarState>) => {
            state.photoContent = action.payload.photoContent;
        },
        clearAvatar: (state) => {
            state.photoContent = null;
        },
    },
});

export const { setAvatar, clearAvatar } = UserAvatarSlice.actions;

export default UserAvatarSlice.reducer;
