import { IFieldElem } from '@models/IFormData';
import { IDictVariable, IVariable, IVariables } from '@models/Forms/IForms';
import { getValueString } from 'src/utils/documentUtils';
import { DictionariesService } from '@services/DictionariesService';
import { IUser } from '@models/IUser';

export class VariablesManager {
    private _variables: IVariables;
    private _docId: string;
    private _currentUser: IUser;

    constructor(variables: IVariables, docId: string, currentUser: IUser) {
        this._variables = variables;
        this._docId = docId;
        this._currentUser = currentUser;
    }

    async GetData(data: Record<string, IFieldElem>, isEdit: boolean, isNew: boolean) {
        const result = {} as Record<string, any>;
        result['docId'] = this._docId;

        result['currentUserId'] = this._currentUser.id;
        result['currentUser'] = this._currentUser.name;
        result['currentUserFullName'] = this._currentUser.fullName;
        result['currentUserFirstName'] = this._currentUser.firstName;
        result['currentUserSurName'] = this._currentUser.lastName;
        result['currentUserPatronymic'] = this._currentUser.middleName;
        result['currentUserMailbox'] = this._currentUser.email;
        result['currentUserPhoneNumber'] = this._currentUser.phone;
        result['currentUserRole'] = this._currentUser.role;
        result['currentUserDepartment'] = this._currentUser.department;
        result['currentUserDepartmentId'] = this._currentUser.departmentId;
        result['currentUserPost'] = this._currentUser.position;
        result['currentUserSubdivision'] = this._currentUser.subdivision;
        result['currentUserSubdivisionInn'] = this._currentUser.subdivisionInn;
        result['currentUserWorkGroups'] = this._currentUser.workGroups;
        result['currentUserAccessGroups'] = this._currentUser.accessGroups;

        this._variables.variable.forEach((variable) => {
            result[variable.variableName] = this.getVariable(variable, data);
        });
        for (let index = 0; index < this._variables.dictVariable.length; index++) {
            const variable = this._variables.dictVariable[index];
            if (
                (variable.onlyNew && isNew) ||
                (variable.onlyEdit && isEdit) ||
                (!variable.onlyEdit && !variable.onlyNew)
            ) {
                result[variable.variableName] = await this.getDictVariable(variable, data);
            }
        }

        return result;
    }
    private getVariable(variable: IVariable, data: Record<string, IFieldElem>) {
        let result = variable.variableFormat;
        variable.fieldVariable.forEach((variable) => {
            let val = getValueString(variable.key, data) as string;
            let localVariable = val && variable.variableFormat ? variable.variableFormat.replace('{value}', val) : '';
            let template = '{' + variable.variableName + '}';
            result = result.replace(template, localVariable ? localVariable : '');
        });
        return result;
    }
    private async getDictVariable(variable: IDictVariable, data: Record<string, IFieldElem>) {
        let code = variable.code;
        if (code.includes('|Document')) {
            code = data[code].value;
        }

        let response = await DictionariesService.getSubTreeByCode(variable.name, code);
        return response.data;
    }
}
