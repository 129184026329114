import { IFieldElem } from '@models/IFormData';
import { UseFormReturn } from 'react-hook-form';
import { FormulaManager } from '@utils/managers/FormulaManager';

export class SetConditionManager {
    private _setValue: any;
    private _fieldsData: Record<string, IFieldElem>;
    private _methods: UseFormReturn<any>;
    private _isNew: boolean;
    private _isEdit: boolean;

    constructor(
        setValue: any,
        fieldsData: Record<string, IFieldElem>,
        methods: UseFormReturn<any>,
        isNew: boolean,
        isEdit: boolean,
    ) {
        this._setValue = setValue;
        this._fieldsData = fieldsData;
        this._methods = methods;
        this._isNew = isNew;
        this._isEdit = isEdit;
    }

    public async checkConditionAsync(condition: string) {
        if (!condition) return true;

        let setValue = this._setValue;
        if (typeof setValue === 'string') {
            setValue = `'${setValue}'`;
        }
        let formula = condition.replaceAll('{value}', setValue);

        let formulaMgr = new FormulaManager(formula);
        formulaMgr.Init(this._fieldsData, this._methods);
        return (await formulaMgr.EvalFormulaValues(this._isEdit, this._isNew)) as boolean;
    }
}
