import '../ActivityActions.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    IResolveExceptionActivityModel,
    IResolveExceptionModel,
} from '@models/document/activityExecutors/IResolveExceptionModel';
import { ActivityExecutionService } from '@services/actions/ActivityExecutionService';
import { Column, DataGrid, Paging, Scrolling, Selection } from 'devextreme-react/data-grid';
import { useForm } from 'react-hook-form';
import ModalContainer, { IModalContainerProps } from '@molecules/ModalContainer/ModalContainer';
import DevExpressDataGrid from '@atoms/DevExpress/DataGrid/DevExpressDataGrid';
import { onCellHoverChanged } from '@utils/dataGridUtils';
import { IResolveExceptionProps } from './IResolveExceptionProps';

export const ResolveExceptionModal: React.FC<IResolveExceptionProps> = (props: IResolveExceptionProps) => {
    const [errorText, setErrorText] = useState<string>();
    const [loading, setLoading] = useState<boolean>();
    const [actionModel, setActionModel] = useState<IResolveExceptionModel>();
    const [templateId, setTemplateId] = useState<string>('');

    const serv = new ActivityExecutionService();
    const gridRef = useRef<DataGrid>(null);

    useEffect(() => {
        setLoading(true);

        serv.getResolveExceptionModel(props.actionKey, props.activityId)
            .then((res) => {
                setActionModel(res.data);
                setLoading(false);
            })
            .finally(() => setLoading(false));
    }, []);

    const onSubmit = (form: any) => {
        if (templateId == '') {
            setErrorText('Не указан шаг маршрута');
            return;
        }

        setLoading(true);

        return serv
            .saveResolveException(props.actionKey, props.activityId, templateId)
            .then((res) => {
                setLoading(false);

                props.completeHandler && props.completeHandler(true);
            })
            .catch((err) => {
                setErrorText(err);
            })
            .finally(() => setLoading(false));
    };

    const formState = useForm<any>({
        mode: 'onBlur', // "onChange"
    });

    const modalContainerProps: IModalContainerProps = {
        header: props.title,
        size: props.modalSize,
        showOkButton: true,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        isBusy: loading,
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
        errorText: errorText,
        formMethods: formState,
        onSubmit: onSubmit,
    };

    const onSelectionChanged = useCallback((e: any) => {
        if (e.currentSelectedRowKeys.length > 0) {
            let currentKeys = e.currentSelectedRowKeys.map((data: IResolveExceptionActivityModel) => data.templateId);
            let deselectedRows = e.selectedRowsData
                .filter((data: IResolveExceptionActivityModel) => {
                    return (
                        currentKeys.length > 0 &&
                        !currentKeys.some((currentKey: string) => currentKey == data.templateId)
                    );
                })
                .map((data: IResolveExceptionActivityModel) => data);

            e.component.deselectRows(deselectedRows);

            // Один выбранный элемент
            setTemplateId(currentKeys.length > 0 ? currentKeys[0] : '');
        }
    }, []);

    return (
        <ModalContainer {...modalContainerProps}>
            <DevExpressDataGrid
                dataSource={actionModel?.activities}
                remoteOperations={true}
                hoverStateEnabled={true}
                columnHidingEnabled={false}
                showColumnHeaders={true}
                columnAutoWidth={false}
                allowColumnReordering={false}
                allowColumnResizing={true}
                columnResizingMode="widget"
                noDataText={'Нет доступных этапов маршрута'}
                rowAlternationEnabled={true}
                ref={gridRef}
                onSelectionChanged={onSelectionChanged}
                onCellHoverChanged={onCellHoverChanged}
            >
                <Selection mode="multiple" allowSelectAll={false} showCheckBoxesMode={'always'} />

                <Scrolling mode="virtual" />
                <Paging enabled={true} defaultPageSize={10} />

                <Column caption="Название" dataField="activityName" width={'60%'} />
                <Column caption="Исполнитель" dataField="performerName" />
            </DevExpressDataGrid>
        </ModalContainer>
    );
};
