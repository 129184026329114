import React, { FC, useEffect, useRef } from 'react';
import './Modal.scss';
import { createPortal } from 'react-dom';
import { MdClose } from 'react-icons/md';
import clsx from 'clsx';
import Button from '../Button';
import { DropdownContainerContext } from '@/context/Contexts';
import { ModalSize } from '@/types/ModalSize';

export interface IModalProps {
    /** Контент модалки */
    children: React.ReactNode;
    /** Событие закрытия */
    onClose?: () => void;
    /** Контент для шапки в модальном окне */
    header?: React.ReactNode;
    /** Контент для футера в модальном окне */
    footer?: React.ReactNode;
    /** Кастомный компонент вместо
     * @default false
     */
    custom?: boolean;
    /** Вид модалки
     * @default modal
     */
    variant?: 'modal' | 'drawer';
    /** Ширина Модалки
     * @default s
     */
    size?: ModalSize;
    /** Показывать разделительную полоску в footer
     * @default true
     */
    showLine?: boolean;
    /** Класс */
    className?: string;
}

const Modal: FC<IModalProps> = ({
    children,
    onClose,
    header,
    footer,
    custom = false,
    showLine = true,
    variant = 'modal',
    size = 'xl',
    className = '',
}: IModalProps) => {
    const modalRef = useRef<HTMLDivElement | null>(null);

    /** При маунте добавляем модалку. При дестрое - удаляем. */
    useEffect(() => {
        document.body.style.overflowY = 'hidden';

        if (modalRef.current) {
            modalRef.current.focus();
        }

        return () => {
            document.body.style.overflowY = 'auto';
        };
    }, []);

    /** Обертка для модалки */
    const modal = (
        <DropdownContainerContext value={null}>
            <div
                data-testid="rf-modal"
                className={clsx(
                    'rf-modal',
                    `rf-modal--${variant}`,
                    `rf-modal--${size}`,
                    showLine && 'rf-modal__footer--line',
                    className,
                )}
            >
                <div className="rf-modal__bg" />
                <div className="rf-modal__container" ref={modalRef} tabIndex={-1}>
                    {onClose && (
                        <Button
                            buttonType="text"
                            startAdornment={<MdClose />}
                            className="rf-modal__close-button"
                            onClick={onClose}
                        />
                    )}

                    {custom ? (
                        children
                    ) : (
                        <div className="rf-modal__wrapper">
                            {header && <div className="rf-modal__header">{header}</div>}
                            <div className="rf-modal__content">{children}</div>
                            {footer && <div className={'rf-modal__footer'}>{footer}</div>}
                        </div>
                    )}
                </div>
            </div>
        </DropdownContainerContext>
    );

    return createPortal(modal, document.body);
};

export default Modal;
