import React, { FC, ReactNode } from 'react';
import Button from '@atoms/Button';
import Folders from '@molecules/Folders/Folders';
import { MdMenu, MdMenuOpen } from 'react-icons/md';
import { useActions } from '@hooks/useActions';
import { useTypedSelector } from '@hooks/useTypedSelector';
import Notifications from '@molecules/Notifications';
import Errors from '@molecules/Errors';
import Tooltip from '@atoms/Tooltip';
import ReleaseNotes from '@molecules/ReleaseNotes/ReleaseNotes';
import clsx from 'clsx';

export interface ILayoutMainProps {
    /** Наполнение */
    children: ReactNode;
    /** Панель вверху */
    header?: ReactNode;
    /** Левое меню */
    sidebar?: ReactNode;
}

const LayoutMain: FC<ILayoutMainProps> = ({ children, header, sidebar }: ILayoutMainProps) => {
    const isMenuOpen = useTypedSelector((state) => state.mainLayout.isMenuOpen);

    const { toggleMenu } = useActions();

    const toggleClick = () => {
        toggleMenu(!isMenuOpen);
    };

    return (
        <>
            <meta
                name="viewport"
                content={
                    // Для устройств с маленьким экраном устанавливаем фиксированный вьюпорт, чтобы верстка не ломалась
                    window.screen.width < 1280 ? 'width=1280' : 'width=device-width, initial-scale=1, shrink-to-fit=no'
                }
            />

            <header>
                <div className="header-navbar">
                    <div className="header-navbar__burger-menu">
                        <Tooltip openDelay={100} background="black" position="bottom">
                            <Button
                                buttonType="text"
                                textColor="neutral"
                                size="m"
                                onClick={toggleClick}
                                className="menu-toggle-button"
                                startAdornment={isMenuOpen ? <MdMenuOpen size="24" /> : <MdMenu size="24" />}
                            />
                            {isMenuOpen ? 'Скрыть навигацию' : 'Показать навигацию'}
                        </Tooltip>
                    </div>

                    {header}
                </div>
            </header>

            <div className="main-container">
                <aside className={clsx('sidebar', isMenuOpen ? 'sidebar--open' : 'sidebar--close')}>
                    <Folders isOpen={true} />
                </aside>
                <main className={clsx('content', isMenuOpen ? 'content--open' : 'content--close')}>
                    <div className="main-box">
                        <div className="notes">
                            <ReleaseNotes />
                        </div>
                        <div className="main-content">{children}</div>
                    </div>
                </main>
                <Notifications />
                <Errors />
            </div>
        </>
    );
};

export default LayoutMain;
