import './AttachFilesFromExistingDocsExecutor.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IAttachListItemModel } from '@models/attaches/IAttachListItemModel';
import { Column, DataGrid, Selection } from 'devextreme-react/data-grid';
import { useActions } from '@hooks/useActions';
import { AttachActionsService } from '@services/actions/AttachActionsService';
import Modal from '@atoms/Modal';
import clsx from 'clsx';
import Button from '@atoms/Button';
import { MdClose } from 'react-icons/md';
import Preloader from '@atoms/Preloader';
import DevExpressDataGrid from '@atoms/DevExpress/DataGrid/DevExpressDataGrid';
import { onCellHoverChanged } from '@utils/dataGridUtils';
import { IAttachFilesFromExistingDocsExecutorProps } from './IAttachFilesFromExistingDocsExecutorProps';

export const AttachFilesFromExistingDocsExecutorModal: React.FC<IAttachFilesFromExistingDocsExecutorProps> = (
    props: IAttachFilesFromExistingDocsExecutorProps,
) => {
    const [isBusy, setIsBusy] = useState<boolean>();
    const [filesToSelect, setFilesToSelect] = useState<IAttachListItemModel[]>();
    const [checkedAttach, setCheckedAttach] = useState<string[]>([]);

    const gridRef = useRef<DataGrid>(null);

    const { setPartsVersionToAttach } = useActions();

    useEffect(() => {
        let service = new AttachActionsService(props.docId, '-1');
        if (props.payloadData.docIds) {
            setIsBusy(true);
            let ids = props.payloadData.docIds.split(',');
            service.getDocsAttaches(ids).then((res) => {
                setFilesToSelect(res.data);
                setIsBusy(false);
            });
        }
    }, []);

    const onSelectionChanged = useCallback((e: any) => {
        if (e.currentSelectedRowKeys.length > 0) {
            let data = e.selectedRowsData.pop();
            let key = e.selectedRowKeys.pop();
            e.component.deselectRows(e.selectedRowKeys);
            //setPartsVersionToAttach();
            setCheckedAttach([key.docIdAndPartNum]);
        } else if (e.selectedRowKeys.length == 0) {
            //setPartsVersionToAttach([]);
            setCheckedAttach([]);
        }
    }, []);

    const onRowClick = useCallback((e: any) => {
        const keys = e.component.getSelectedRowKeys();
        const index = keys.indexOf(e.key);
        if (index > -1) {
            keys.splice(index, 1);
        } else {
            keys.splice(0, keys.length);
            keys.push(e.key);
        }
        e.component.selectRows(keys);
    }, []);

    return (
        <Modal
            className={clsx('modal-dlg-container')}
            size={props.modalSize}
            header={
                <div className={clsx('box')}>
                    <div>{props.action.displayName}</div>
                    <div className={clsx('left')}>
                        <Button
                            buttonType="text"
                            textColor="neutral"
                            size="xs"
                            aria-label="Закрыть окно"
                            onClick={() => {
                                if (props.completeHandler) props.completeHandler(false);
                            }}
                            startAdornment={<MdClose size="24" />}
                        />
                    </div>
                </div>
            }
            footer={
                <div className="modal-dlg-buttons">
                    <Button
                        size="s"
                        aria-label={props.okButtonText}
                        onClick={() => {
                            if (props.completeHandler) {
                                setPartsVersionToAttach(checkedAttach);
                                props.completeHandler(true);
                            }
                        }}
                    >
                        {props.okButtonText}
                    </Button>
                    <Button
                        buttonType="light"
                        size="s"
                        aria-label={props.cancelButtonText}
                        onClick={() => {
                            if (props.completeHandler) props.completeHandler(false);
                        }}
                    >
                        {props.cancelButtonText}
                    </Button>
                </div>
            }
        >
            {isBusy ? (
                <Preloader size="m" position="static" />
            ) : (
                <div>
                    <DevExpressDataGrid
                        key="abooksearchGrid"
                        showRowLines={true}
                        columnAutoWidth={true}
                        columnMinWidth={30}
                        dataSource={filesToSelect}
                        remoteOperations={true}
                        hoverStateEnabled={true}
                        columnHidingEnabled={false}
                        showColumnHeaders={true}
                        allowColumnResizing={true}
                        columnResizingMode="widget"
                        ref={gridRef}
                        onSelectionChanged={onSelectionChanged}
                        onCellHoverChanged={onCellHoverChanged}
                        onRowClick={onRowClick}
                    >
                        <Selection mode="multiple" allowSelectAll={false} selectAllMode="page" />

                        <Column
                            key={`col_attachName`}
                            allowFiltering={true}
                            caption={'Файл'}
                            dataField={'attachName'}
                            dataType={'string'}
                            visible={true}
                            allowSorting={false}
                            filterOperations={['contains']}
                            showEditorAlways={false}
                            width={'*'}
                        />

                        <Column
                            key={`col_docName`}
                            allowFiltering={true}
                            caption={'Документ'}
                            dataField={'docName'}
                            dataType={'string'}
                            visible={true}
                            allowSorting={false}
                            filterOperations={['contains']}
                            showEditorAlways={false}
                        />

                        <Column
                            key={`col_isManualCreated`}
                            allowFiltering={true}
                            caption={'Создан вручную'}
                            dataField={'isManualCreated'}
                            dataType={'boolean'}
                            visible={true}
                            allowSorting={false}
                            filterOperations={['contains']}
                            showEditorAlways={false}
                            width={'*'}
                        />
                    </DevExpressDataGrid>
                </div>
            )}
        </Modal>
    );
};
