import './CreateDocumentLinkExecutor.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ICreateDocumentLinkModel } from '@models/document/actionsExecution/ICreateDocumentLinkModel';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import {
    Column,
    DataGrid,
    Grouping,
    GroupPanel,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
    Selection,
} from 'devextreme-react/data-grid';
import { useForm } from 'react-hook-form';
import { useActions } from '@hooks/useActions';
import { useTypedSelector } from '@hooks/useTypedSelector';
import ModalContainer, { IModalContainerProps } from '@molecules/ModalContainer/ModalContainer';
import DevExpressDataGrid from '@atoms/DevExpress/DataGrid/DevExpressDataGrid';
import { getColumnDataTypeByFieldDataType, onCellHoverChanged } from '@utils/dataGridUtils';
import { FilterRow } from 'devextreme-react/tree-list';
import { ActionExecutorModel } from '@organisms/actions/ActionExecutorModel';
import { ActionTypes } from '@models/actions/ActionTypes';
import { ICreateDocumentLinkExecutorProps } from './ICreateDocumentLinkExecutorProps';

export const CreateDocumentLinkExecutorModal: React.FC<ICreateDocumentLinkExecutorProps> = (
    props: ICreateDocumentLinkExecutorProps,
) => {
    const [errorText, setErrorText] = useState<string>();
    const [loading, setLoading] = useState<boolean>();
    const [selectedKeys, setSelectedKeys] = useState<number[]>([]);
    const [documentsModel, setDocumentsModel] = useState<ICreateDocumentLinkModel>();
    const [documentsList, setDocumentsList] = useState<Record<string, string>[]>([]);
    const service = new DocumentExecutionService(props.docId);
    const gridRef = useRef<DataGrid>(null);
    const formState = useForm<any>({
        mode: 'onBlur', // "onChange"
    });

    const { setActionWarning } = useActions();
    const { document: doc } = useTypedSelector((state) => state);

    useEffect(() => {
        setLoading(true);
        service
            .getCreateDocumentLinkModel(props.actionKey)
            .then((res) => {
                setDocumentsModel(res.data);
                setDocumentsList(res.data.tableValues);
            })
            .catch((error) => setErrorText(error))
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const onSubmit = () => {
        let selectedRows = gridRef?.current?.instance.getSelectedRowsData();
        return service
            .createDocumentLink(
                props.actionKey,
                selectedRows?.map((row) => row.docKey as string) as string[],
                doc.partsVersionToAttach,
            )
            .then((res) => {
                if (res.data.warningAction) {
                    setActionWarning(res.data.warningAction);
                    return;
                }

                props.completeHandler && props.completeHandler(true);
            })
            .catch((err) => {
                let msg = Array.isArray(err) ? err[0] : err;
                setErrorText(msg);
            });
    };

    const onSelectionChanged = useCallback((e: any) => {
        setSelectedKeys(e.selectedRowKeys);
    }, []);

    const modalContainerProps: IModalContainerProps = {
        header: props.title,
        size: props.modalSize,
        showOkButton: true,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        isBusy: loading,
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
        errorText: errorText,
        formMethods: formState,
        onSubmit: onSubmit,
        okButtonDisabled: selectedKeys.length === 0,
    };

    const allowedPageSizes = [5, 10, 20];

    const onRowClick = useCallback((e: any) => {
        if (e.component.getSelectedRowKeys().indexOf(e.key) > -1) {
            e.component.deselectRows([e.key]);
        } else {
            e.component.selectRows([e.key], true);
        }
    }, []);

    return !doc.warningAction ? (
        <ModalContainer {...modalContainerProps}>
            {documentsModel ? (
                <DevExpressDataGrid
                    dataSource={documentsList}
                    //dataSource={dataSource} // TODO Переключить грид на источник данных после реализации фильтрации и сортировки на бэке
                    remoteOperations={true}
                    hoverStateEnabled={true}
                    columnHidingEnabled={false}
                    showColumnHeaders={true}
                    columnAutoWidth={true}
                    allowColumnReordering={false}
                    allowColumnResizing={true}
                    columnResizingMode="widget"
                    noDataText={'Ничего не найдено'}
                    rowAlternationEnabled={true}
                    onSelectionChanged={onSelectionChanged}
                    ref={gridRef}
                    onRowClick={onRowClick}
                    onCellHoverChanged={onCellHoverChanged}
                >
                    <Selection
                        mode="multiple"
                        showCheckBoxesMode={'always'}
                        allowSelectAll={true}
                        selectAllMode={'allPages'}
                    />
                    <FilterRow visible={true} />
                    <HeaderFilter visible={true} />
                    <SearchPanel visible={true} placeholder="" />
                    <Grouping contextMenuEnabled={true} />
                    <GroupPanel visible={true} />
                    <Paging enabled={true} defaultPageSize={documentsModel?.pageSize} />
                    <Pager allowedPageSizes={allowedPageSizes} showPageSizeSelector={true} />
                    {documentsModel?.tableColumns.map((column, i) => {
                        return (
                            <Column
                                key={`col_${i}`}
                                caption={`${column.title}`}
                                dataField={`${column.dataField}`}
                                dataType={getColumnDataTypeByFieldDataType(column.dataType)}
                                allowSorting={true}
                                allowHeaderFiltering={true}
                                allowFiltering={true}
                                allowSearch={true}
                                allowReordering={false}
                                width={`${column.width}`}
                            />
                        );
                    })}
                </DevExpressDataGrid>
            ) : (
                <></>
            )}
        </ModalContainer>
    ) : (
        <ActionExecutorModel
            type={ActionTypes.Document}
            objId={doc?.data?.id?.toString() ?? '-1'}
            action={{
                displayName: doc.warningAction.message,
                key: 'warnActionKey',
                type: doc.warningAction?.type,
                hasAccessibility: false,
                hasGroupAccessibility: false,
                options: {
                    actionData: doc.warningAction?.actionData,
                    docData: doc.data,
                },
            }}
            completeHandler={() => {
                setActionWarning(undefined);
            }}
        />
    );
};
