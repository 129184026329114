import { AxiosResponse } from 'axios';
import DataGrid from 'devextreme-react/data-grid';
import TreeList from 'devextreme-react/tree-list';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { MdAdd, MdClose, MdDone, MdFlag, MdSave } from 'react-icons/md';
import { IGridStateSettings } from '@models/response/IGridStateSettings';
import { IDataGridStateService } from '@services/IDataGridStateService';
import clsx from 'clsx';
import Button from '@atoms/Button';
import Modal from '@atoms/Modal';

import './StateModal.scss';
import Input from '@atoms/Input';
import Tooltip from '@atoms/Tooltip';
import MessageDialog from '@molecules/MessageDialog/MessageDialog';
import { sendNotification } from '@molecules/Notifications';

export interface IStateModalProps {
    show: boolean;
    stateSettings?: IGridStateSettings[];
    onCloseClick: () => void;
    onRefreshGrid: () => void;
    onSelectState: (stateData: IGridStateSettings[] | undefined) => void;
    componentRef: React.RefObject<DataGrid | null> | React.RefObject<TreeList | null>;
    stateKey: string;
    stateService: IDataGridStateService;
}

export interface IStateModalConfirmProps {
    show?: boolean;
    message?: string;
    onSuccess?: () => void;
}

const StateModal: FC<IStateModalProps> = ({ ...props }: IStateModalProps) => {
    const [inputNameValue, setInputNameValue] = useState<string>('');
    const { stateSettings } = props;
    const { stateService } = props;
    const [confirmModalProps, setConfirmModalProps] = useState<IStateModalConfirmProps>();

    const onSaveState = useCallback(
        async (e: React.MouseEvent) => {
            let settingsArray: IGridStateSettings[] = [];

            if (stateSettings) {
                settingsArray = [...stateSettings];
            }

            if (settingsArray.some((val) => val.name === inputNameValue)) {
                sendNotification({
                    message: 'Состояние с таким названием уже существует',
                    variant: 'red',
                });
                return;
            }

            settingsArray.push(getCurrentState(props.componentRef, inputNameValue, undefined));

            await saveSettings(props.stateKey, settingsArray, (response) => props.onSelectState(settingsArray));

            setInputNameValue('');
        },
        [inputNameValue],
    );

    const saveSettings = (
        stateKey: string,
        settings: IGridStateSettings[],
        onSuccess: (response: AxiosResponse<any, any>) => void,
    ) => {
        return stateService.saveState(stateKey, settings).then((response) => {
            onSuccess(response);
        });
    };

    const getCurrentState = (
        ref: React.RefObject<DataGrid | null> | React.RefObject<TreeList | null>,
        name: string,
        template: IGridStateSettings | undefined = undefined,
    ) => {
        let settings: IGridStateSettings = {
            name: name,
            default: template ? template.default : false,
            selected: template ? template.selected : false,
            state: {
                columns: [],
                allowedPageSizes: [10, 20, 30],
                filterPanel: {
                    filterEnabled: false,
                },
                filterValue: '',
                pageIndex: 0,
                pageSize: 10,
                searchText: '',
            },
            filters: undefined,
            sort: undefined,
        };

        const columns = ref.current?.instance.getVisibleColumns();

        columns?.forEach((column, index) => {
            if (column.type !== 'selection' &&
                column.dataField !== 'actionColumn' &&
                column.dataField !== 'emptyColumn'
            ) {
                settings.state.columns.push({
                    dataField: column.dataField,
                    dataType: column.dataType,
                    name: column.name,
                    visible: column.visible,
                    visibleIndex: column.visibleIndex,
                    width: column.width,
                    sortIndex: column.sortIndex,
                    sortOrder: column.sortOrder,
                });
            }
        });

        settings.filters = ref.current?.instance.getCombinedFilter();
        settings.sort = ref.current?.instance.getDataSource().sort();

        return settings;
    };

    const confirmModal = confirmModalProps && confirmModalProps.show && (
        <MessageDialog
            header="Подтверждение действия"
            showOkButton={true}
            showCancelButton={true}
            message={confirmModalProps.message!}
            okClick={() => {
                confirmModalProps.onSuccess?.();
                setConfirmModalProps(undefined);

                return new Promise<void>((resolve, reject) => {
                    resolve();
                });
            }}
            cancelClick={() => {
                setConfirmModalProps(undefined);
            }}
        />
    );

    const stateSettingsRows = useMemo(() => {
        return stateSettings?.map((item, index) => (
            <tr key={'state_modal_row_' + index}>
                <td>
                    <Tooltip className={clsx('checkbox')} background={'black'}>
                        <Button
                            buttonType={item.selected ? 'primary' : 'secondary'}
                            textColor="neutral"
                            size="xs"
                            aria-label="Применить состояние"
                            startAdornment={<MdDone size="24" />}
                            onClick={(e: React.MouseEvent) => {
                                let newLocalSettings = [...stateSettings];
                                newLocalSettings.forEach((x) => (x.selected = false));
                                newLocalSettings[index].selected = true;
                                props.onSelectState(newLocalSettings);
                            }}
                        />
                        Применить состояние
                    </Tooltip>
                </td>
                <td>
                    <span className={clsx('state-name-common')}>{item.name}</span>
                </td>
                <td>
                    <Tooltip background={'black'}>
                        <Button
                            buttonType="secondary"
                            textColor="neutral"
                            size="xs"
                            aria-label="Сохранить изменения"
                            startAdornment={<MdSave size="24" />}
                            onClick={(e) => {
                                let settings = [...stateSettings];
                                setConfirmModalProps({
                                    show: true,
                                    message: `Сохранить состояние '${settings[index].name}'?`,
                                    onSuccess: () => {
                                        settings[index] = getCurrentState(
                                            props.componentRef,
                                            settings[index].name,
                                            settings[index],
                                        );
                                        saveSettings(props.stateKey, settings, (response) =>
                                            props.onSelectState(settings),
                                        );
                                    },
                                });
                            }}
                        />
                        Сохранить изменения
                    </Tooltip>
                </td>
                <td>
                    <Tooltip background={'black'}>
                        <Button
                            buttonType={item.default ? 'primary' : 'secondary'}
                            textColor="neutral"
                            size="xxs"
                            aria-label="Сделать состоянием по умолчанию"
                            startAdornment={<MdFlag size="24" />}
                            onClick={(e) => {
                                let settings = [...stateSettings];
                                setConfirmModalProps({
                                    show: true,
                                    message: `Установить основным состояние '${settings[index].name}'?`,
                                    onSuccess: () => {
                                        settings.forEach(
                                            (localSetting, localIndex) => (localSetting.default = localIndex === index),
                                        );
                                        saveSettings(props.stateKey, settings, (response) =>
                                            props.onSelectState(settings),
                                        );
                                    },
                                });
                            }}
                        />
                        Сделать состоянием по умолчанию
                    </Tooltip>
                </td>
                <td>
                    <Tooltip background={'black'}>
                        <Button
                            buttonType="secondary"
                            textColor="neutral"
                            size="xs"
                            aria-label="Удалить"
                            startAdornment={<MdClose size="24" />}
                            onClick={(e) => {
                                let settings = [...stateSettings];
                                setConfirmModalProps({
                                    show: true,
                                    message: `Удалить состояние '${settings[index].name}'?`,
                                    onSuccess: () => {
                                        settings.splice(index, 1);
                                        saveSettings(props.stateKey, settings, (response) => {
                                            props.onSelectState(settings);
                                            item.selected && props.onRefreshGrid();
                                        });
                                    },
                                });
                            }}
                        />
                        Удалить
                    </Tooltip>
                </td>
            </tr>
        ));
    }, [stateSettings]);

    const modal = props.show && (
        <>
            <Modal
                size="m"
                header={
                    <>
                        <div className={clsx('box')}>
                            <div>
                                <span>Список состояний</span>
                            </div>
                            <div className={clsx('left')}>
                                <Button
                                    buttonType="text"
                                    textColor="neutral"
                                    size="xs"
                                    aria-label="Закрыть окно"
                                    onClick={props.onCloseClick}
                                    startAdornment={<MdClose size="24" />}
                                />
                            </div>
                        </div>
                    </>
                }
            >
                <div>
                    <table className={'state-table'}>
                        <tbody>
                            <tr>
                                <td></td>
                                <td>
                                    <Input
                                        id="inputName"
                                        className={clsx('state-name-common')}
                                        type="text"
                                        placeholder={'Название состояния'}
                                        value={inputNameValue}
                                        onInput={(e) => {
                                            setInputNameValue(e.currentTarget.value);
                                        }}
                                    />
                                </td>
                                <td>
                                    <Tooltip background={'black'}>
                                        <Button
                                            buttonType="secondary"
                                            textColor="neutral"
                                            size="xs"
                                            aria-label="Добавить состояние"
                                            startAdornment={<MdAdd size="24" />}
                                            onClick={onSaveState}
                                            disabled={!inputNameValue}
                                        />
                                        Добавить состояние
                                    </Tooltip>
                                </td>
                                <td></td>
                                <td></td>
                            </tr>

                            {stateSettingsRows}

                            {/* {stateSettings?.map((item, index) => {})} */}
                        </tbody>
                    </table>
                </div>
            </Modal>
        </>
    );

    return (
        <>
            {modal}
            {confirmModal}
        </>
    );
};

export default StateModal;
