import { IStandartValidator, ITableValidator, IValidator, IValidatorBase } from '@models/Forms/IForms';
import { IFieldElem } from '@models/IFormData';
import { UseFormReturn } from 'react-hook-form';
import { FormulaManager } from '@utils/managers/FormulaManager';

export class ValidatorManager {
    private _fieldsData: Record<string, IFieldElem>;
    private _methods: UseFormReturn<any>;

    constructor(fieldsData: Record<string, IFieldElem>, methods: UseFormReturn<any>) {
        this._fieldsData = fieldsData;
        this._methods = methods;
    }

    private getValue(name: string, rowData?: any, rowParent?: any) {
        if (name) {
            if (name.includes('|Document') || name.startsWith('#links')) {
                if (this._fieldsData) {
                    let field = this._fieldsData[name];
                    if (field) {
                        let fname = ('fields.[' + field.index + '].value') as any;
                        let val = this._methods.getValues(fname);
                        return val as any;
                    } else {
                        return null;
                    }
                }
            } else if (name.includes('|Parent') && rowParent) {
                let fname = name.replace('|Parent', '');
                type ObjectKey = keyof typeof rowParent;
                const attrNAme = fname as ObjectKey;
                return rowParent[attrNAme] as any;
            } else {
                if (rowData) {
                    type ObjectKey = keyof typeof rowData;
                    const attrNAme = name as ObjectKey;
                    return rowData[attrNAme] as any;
                }
            }
        }
    }

    private checkStandartValidator(validator: IValidatorBase): validator is IStandartValidator {
        return validator.type === 'standart';
    }

    private checkTableValidator(validator: IValidatorBase): validator is ITableValidator {
        return validator.type === 'table';
    }

    private async checkValidatorConditionAsync(validator: IValidator, rowData?: any): Promise<boolean> {
        let manager = new FormulaManager(validator.condition);
        manager.Init(this._fieldsData!, this._methods);
        let values = manager.GetFields().map((field) => this.getValue(field, rowData));
        return await manager.EvalFormulaValues(true, true, values);
    }

    private async getValidatorMessageAsync(validator: IValidator, rowData?: any): Promise<string> {
        let manager = new FormulaManager(validator.message);
        manager.Init(this._fieldsData!, this._methods);
        let values = manager.GetFields().map((field) => this.getValue(field, rowData));
        return (await manager.ReplaceFormulaValues(true, true, values)) ?? '';
    }

    private async validateStandartAsync(validator: IStandartValidator, errors: string[], rowData?: any) {
        if (await this.checkValidatorConditionAsync(validator, rowData)) {
            errors.push(await this.getValidatorMessageAsync(validator, rowData));
        }
    }

    private async validateTableAsync(validator: ITableValidator, errors: string[], rowData?: any) {
        if (await this.checkValidatorConditionAsync(validator, rowData)) {
            let _errors: string[] = [];
            let rows = this.getValue(validator.key, rowData) as any[];
            for (const row of rows) {
                await this.validateAllAsync(validator.validators, _errors, row);
            }
            if (_errors.length > 0) {
                let message = await this.getValidatorMessageAsync(validator, rowData);
                if (message !== '') {
                    errors.push(message);
                }
                errors.push(..._errors.filter((value, index, array) => array.indexOf(value) == index));
            }
        }
    }

    private async validateAsync(validator: IValidatorBase, errors: string[], rowData?: any) {
        if (this.checkStandartValidator(validator)) {
            await this.validateStandartAsync(validator, errors, rowData);
        } else if (this.checkTableValidator(validator)) {
            await this.validateTableAsync(validator, errors, rowData);
        }
    }

    public async validateAllAsync(validators: IValidatorBase[] | undefined, errors: string[], rowData?: any) {
        if (validators) {
            for (const validator of validators) {
                await this.validateAsync(validator, errors, rowData);
            }
        }
    }
}
