import './DictpickerModal.scss';
import React, { FC, useState } from 'react';
import { MdClose } from 'react-icons/md';
import clsx from 'clsx';
import Button from '@atoms/Button';
import Modal from '../../Modal';
import SearchModeControl from './ModeControls/SearchModeControl';
import { IExternalSearchDict } from '@models/Forms/IForms';
import { DictionariesService } from '@services/DictionariesService';
import { sendErrorMsg } from '@molecules/Errors';
import { IErrorsButton } from '@molecules/Errors/IErrorMessage';

export interface IDictExternalSearchModalProp {
    settings: IExternalSearchDict;
    onCloseModal: () => void;
    onSubmitModal: (data: string) => void;
    //getParams: (options: any) => string;
}

const DictExternalSearchModal: FC<IDictExternalSearchModalProp> = (p: IDictExternalSearchModalProp) => {
    const [selectedRowItem, setSelectedRowItem] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    const ModalFooter = ({ onClose, onSubmit }: { onClose: () => void; onSubmit: (data: any) => void }) => (
        <div className={'dictModal_footer'}>
            <Button
                disabled={selectedRowItem == undefined || loading}
                onClick={() => {
                    if (selectedRowItem) {
                        setLoading(true);
                        DictionariesService.saveExternalSearch(p.settings.submitApiUrl, selectedRowItem)
                            .then((response) => {
                                if (response.data.message) {
                                    if (p.settings.errorMessages && p.settings.errorMessages.errorMessage) {
                                        let conf = p.settings.errorMessages.errorMessage.find(
                                            (x) => x.errorValue === response.data.message,
                                        );
                                        if (conf) {
                                            let buttons: IErrorsButton[] = [];
                                            conf?.buttons?.forEach((x) => {
                                                let url = x.act.replace('{payload}', response.data.payLoadId);
                                                buttons.push({
                                                    title: x.title,
                                                    onClick: () => {
                                                        window.open(`.${url}`, '_blank');
                                                    },
                                                });
                                            });
                                            sendErrorMsg({
                                                message: [conf.message],
                                                closeBtnTitle: conf.closeBtnTitle,
                                                buttons: buttons,
                                                closeBtnClick: () => {
                                                    onSubmit(response.data.payLoadKey);
                                                    onClose();
                                                },
                                            });
                                        } else {
                                            sendErrorMsg({
                                                message: [response.data.message],
                                                closeBtnClick: () => {
                                                    onSubmit(response.data.payLoadKey);
                                                    onClose();
                                                },
                                            });
                                        }
                                    } else {
                                        sendErrorMsg({
                                            message: [response.data.message],
                                            closeBtnClick: () => {
                                                onSubmit(response.data.payLoadKey);
                                                onClose();
                                            },
                                        });
                                    }
                                } else {
                                    onSubmit(response.data.payLoadKey);
                                    onClose();
                                }
                            })
                            .finally(() => {
                                setLoading(false);
                            });
                    } else {
                        onClose();
                    }
                }}
                size="s"
            >
                Выбрать
            </Button>
            <Button onClick={onClose} buttonType="light" size="s">
                Отменить
            </Button>
        </div>
    );

    return (
        <Modal
            className={clsx('dictExternalSearchModal', 'dx-dropdowneditor-overlay')}
            size="content"
            footer={<ModalFooter onClose={p.onCloseModal} onSubmit={p.onSubmitModal} />}
            header={
                <div className={clsx('box')}>
                    <div>
                        <div>{p.settings.buttonName}</div>
                    </div>
                    <div className={clsx('left')}>
                        <Button
                            buttonType="text"
                            textColor="neutral"
                            size="xs"
                            aria-label="Закрыть окно"
                            onClick={() => {
                                p.onCloseModal();
                            }}
                            startAdornment={<MdClose size="24" />}
                        />
                    </div>
                </div>
            }
        >
            <div className="dict-container">
                <div className="dict-grid">
                    <SearchModeControl
                        {...p.settings}
                        onSelectedRowItems={(data) => {
                            setSelectedRowItem(data);
                        }}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default DictExternalSearchModal;
