export interface IGridSettings {
    wordWrapMode: GridWordWrapMode;
}

export enum GridWordWrapMode {
    // Не переносить (в одну строку)
    NoWrap,
    // Ограничение количества строк
    Trim,
    // Переносить
    Wrap,
}
