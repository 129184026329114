import React from 'react';
import { IBaseAction } from '@models/actions/IBaseAction';
import { IActionExecutor } from '@organisms/actions/IActionExecutor';
import { ModalSize } from '@/types/ModalSize';
import { IRemoveRouteMapProps } from './IRemoveRouteMapProps';
import { RemoveRouteMapModal } from './RemoveRouteMapModal';

export class RemoveRouteMapExecutor implements IActionExecutor {
    private _modalProps?: IRemoveRouteMapProps;

    run = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        raiseVisualElement?: () => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            actionKey: action.key,
            activityKey: action.options?.activityKey,
            processKey: action.options?.processKey,
            documentKey: action.options?.documentKey,
            modalSize: modalSize ?? action.options?.modalSize ?? 'l',
            completeHandler: completeHandler,
        };
    };

    visualElement = () => {
        return this._modalProps ? <RemoveRouteMapModal {...this._modalProps} /> : <></>;
    };
}
