import './ErrorPage.scss';
import React, { FC, useCallback } from 'react';
import { MdContentCopy, MdHome, MdRefresh, MdReport } from 'react-icons/md';
import Button from '@atoms/Button';
import Accordion from '@atoms/Accordion';
import { useCopyToClipboard } from 'usehooks-ts';
import Tooltip from '@atoms/Tooltip';
import { FallbackProps } from 'react-error-boundary/dist/declarations/src/types';

interface ErrorPageProps extends FallbackProps {}

const ErrorPage: FC<ErrorPageProps> = ({ error, resetErrorBoundary }) => {
    const errorText =
        error.message !== undefined ? error.message : typeof error === 'string' ? error : (error?.toString() ?? '');

    const [, copyToClipboard] = useCopyToClipboard();
    const handleCopy = useCallback(() => {
        try {
            copyToClipboard(errorText);
        } catch (e: any) {
            console.log(e);
        }
    }, [copyToClipboard, errorText]);

    return (
        <div className="error-page">
            <div className="error-page__icon">
                <MdReport size="5rem" />
            </div>
            <span className="error-page__title">Что-то пошло не так</span>
            <span className="error-page__subtitle">
                В работе приложения произошла непредвиденная ошибка. Попробуйте обновить страницу.
            </span>
            <div className="error-page__buttons">
                <Button
                    type="button"
                    className="error-page__back-button"
                    onClick={() => {
                        resetErrorBoundary();
                    }}
                    startAdornment={<MdHome size="24" />}
                >
                    На главную страницу
                </Button>
                <Button
                    type="button"
                    className="error-page__refresh-button"
                    onClick={() => {
                        window.location.reload();
                    }}
                    startAdornment={<MdRefresh size="24" />}
                >
                    Обновить страницу
                </Button>
            </div>
            <div className="error-page__description-wrapper">
                <Accordion
                    data={[
                        {
                            title: 'Подробности ошибки',
                            defaultOpened: true,
                            content: (
                                <div className="error-page__description-content">
                                    <p className="error-page__error-text">{errorText}</p>
                                    {navigator.clipboard !== undefined ? (
                                        <div className="error-page__copy-text-btn">
                                            <Tooltip openDelay={100} background="black" position="bottom">
                                                <Button
                                                    buttonType="secondary"
                                                    size="s"
                                                    startAdornment={<MdContentCopy size="16" />}
                                                    onClick={handleCopy}
                                                />
                                                Копировать
                                            </Tooltip>
                                        </div>
                                    ) : null}
                                </div>
                            ),
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export default ErrorPage;
