import React, { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { deepEqual, getValueByPath } from '@utils/helpers';
import { AttachFilesBlock, IAttachFilesBlockRefActions } from '@atoms/AttachFilesBlock/AttachFilesBlock';
import { IAttachesCreateModel } from '@models/attaches/IAttachesCreateModel';
import { ISelectedFiles } from '@atoms/AttachFilesBlock/ISelectedFiles';

export type IAttachFilesBlockControlProps<TFieldValues extends object = object> = {
    id?: string;
    attachModel: IAttachesCreateModel;
    formMethods: UseFormReturn<TFieldValues>;
    name: string;
};

const AttachFilesBlockControl = <TFieldValues extends object = object>({
    id = undefined,
    name,
    attachModel,
    formMethods,
}: IAttachFilesBlockControlProps<TFieldValues>) => {
    const ctl = React.useRef<IAttachFilesBlockRefActions>(null);
    const prevValue = React.useRef<any>(undefined);

    useEffect(() => {
        const unsubscribe = formMethods.watch((value: any) => {
            let data = getValueByPath(value, name);
            if (data) {
                if (!deepEqual(data, prevValue.current)) {
                    let copy = { ...data };
                    prevValue.current = copy;
                    ctl.current?.setData(copy);
                }
            }
        });
        return () => unsubscribe.unsubscribe();
    }, [formMethods.watch]);

    const checkValid = (e: any) => {
        try {
            return ctl.current?.isValid();
        } catch (error) {
            console.log(error);
            return true;
        }
    };

    useEffect(() => {
        formMethods.register(name as any, { validate: { checkValid } });
    }, [formMethods.register]);

    return (
        <AttachFilesBlock
            id={id}
            ref={ctl}
            key={name}
            model={attachModel}
            onChanged={(data: ISelectedFiles) => {
                let copy = { ...data };
                prevValue.current = copy;
                formMethods.setValue(name as any, copy as any, { shouldDirty: true });
            }}
        />
    );
};

export default AttachFilesBlockControl;
