import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import axios from 'axios';

interface ThemeContextType {
    isThemeLoaded: boolean;
}

const ThemeContext = createContext<ThemeContextType>({ isThemeLoaded: false });

export const ThemeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isThemeLoaded, setIsThemeLoaded] = useState(false);

    useEffect(() => {
        const loadTheme = async () => {
            try {
                const basePath = import.meta.env.VITE_BASE_PATH as string;
                const response = await axios.get(basePath === '/' ? `colors.json` : `${basePath}/colors.json`);
                const root = document.documentElement;

                Object.entries(response.data).forEach(([key, value]) => {
                    root?.style.setProperty(key, value as string);
                });

                setIsThemeLoaded(true);
            } catch (error) {
                console.log('Using default theme');
                setIsThemeLoaded(true); // Still mark as loaded even if there's an error
            }
        };

        loadTheme();
    }, []);

    return <ThemeContext.Provider value={{ isThemeLoaded }}>{children}</ThemeContext.Provider>;
};

export const useTheme = () => useContext(ThemeContext);
