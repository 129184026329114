import React, { FC, useEffect, useState } from 'react';
import { IDocumentData } from '@models/document/IDocumentData';
import { IDocumentScheme, TableTab } from '@models/document/IDocumentScheme';
import { IFieldElem } from '@models/IFormData';
import { UseFormReturn } from 'react-hook-form';
import DocumentTabItem from './tabs/DocumentTabItem/DocumentTabItem';
import './DocumentTabBuilder.scss';
import { ITab } from '@/types';
import { IDocumentTab } from './tabs/DocumentTabLabel/DocumentTabLabel';
import DocumentTabLinks from './tabs/DocumentTabLinks/DocumentTabLinks';
import DocumentTabAttach from './tabs/DocumentTabAttach/DocumentTabAttach';
import DocumentTabRoutes from './tabs/DocumentTabRoutes/DocumentTabRoutes';
import DocumentStatusBarTab from './tabs/DocumentStatusBarTab/DocumentStatusBarTab';
import DocumentTabRoutesMap from './tabs/DocumentTabRoutesMap/DocumentTabRoutesMap';
import Tabs from '@atoms/Tabs';
import DocumentTabLoadEisLog from './tabs/DocumentTabLoadEisLog/DocumentTabLoadEisLog';
import { FormulaManager } from '@utils/managers/FormulaManager';
import { IDocumentStatusBar } from '@models/document/IDocumentStatusBar';
import { IDocumentRouteMap } from '@models/document/IDocumentRouteMap';
import DocumentTabCopyAttach from './tabs/DocumentTabAttach/DocumentTabCopyAttach';

export interface IDocumentTabBuilderProps {
    scheme: IDocumentScheme;
    statusBar?: IDocumentStatusBar;
    routeMap?: IDocumentRouteMap;
    isNew: boolean;
    isEdit: boolean;
    methods: UseFormReturn<IDocumentData>;
    setError: (errors?: string[]) => void;
    fields: Record<string, IFieldElem>;
    docId: string;
    useLinks?: boolean;
    hideRoutes?: boolean;
    hideStatusBar?: boolean;
    hideRoutesMap?: boolean;
    hideEisLog?: boolean;
    baseDocId: string;
}

const DocumentTabBuilder: FC<IDocumentTabBuilderProps> = ({
    scheme,
    statusBar,
    routeMap,
    isNew,
    isEdit,
    methods,
    setError,
    fields,
    docId,
    useLinks = true,
    hideRoutes = false,
    hideStatusBar = false,
    hideRoutesMap = false,
    hideEisLog = false,
    baseDocId,
}: IDocumentTabBuilderProps) => {
    const [tabs, setTabs] = useState<ITab[]>();
    const activated = React.useRef(false);

    useEffect(() => {
        activated.current = true;
        return () => {
            activated.current = false;
        };
    }, []);

    const createLinkTab = (element: TableTab, _tabs: ITab[]) => {
        const includedFields: number[] = [];
        if (!element.key) return;
        let tab = {
            label: element.name,
            tab: (
                <DocumentTabLinks
                    key={element.key}
                    tables={[element]}
                    docId={docId}
                    fields={fields!}
                    formMethods={methods}
                    isNew={isNew}
                    isEdit={false}
                />
            ),
            url: useLinks ? `#links-${element.key}` : undefined,
            exact: true,
            includedFields: includedFields,
            formMethods: methods,
            fields: fields!,
            visibilityRules: element.visibilityRules,
        } as IDocumentTab;
        _tabs.push(tab);
    };

    const createAttachTab = (element: TableTab, _tabs: ITab[]) => {
        const includedFields: number[] = [];
        if (!element.key) return;
        let tab = {
            label: element.name,
            tab: (
                <DocumentTabAttach
                    key={element.key}
                    tables={[element]}
                    docId={docId}
                    fields={fields!}
                    formMethods={methods}
                    isNew={isNew}
                    isEdit={false}
                />
            ),
            url: useLinks ? `#attachments-${element.key}` : undefined,
            exact: true,
            includedFields: includedFields,
            formMethods: methods,
            fields: fields!,
            visibilityRules: element.visibilityRules,
        } as IDocumentTab;
        _tabs.push(tab);
    };

    const initTabs = async () => {
        let _tabs: ITab[] = [];
        if (scheme && scheme.form && scheme.form.view) {
            scheme.form.view.rows.row.forEach((element) => {
                const includedFields: number[] = [];
                let tab = {
                    label: element.columns[0].blocks[0].name,
                    tab: (
                        <DocumentTabItem
                            formMethods={methods}
                            docId={docId}
                            setError={setError}
                            fields={fields!}
                            key={element.columns[0].blocks[0].id}
                            tab={element.columns[0].blocks[0]}
                            includedFields={includedFields}
                            scheme={scheme}
                            isEdit={isEdit}
                            isNew={isNew}
                        />
                    ),
                    url: useLinks ? `#page-${element.columns[0].blocks[0].id}` : undefined,
                    exact: true,
                    includedFields: includedFields,
                    formMethods: methods,
                    fields: fields!,
                    visibilityRules: element.columns[0].blocks[0].visibilityRules,
                } as IDocumentTab;
                _tabs.push(tab);
            });
            if (!isNew) {
                if (!scheme.attach.singlePage) {
                    scheme.attach.tables.forEach((element) => {
                        createAttachTab(element, _tabs);
                    });
                } else {
                    // обрабатываем таблицы на отдельной странице
                    scheme.attach.tables
                        .filter((el) => el.separatePage)
                        .forEach((element) => {
                            createAttachTab(element, _tabs);
                        });

                    const includedFields: number[] = [];
                    let tab = {
                        label: 'Документация',
                        tab: (
                            <DocumentTabAttach
                                tables={scheme.attach.tables.filter((el) => !el.separatePage)}
                                docId={docId}
                                fields={fields!}
                                formMethods={methods}
                                isNew={isNew}
                                isEdit={false}
                            />
                        ),
                        url: useLinks ? `#attachments` : undefined,
                        exact: true,
                        includedFields: includedFields,
                        formMethods: methods,
                        fields: fields!,
                        visibilityRules: scheme.attach.visibilityRules,
                    } as IDocumentTab;
                    _tabs.push(tab);
                }

                if (!scheme.links.singlePage) {
                    scheme.links.tables.forEach((element) => {
                        createLinkTab(element, _tabs);
                    });
                } else {
                    // обрабатываем таблицы на отдельной странице
                    scheme.links.tables
                        .filter((el) => el.separatePage)
                        .forEach((element) => {
                            createLinkTab(element, _tabs);
                        });

                    const includedFields: number[] = [];
                    let tab = {
                        label: 'Связанные документы',
                        tab: (
                            <DocumentTabLinks
                                tables={scheme.links.tables.filter((el) => !el.separatePage)}
                                docId={docId}
                                fields={fields!}
                                formMethods={methods}
                                isNew={isNew}
                                isEdit={false}
                            />
                        ),
                        url: useLinks ? `#links` : undefined,
                        exact: true,
                        includedFields: includedFields,
                        formMethods: methods,
                        fields: fields!,
                        visibilityRules: scheme.links.visibilityRules,
                    } as IDocumentTab;
                    _tabs.push(tab);
                }

                if (!hideRoutes && scheme.visibleRoutes) {
                    _tabs.push({
                        label: 'Маршруты',
                        tab: <DocumentTabRoutes docId={docId} />,
                        url: useLinks ? '#routes' : undefined,
                        exact: true,
                    } as ITab);
                }
                if (!hideStatusBar && scheme.visibleStatusBar) {
                    _tabs.push({
                        label: scheme.statusBarTabName,
                        tab: <DocumentStatusBarTab docId={docId} statusBar={statusBar} />,
                        url: useLinks ? '#status' : undefined,
                        exact: true,
                    } as ITab);
                }
                if (!hideRoutesMap && scheme.visibleRoutesMap) {
                    _tabs.push({
                        label: 'Карта маршрута',
                        tab: <DocumentTabRoutesMap docId={docId} routeMap={routeMap} fields={fields} />,
                        url: useLinks ? '#routesmap' : undefined,
                        exact: true,
                    } as ITab);
                }
                if (!hideEisLog && scheme.visibleLoadEisLog) {
                    _tabs.push({
                        label: 'История отправки ЕИС',
                        tab: <DocumentTabLoadEisLog docId={docId} />,
                        url: useLinks ? '#eishistory' : undefined,
                        exact: true,
                    } as ITab);
                }
            } else {
                if (scheme.baseActionsSettings.attachesCopyAndEdit.visible && baseDocId) {
                    _tabs.push({
                        label: 'Переносимая документация',
                        tab: <DocumentTabCopyAttach baseDocId={baseDocId} />,
                        url: `#copyattachments`,
                        exact: false,
                    } as ITab);
                }
            }

            // Выбираем начальное значение
            if (_tabs.length > 0) {
                for (let index = 0; index < _tabs.length; index++) {
                    const tab = _tabs[index] as any;
                    let visibilityRules = tab.visibilityRules;
                    if (visibilityRules === undefined || visibilityRules === '' || visibilityRules === null) {
                        _tabs[index].active = true;
                        break;
                    } else {
                        const visibilityMng = new FormulaManager(visibilityRules!);
                        visibilityMng.Init(tab.fields);
                        let result = await visibilityMng.EvalFormulaValues(isEdit, isNew);
                        if (result) {
                            _tabs[index].active = true;
                            break;
                        }
                    }
                }
            }
            if (activated.current) {
                setTabs(_tabs);
            }
        }
    };

    useEffect(() => {
        initTabs();
    }, [scheme, fields, docId, isNew, isEdit, routeMap, statusBar]);

    return tabs ? <Tabs size="sm" list={tabs!} /> : <></>;
};

export default DocumentTabBuilder;
