import React, { FC, ReactNode } from 'react';
import { TITLE_PREFIX } from '@/configuration';

export interface ILayoutEmptyProps {
    /** Наполнение */
    children: ReactNode | ReactNode[];
}

const LayoutEmpty: FC<ILayoutEmptyProps> = ({ children }: ILayoutEmptyProps) => {
    return (
        <>
            <title>{`${TITLE_PREFIX} — Авторизация`}</title>
            <div className="content-center">{children}</div>
        </>
    );
};

export default LayoutEmpty;
