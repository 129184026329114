import { ActFinishType } from '@/types/ActFinishType';
import { BaseApproveExecutor } from '../BaseApprove/BaseApproveExecutor';

export class RejectExecutor extends BaseApproveExecutor {
    constructor() {
        super();

        this.approveModel = {
            commentIsRequired: true,
            actType: ActFinishType.Refuse,
        };
    }
}
