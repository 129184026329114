import React, { useEffect, useState } from 'react';

import './TableData.scss';
import { IDocumentTable } from '@models/Forms/IForms';
import { MdEdit } from 'react-icons/md';
import TableForm from './ModalForms/TableForm';
import { IField } from '@models/IFormData';
import Tooltip from '../Tooltip';
import {
    isTableColumn,
    isTableColumnAbook,
    isTableColumnAutoComplete,
    isTableColumnCalc,
    isTableColumnDict,
} from '@utils/tableHelper';

export interface EditRowMultiButtonProps {
    column: any;
    onSubmit: (data: IField[]) => Promise<void>;
    canEditRowMulti: (column: any) => boolean;
    getParentFields: () => IField[];
    docId?: string;
    table: IDocumentTable;
}

const EditRowMultiButton: React.FC<EditRowMultiButtonProps> = ({
    column,
    onSubmit,
    canEditRowMulti,
    getParentFields,
    docId,
    table,
}: EditRowMultiButtonProps) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const activated = React.useRef(false);

    let tableForm = { ...table };
    tableForm.tableColumn = isTableColumn(column)
        ? [{ ...column, changes: { change: [] }, visibilityRules: '', readonly: '', required: '' }]
        : [];
    tableForm.tableColumnAbook = isTableColumnAbook(column)
        ? [{ ...column, changes: { change: [] }, visibilityRules: '', readonly: '', required: '' }]
        : [];
    tableForm.tableColumnAutoComplete = isTableColumnAutoComplete(column)
        ? [{ ...column, changes: { change: [] }, visibilityRules: '', readonly: '', required: '' }]
        : [];
    tableForm.tableColumnDict = isTableColumnDict(column)
        ? [{ ...column, changes: { change: [] }, visibilityRules: '', readonly: '', required: '' }]
        : [];
    tableForm.tableColumnCalc = isTableColumnCalc(column)
        ? [{ ...column, changes: { change: [] }, visibilityRules: '', readonly: '', required: '' }]
        : [];
    tableForm.customForm = '';

    const onClick = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (canEditRowMulti(column)) {
            setShowModal(!showModal);
        }
    };

    const onSubmitHandler = async (data: IField[]) => {
        await onSubmit(data);
        if (activated.current) {
            setShowModal(!showModal);
        }
    };

    useEffect(() => {
        activated.current = true;
        return () => {
            activated.current = false;
        };
    }, []);

    return (
        <>
            <Tooltip openDelay={100} background="black" position="bottom">
                <MdEdit size="12" onClick={onClick} />
                {'Редактировать значение колонки в выбранных строках.'}
            </Tooltip>

            {showModal && (
                <TableForm
                    table={tableForm}
                    docId={docId}
                    title={`Значение для колонки`}
                    isEdit={true}
                    onClose={() => {
                        setShowModal(!showModal);
                    }}
                    onSubmit={onSubmitHandler}
                    submitButtonCaption="Применить"
                    getParentFields={getParentFields}
                    rowIndex={0}
                    rowArray={[{ '|NUM': '-1' }]}
                    disablePagination={true}
                />
            )}
        </>
    );
};

export default EditRowMultiButton;
