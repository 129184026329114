import './MailReminderExecutor.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IMailReminderInfo } from '@models/document/actionsExecution/IMailReminderModel';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import { Column, DataGrid, Editing } from 'devextreme-react/data-grid';
import { useForm } from 'react-hook-form';
import { RemindersService } from '@services/RemindersService';
import ModalContainer, { IModalContainerProps } from '@molecules/ModalContainer/ModalContainer';
import { DateTimepickerControl, TextareaControl } from '@controls/index';
import ColoredLine from '@atoms/ColoredLine';
import DevExpressDataGrid from '@atoms/DevExpress/DataGrid/DevExpressDataGrid';
import { onCellHoverChanged } from '@utils/dataGridUtils';
import { IMailReminderExecutorProps } from './IMailReminderExecutorProps';
import { IMailReminderFormData } from './IMailReminderFormData';

export const MailReminderExecutorModal: React.FC<IMailReminderExecutorProps> = (props: IMailReminderExecutorProps) => {
    const [errorText, setErrorText] = useState<string>();
    const [loading, setLoading] = useState<boolean>();

    const [message, setMessage] = useState<string>(props.prefilledText);
    const [deliveryDateTime, setDeliveryDateTime] = useState<Date>();
    const [existingReminders, setExistingReminders] = useState<IMailReminderInfo[]>([]);
    const service = new DocumentExecutionService(props.docId);
    const gridRef = useRef<DataGrid>(null);
    const formState = useForm<IMailReminderFormData>({
        mode: 'onBlur', // "onChange"
    });
    const [minDateVal, setMinDate] = useState<Date | undefined>(undefined);

    useEffect(() => {
        setLoading(true);
        service
            .getMailReminderModel(props.actionKey)
            .then((res) => {
                setExistingReminders(res.data.existingReminders);
            })
            .catch((error) => setErrorText(error))
            .finally(() => {
                setLoading(false);
            });

        let currDT = new Date();
        setMinDate(currDT);
        setDeliveryDateTime(currDT);
    }, []);

    const onSubmit = (form: IMailReminderFormData) => {
        if (!deliveryDateTime) {
            setErrorText('Не указано время доставки напоминания');
            return;
        }

        setLoading(true);

        return service
            .createMailReminder(props.actionKey, deliveryDateTime, message)
            .then((res) => {
                setLoading(false);

                props.completeHandler && props.completeHandler(true);
            })
            .catch((err) => {
                setErrorText(err);
            })
            .finally(() => setLoading(false));
    };

    const onRowRemoved = useCallback(
        (e: any) => {
            RemindersService.removeReminder(e.data.id as number)
                .then(() => console.log(existingReminders))
                .catch((err) => {
                    setErrorText(err);
                });
        },
        [existingReminders],
    );

    const modalContainerProps: IModalContainerProps = {
        header: props.title,
        size: props.modalSize,
        showOkButton: true,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        isBusy: loading,
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
        errorText: errorText,
        formMethods: formState,
        onSubmit: onSubmit,
        okButtonDisabled: false,
    };

    return (
        <ModalContainer {...modalContainerProps}>
            <TextareaControl
                label="Текст напоминания"
                readOnly={false}
                required={false}
                name="message"
                autoResize={true}
                defaultValue={props.prefilledText}
                formState={formState.formState}
                control={formState.control}
                onValueChange={(val: any) => {
                    setMessage(val);
                }}
            />
            <div className={'MailReminder__DeliveryDateTimeBlock'}>
                <DateTimepickerControl
                    label="Время доставки напоминания"
                    readOnly={false}
                    required={true}
                    name="deliveryDateTime"
                    formState={formState.formState}
                    control={formState.control}
                    minDate={minDateVal}
                    defaultValue={minDateVal}
                    onChangeVal={(val: any) => {
                        setDeliveryDateTime(val);
                    }}
                />
            </div>
            <div className={'MailReminder__DelimiterBlock'}>
                <ColoredLine />
            </div>
            <div className={'MailReminder__ExistingRemindersBlock'}>
                {existingReminders && existingReminders.length > 0 ? (
                    <DevExpressDataGrid
                        dataSource={existingReminders}
                        remoteOperations={true}
                        hoverStateEnabled={true}
                        columnHidingEnabled={false}
                        showColumnHeaders={true}
                        columnAutoWidth={true}
                        allowColumnReordering={false}
                        allowColumnResizing={true}
                        columnResizingMode="widget"
                        noDataText={'Нет назначенных напоминаний'}
                        rowAlternationEnabled={true}
                        onRowRemoved={onRowRemoved}
                        ref={gridRef}
                        onCellHoverChanged={onCellHoverChanged}
                    >
                        <Editing
                            mode="cell"
                            allowUpdating={false}
                            allowAdding={false}
                            allowDeleting={true}
                            confirmDelete={false}
                        />
                        <Column
                            key={`col_creationDateTime`}
                            caption={'Время создания'}
                            dataField={'creationDateTime'}
                            dataType={'datetime'}
                            allowSorting={false}
                            allowHeaderFiltering={false}
                            width={'20%'}
                        />
                        <Column
                            key={`col_deliveryDateTime`}
                            caption={'Время доставки'}
                            dataField={'deliveryDateTime'}
                            dataType={'datetime'}
                            allowSorting={false}
                            allowHeaderFiltering={false}
                            width={'20%'}
                        />
                        <Column
                            key={`col_message`}
                            caption={'Сообщение'}
                            dataField={'message'}
                            dataType={'string'}
                            allowSorting={false}
                            allowHeaderFiltering={false}
                            width={'60%'}
                        />
                    </DevExpressDataGrid>
                ) : (
                    <div className={'MailReminder__NoRemindersBlock'}>Нет назначенных напоминаний</div>
                )}
            </div>
        </ModalContainer>
    );
};
