import { IBaseAction } from '@models/actions/IBaseAction';
import { IActionExecutor } from './IActionExecutor';
import { RouteMapActionTypes } from '@models/actions/RouteMapActionTypes';
import { AddRouteMapExecutor } from './routeMapExecutors/AddRouteMap/AddRouteMapExecutor';
import { EditRouteMapExecutor } from './routeMapExecutors/EditRouteMap/EditRouteMapExecutor';
import { RemoveRouteMapExecutor } from './routeMapExecutors/RemoveRouteMap/RemoveRouteMapExecutor';

export class RouteMapActionsExecFactory {
    public static allExecutors: { [actType: string]: () => IActionExecutor } = {
        [RouteMapActionTypes[RouteMapActionTypes.Add]]: () => new AddRouteMapExecutor(),
        [RouteMapActionTypes[RouteMapActionTypes.Edit]]: () => new EditRouteMapExecutor(),
        [RouteMapActionTypes[RouteMapActionTypes.Remove]]: () => new RemoveRouteMapExecutor(),
    };

    static GetActionExecutor(act: IBaseAction, throwError: boolean = true): IActionExecutor | undefined {
        let createExecutor = this.allExecutors[act.type];
        if (!createExecutor) {
            if (!throwError) return undefined;

            throw new Error(`Не обнаружен ActionExecutor для типа ${act.type}`);
        }

        return createExecutor();
    }
}
