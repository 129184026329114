import React, { ReactNode, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import './Dictpicker.scss';
import { MdAdd, MdMenu, MdOutlineExpandLess, MdOutlineExpandMore } from 'react-icons/md';
import Input from '../Input';
import clsx from 'clsx';
import DictpickerModal from './DictpickerModal/DictpickerModal';
import { IDictionaryData } from '@models/dictionary/IDictionaryData';
import {
    IAddDictForm,
    IDictFilter,
    IDictSource,
    IExternalSearchDict,
    IGridModalAttributes,
    IGridUserScripts,
    IJoinedDictionary,
    ISetValues,
} from '@models/Forms/IForms';
import DictpickerSelect from './DictpickerSelect/DictpickerSelect';
import DictChipItem from '@molecules/formbuilder/controls/Dict/DictChipItem/DictChipItem';
import Button from '@atoms/Button';
import { autoUpdate, flip, offset, shift, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import { IDictpickerRefActions } from './IDictpickerRefActions';

export interface IDictpickerProps<T extends HTMLElement = HTMLDivElement> {
    /** Имя поля */
    name?: string;
    /** Имя справочника */
    dictionaryName: string;
    /** Псевдо-справочник */
    isFormData: boolean;
    isMultiple: boolean;
    isSelectMode: boolean;
    getExternalDataSource: () => any[];
    getFormValuesAsync: () => Promise<string>;
    getFiltersAsync: () => Promise<IDictFilter>;
    onValidate?: (data: IDictionaryData[]) => Promise<boolean>;
    /** зависимые поля*/
    setValues?: ISetValues;
    /** колонки для грида */
    gridAttribute?: IGridModalAttributes;
    /** Уровень загрузки справочника */
    startLevel?: number;
    /** Текст Placeholder */
    placeholder?: string;
    /** Значение по умолчанию */
    defaultValue?: any;
    /** отображаемое значение */
    displayValue: string;
    /** Чистое значение поля */
    rawVal?: any;
    /** Скрыть поле */
    disabled?: boolean;
    /** Только для просмотра */
    readOnly?: boolean;
    /** Функция изменения значения */
    onChange?: (value: IDictionaryData[]) => Promise<void>;
    /** Функция отмены */
    onCancel?: () => void;
    /** Кастомная кнопка */
    children?: ReactNode | ReactNode[];
    /** Переводит инпут в невалидный статус */
    invalid?: boolean;
    /**
     * Добавляет инпуту белый фон
     * @default true
     */
    filled?: boolean;
    /** Ссылка на контейнер портала */
    containerRef?: React.Ref<T>;
    /** Реф для пробрасывания действий по кастомным кнопкам */
    controlRef?: React.Ref<IDictpickerRefActions>;
    /**
     *  Добавлять фокус при выборе дат
     * @default false
     *  */
    isFocusBorder?: boolean;
    insideGrid: boolean;
    autoFocus?: boolean;
    /** Доступные для выбора уровни */
    selectableLevels?: string;
    /** Отображаемые уровни */
    visibleLevels?: string;
    /** Ключ кэша предикатов */
    predicatesCache?: string;
    externalSearch?: IExternalSearchDict;
    addForm?: IAddDictForm;
    /**
     * Режим загрузки справочника
     * */
    loadMode?: string;

    docId?: string;

    modalTitle?: string;

    joinedDictionaries?: IJoinedDictionary[];

    /**
     * Дополнительная настройка источника данных (выбор данных из реквизитов-таблиц и т.д.)
     */
    source?: IDictSource;
    /**
     * Флаг обработки операций фильтрации, поиска и т.п. на стороне клиента
     */
    useClientSideDataProcessing?: boolean;

    /** Отображение в виде чипсов */
    chipsView?: boolean;

    /*
     * Пользовательские скрипты
     */
    userScripts?: IGridUserScripts;

    /**
     * Максимальное количество отображаемых чипсов
     * @default 20
     */
    maxVisibleChips?: number;
}

// FIXME: Добавить управление с клавиатуры
const Dictpicker: React.FC<IDictpickerProps> = ({
    name = 'dictpicker',
    placeholder = 'Выберите значение',
    defaultValue,
    displayValue,
    rawVal,
    gridAttribute,
    dictionaryName,
    invalid = false,
    filled = true,
    disabled = false,
    readOnly = false,
    onChange,
    onCancel,
    onValidate,
    children,
    isFormData,
    isMultiple,
    getFormValuesAsync,
    getFiltersAsync,
    containerRef,
    controlRef,
    getExternalDataSource,
    isFocusBorder = false,
    insideGrid = false,
    autoFocus,
    selectableLevels = '',
    visibleLevels = '',
    predicatesCache = '',
    loadMode = '',
    useClientSideDataProcessing = false,
    isSelectMode,
    docId,
    externalSearch,
    addForm,
    modalTitle,
    joinedDictionaries,
    source,
    chipsView = false,
    userScripts,
    maxVisibleChips = 20,
}: IDictpickerProps) => {
    useImperativeHandle(controlRef, () => ({
        reset: () => {},
        setSelected: (items) => {},
    }));

    // -------------------------------------------------------------------------------------------------------------------

    const dictpickerRef = useRef<HTMLDivElement>(null);

    const [showDictionary, toggleDictionary] = useState<boolean>(false);
    const [showAllChips, setShowAllChips] = useState<boolean>(false);

    const onCloseModal = useCallback(() => {
        toggleDictionary(false);
        onCancel && onCancel();
    }, [onCancel]);

    // Floating UI setup
    const { refs, floatingStyles, context } = useFloating({
        open: showDictionary,
        onOpenChange: toggleDictionary,
        whileElementsMounted: autoUpdate,
        placement: 'bottom',
        middleware: [offset({ mainAxis: 8, crossAxis: 0 }), flip(), shift()],
    });

    const dismiss = useDismiss(context, { enabled: isSelectMode });

    const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

    // -------------------------------------------------------------------------------------------------------------------

    const [inputValue, setInputValue] = useState<string[]>();

    const values = useMemo(() => {
        return typeof rawVal === 'string' && rawVal
            ? rawVal.split('|').sort((a, b) => {
                  const textA = a.toUpperCase();
                  const textB = b.toUpperCase();
                  return textA < textB ? -1 : textA > textB ? 1 : 0;
              })
            : [];
    }, [rawVal]);

    useEffect(() => {
        if (!defaultValue) {
            setInputValue(undefined);
        } else {
            if (source?.table) {
                let keys = (defaultValue as any[])?.map((row: any) => row[source.key]);
                if (keys) {
                    setInputValue(keys);
                }
            } else {
                if (defaultValue.toString().indexOf('|') > 0) {
                    setInputValue(defaultValue.toString().split('|'));
                } else {
                    let arr = [] as string[];
                    arr.push(defaultValue);
                    setInputValue(arr);
                }
            }
        }
    }, [defaultValue]);

    const toggleDict = (e?: any) => {
        toggleDictionary((prev) => !prev);
    };

    // Determine which items to display based on showAllChips state
    const displayedValues = useMemo(() => {
        if (showAllChips || !values || values.length <= maxVisibleChips) {
            return values;
        }
        return values.slice(0, maxVisibleChips);
    }, [values, showAllChips, maxVisibleChips]);

    // Determine if we need to show the "Show More" button
    const hasMoreChips = useMemo(() => {
        return values && values.length > maxVisibleChips;
    }, [values, maxVisibleChips]);

    const toggleShowAllChips = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        setShowAllChips((prev) => !prev);
    }, []);

    return (
        <div
            className={clsx(
                !insideGrid && 'rf-dictpicker',
                insideGrid && 'rf-dictpicker-ingrid',
                isFocusBorder && 'rf-dictpicker__focus-border',
                disabled && 'rf-dictpicker--disabled',
            )}
            ref={dictpickerRef}
        >
            <div
                ref={refs.setReference}
                className={clsx('rf-dictpicker__input-wrapper', {
                    'rf-dictpicker__input-wrapper--disabled': disabled,
                    'rf-dictpicker__input-wrapper--readonly': readOnly,
                })}
                onFocus={autoFocus && !readOnly && !chipsView ? (e: any) => toggleDict(e) : () => {}}
                onClick={!readOnly && !chipsView ? (e: any) => toggleDict(e) : () => {}}
                {...getReferenceProps()}
            >
                {children ||
                    (chipsView ? (
                        <div className="dict-chips-container">
                            <div className="dict-chips-add">
                                <Button
                                    size="s"
                                    buttonType={'round'}
                                    textColor="neutral"
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        toggleDict();
                                    }}
                                    className={readOnly ? 'dict-chips-add--disabled' : undefined}
                                    disabled={readOnly}
                                    startAdornment={<MdAdd size="24" />}
                                    aria-label="Добавить"
                                />
                            </div>
                            <div className="dict-chips-items">
                                {displayedValues &&
                                    displayedValues.length > 0 &&
                                    displayedValues.map((item: string, index: number) => (
                                        <DictChipItem
                                            key={index}
                                            item={item}
                                            showRemoveIcon={!readOnly}
                                            onClick={
                                                !readOnly
                                                    ? () => {
                                                          toggleDict();
                                                      }
                                                    : undefined
                                            }
                                            onRemove={async () => {
                                                onChange &&
                                                    (await onChange(
                                                        values
                                                            .filter((x) => x != item)
                                                            .map((val) => {
                                                                return { code: val } as IDictionaryData;
                                                            }),
                                                    ));
                                            }}
                                        />
                                    ))}
                                {values === undefined || values.length === 0 ? (
                                    <div className="dict-chips-placeholder">{placeholder}</div>
                                ) : hasMoreChips ? (
                                    <Button
                                        size="s"
                                        buttonType="outline"
                                        onClick={toggleShowAllChips}
                                        className="dict-chips-show-more"
                                        startAdornment={
                                            showAllChips ? (
                                                <MdOutlineExpandLess size="20" />
                                            ) : (
                                                <MdOutlineExpandMore size="20" />
                                            )
                                        }
                                    >
                                        {showAllChips ? 'Скрыть' : `Ещё ${values.length - maxVisibleChips}`}
                                    </Button>
                                ) : null}
                            </div>
                        </div>
                    ) : (
                        <Input
                            name={name}
                            value={displayValue}
                            placeholder={placeholder}
                            disabled={disabled}
                            readOnly={true}
                            withoutClasses={!readOnly}
                            invalid={invalid}
                            filled={filled}
                            autoFocus={autoFocus}
                            endAdornment={
                                !readOnly && !disabled ? (
                                    <div className={'rf-dictpicker__calendar-chevron'}>
                                        {isSelectMode ? <MdOutlineExpandMore size="20" /> : <MdMenu />}
                                    </div>
                                ) : (
                                    <></>
                                )
                            }
                        />
                    ))}
            </div>
            {showDictionary &&
                (isSelectMode ? (
                    <DictpickerSelect
                        widthDropdown={dictpickerRef.current?.getBoundingClientRect().width}
                        selected={inputValue!}
                        docId={docId}
                        dictName={dictionaryName}
                        isFormData={isFormData}
                        getExternalDataSource={getExternalDataSource}
                        isMultiple={isMultiple}
                        selectableLevels={selectableLevels}
                        visibleLevels={visibleLevels}
                        predicatesCache={predicatesCache}
                        loadMode={loadMode}
                        useClientSideDataProcessing={useClientSideDataProcessing}
                        getFormValuesAsync={getFormValuesAsync}
                        getFiltersAsync={getFiltersAsync}
                        gridAttribute={gridAttribute}
                        onSubmitModal={onChange!}
                        onCloseModal={onCloseModal}
                        ref={refs.setFloating}
                        floatingContext={context}
                        getFloatingProps={getFloatingProps}
                        floatingStyles={floatingStyles}
                    />
                ) : (
                    <DictpickerModal
                        selected={inputValue!}
                        docId={docId}
                        dictName={dictionaryName}
                        modalTitle={modalTitle}
                        isFormData={isFormData}
                        getExternalDataSource={getExternalDataSource}
                        isMultiple={isMultiple}
                        selectableLevels={selectableLevels}
                        visibleLevels={visibleLevels}
                        predicatesCache={predicatesCache}
                        externalSearch={externalSearch}
                        addForm={addForm}
                        loadMode={loadMode}
                        useClientSideDataProcessing={useClientSideDataProcessing}
                        getFormValuesAsync={getFormValuesAsync}
                        getFiltersAsync={getFiltersAsync}
                        gridAttribute={gridAttribute}
                        onSubmitModal={onChange!}
                        onCloseModal={onCloseModal}
                        joinedDictionaries={joinedDictionaries}
                        onValidate={onValidate}
                        userScripts={userScripts}
                    />
                ))}
        </div>
    );
};

export default Dictpicker;
