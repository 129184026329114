import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { IWIICCheckFreeTimeResultDto } from '@models/wiicFreeDate/IWIICCheckFreeTimeResultDto';
import { IWIICReservTimeResultDto } from '@models/wiicFreeDate/IWIICReservTimeResultDto';

export class WIICFreeDateService {
    static async checkFreeTime(docId: string, checkDate: Date): Promise<AxiosResponse<IWIICCheckFreeTimeResultDto>> {
        return $api.get<IWIICCheckFreeTimeResultDto>(`/wiicfreedate/checkfreetime/${docId}/${checkDate.toISOString()}`);
    }

    static async reservTime(docId: string, reservTime: Date): Promise<AxiosResponse<IWIICReservTimeResultDto>> {
        return $api.post<IWIICReservTimeResultDto>(`/wiicfreedate/reservtime/${docId}/${reservTime.toISOString()}`);
    }
}
