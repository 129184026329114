import './GridSettingsButton.scss';
import React, { FC, useImperativeHandle, useMemo, useRef } from 'react';
import Button from '@atoms/Button';
import Menu from '@atoms/Menu';
import { GridWordWrapMode, IGridSettings } from '@models/dataGrid/IGridSettings';
import { MdAddchart, MdMenu, MdRefresh, MdSettings } from 'react-icons/md';
import { IListElement, ISegment } from '@/types';
import Segment from '@atoms/Segment';
import { IMenuRefActions } from '@atoms/Menu/Menu';
import { useHoverDelay } from '@hooks/useHoverDelay';
import Tooltip from '@atoms/Tooltip';

export interface IGridSettingsButtonProps {
    value: IGridSettings;
    onChange: (value: IGridSettings) => void;
    onClickStateChooser: () => void;
    onClickRefresh: () => void;
    onClickColumnChooser: () => void;
    /** Ссылка на меню */
    menuSettingsRef?: React.Ref<IMenuSettingsRefActions>;
}

export interface IMenuSettingsRefActions {
    openMenu: () => void;
}

const wordWrapModeList: ISegment[] = [
    {
        value: `${GridWordWrapMode.NoWrap}`,
        label: '1 строка',
    },
    {
        value: `${GridWordWrapMode.Trim}`,
        label: '5 строк',
    },
    {
        value: `${GridWordWrapMode.Wrap}`,
        label: 'Все',
    },
];

const GridSettingsButton: FC<IGridSettingsButtonProps> = ({
    value,
    onChange,
    onClickStateChooser,
    onClickRefresh,
    onClickColumnChooser,
    menuSettingsRef,
}: IGridSettingsButtonProps) => {
    const menuRef = useRef<IMenuRefActions>(null);
    const menuList = useMemo<IListElement[]>(() => {
        return [
            {
                label: (
                    <div className="grid-settings__menu-item">
                        <MdMenu size="24" />
                        <div className="grid-settings__menu-item-label">Список состояний</div>
                    </div>
                ),
                separated: false,
                value: '1',
                handler: onClickStateChooser,
            },
            {
                label: (
                    <div className="grid-settings__menu-item">
                        <MdRefresh size="24" />
                        <div className="grid-settings__menu-item-label">Сбросить состояние</div>
                    </div>
                ),
                separated: true,
                value: '2',
                handler: onClickRefresh,
            },
            {
                label: (
                    <div className="grid-settings__menu-item">
                        <MdAddchart size="24" />
                        <div className="grid-settings__menu-item-label">Выбор столбцов</div>
                    </div>
                ),
                separated: true,
                value: '3',
                handler: onClickColumnChooser,
            },
            {
                label: (
                    <div className="grid-settings__item">
                        <div className="grid-settings__item-label">Количество строк текста в ячейке</div>
                        <Segment
                            list={wordWrapModeList}
                            fullWidth={true}
                            value={wordWrapModeList[value.wordWrapMode]}
                            onChange={(e) => {
                                onChange({
                                    ...value,
                                    wordWrapMode: +e.value,
                                });
                            }}
                        />
                    </div>
                ),
                preventClick: true,
                separated: true,
                value: '4',
            },
        ] as IListElement[];
    }, [onChange, onClickColumnChooser, onClickRefresh, onClickStateChooser, value]);

    useImperativeHandle(menuSettingsRef, () => ({
        openMenu: () => {
            menuRef.current?.open();
        },
    }));

    const { handleMouseEnter, handleMouseLeave } = useHoverDelay(() => {
        menuRef.current?.close();
    }, 1000);

    return (
        <div className="grid-settings" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <Menu menuRef={menuRef} list={menuList} position="bottom-end">
                <div className="grid-settings__menu">
                    <Tooltip openDelay={100} background="black" position="top">
                        <Button
                            buttonType="text"
                            textColor="neutral"
                            size="s"
                            startAdornment={<MdSettings size="24" />}
                        />
                        Настройки таблицы
                    </Tooltip>
                </div>
            </Menu>
        </div>
    );
};

export default GridSettingsButton;
