import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { ITableDemand } from '@models/winnerSelection/ITableDemand';
import { IOfferCritDetailsFormData } from '@molecules/formbuilder/controls/CustomComponent/components/WinnerSelection/OfferCritDetails/IOfferCritDetailsFormData';
import { IOfferRateFormData } from '@molecules/formbuilder/controls/CustomComponent/components/WinnerSelection/OfferRate/IOfferRateFormData';
import { IOfferTechAnalysisFormData } from '@molecules/formbuilder/controls/CustomComponent/components/WinnerSelection/OfferTechAnalysis/IOfferTechAnalysisFormData';
import { ITablePositions } from '@models/winnerSelection/ITablePositions';
import { IForms } from '@models/Forms/IForms';
import { ITableKO } from '@models/winnerSelection/ITableKO';
import { IOfferApproveToStageTwoFormData } from '@molecules/formbuilder/controls/CustomComponent/components/WinnerSelection/OfferApproveToStageTwo/IOfferApproveToStageTwoFormData';
import { IOfferKOCritDetailsFormData } from '@molecules/formbuilder/controls/CustomComponent/components/WinnerSelection/OfferKOCritDetails/IOfferKOCritDetailsFormData';

export class WinnerSelectionService {
    static async fetchTableDemandData(docId: string, tableKey: string): Promise<AxiosResponse<ITableDemand>> {
        return $api.get<ITableDemand>(`/winnerSelection/tableDemand/${docId}/${tableKey}`);
    }

    static async saveTableDemandOfferCrit(
        offerId: string,
        demandId: string,
        tableKey: string,
        formData: IOfferCritDetailsFormData,
    ): Promise<AxiosResponse> {
        return $api.post(`/winnerSelection/tableDemand/${offerId}/${demandId}/${tableKey}/crit`, formData);
    }

    static async saveTableDemandOfferRate(
        offerId: string,
        demandId: string,
        tableKey: string,
        formData: IOfferRateFormData,
    ): Promise<AxiosResponse> {
        return $api.post(`/winnerSelection/tableDemand/${offerId}/${demandId}/${tableKey}/rate`, formData);
    }

    static async saveTableDemandOfferTechAnalysis(
        offerId: string,
        demandId: string,
        tableKey: string,
        formData: IOfferTechAnalysisFormData,
    ): Promise<AxiosResponse> {
        return $api.post(`/winnerSelection/tableDemand/${offerId}/${demandId}/${tableKey}/techAnalysis`, formData);
    }

    static async saveTableDemandOfferApproveToStageTwo(
        offerId: string,
        demandId: string,
        tableKey: string,
        formData: IOfferApproveToStageTwoFormData,
    ): Promise<AxiosResponse> {
        return $api.post(`/winnerSelection/tableDemand/${offerId}/${demandId}/${tableKey}/approveToStageTwo`, formData);
    }

    // -------------------------------------------------------------------------------------------------------------------

    static async fetchTablePositionsData(docId: string, tableKey: string): Promise<AxiosResponse<ITablePositions>> {
        return $api.get<ITablePositions>(`/winnerSelection/tablePositions/${docId}/${tableKey}`);
    }

    // -------------------------------------------------------------------------------------------------------------------

    // Возвращает модель для формы
    static async fetchCustomFormScheme(tableKey: string, actionKey: string): Promise<AxiosResponse<IForms>> {
        return $api.get<IForms>(`/winnerSelection/customFormScheme/${tableKey}/${actionKey}`);
    }

    // -------------------------------------------------------------------------------------------------------------------

    static async fetchTableKOData(docId: string, tableKey: string): Promise<AxiosResponse<ITableKO>> {
        return $api.get<ITableKO>(`/winnerSelection/tableKO/${docId}/${tableKey}`);
    }

    static async saveTableKOOfferCrit(
        offerId: string,
        demandId: string,
        tableKey: string,
        formData: IOfferKOCritDetailsFormData,
    ): Promise<AxiosResponse> {
        return $api.post(`/winnerSelection/tableKO/${offerId}/${demandId}/${tableKey}/crit`, formData);
    }
}
