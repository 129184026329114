import { scan } from 'react-scan'; // must be imported before React and React DOM
import './styles/index.scss';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { BASE_PATH } from '@/configuration';
import App from '@/App';
import { Provider } from 'react-redux';
import { setupStore } from './store';
import { apiStore } from './http/index';
import { BrowserRouter } from 'react-router';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from '@pages/errorPage/ErrorPage';
import { ThemeProvider } from '@/context/ThemeContext';
import { ConfigService } from '@/configuration/services/configService';

const config = ConfigService.get();
if (config.environment === 'DEV') scan({ enabled: false, showToolbar: true });

const store = setupStore();
apiStore(store);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <StrictMode>
        <ErrorBoundary FallbackComponent={ErrorPage} onReset={() => window.location.replace(BASE_PATH)}>
            <ThemeProvider>
                <Provider store={store}>
                    <BrowserRouter basename={BASE_PATH}>
                        <App />
                    </BrowserRouter>
                </Provider>
            </ThemeProvider>
        </ErrorBoundary>
    </StrictMode>,
);
