import './Checkbox.scss';
import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import { MdDone, MdHorizontalRule } from 'react-icons/md';
import DOMPurify from 'dompurify';
import { useCombinedRefs } from '@hooks/useCombinedRefs';
import clsx from 'clsx';

export interface ICheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
    /**
     * Ссылка на элемент
     */
    ref?: React.Ref<HTMLLabelElement>;
    /** Лейбл */
    label?: React.ReactNode;
    /** Значение */
    value?: string;
    /**
     * Отображение иконки
     * @default true
     *  */
    icon?: boolean;
    /**
     * Вертикальное выравнивание
     * @default 'flex-start'
     *  */
    align?: 'flex-start' | 'center' | 'flex-end';
    /**
     * Если дочерние чекбоксы чекнуты, флаг равен true
     * @default false
     *  */
    halfChecked?: boolean;
    /**
     * Положение чекбокса
     *  @default 'left'
     * */
    position?: 'left' | 'right';
    /**
     * Круглый чекбокс
     * @default false
     */
    round?: boolean;
    /**
     *  100% ширины
     * @default false
     *  */
    fullWidth?: boolean;
    /**
     * Лейбл - текст
     * @default '''
     *  */
    titleAtt?: string;
    /** Переводит инпут в невалидный статус */
    invalid?: boolean;
}

const Checkbox: React.FC<ICheckboxProps> = ({
    ref,
    label,
    value,
    invalid,
    icon = true,
    align = 'flex-start',
    halfChecked = false,
    position = 'left',
    round = false,
    fullWidth = false,
    titleAtt = '',
    ...props
}) => {
    const defaultRef = useRef(null);
    const combinedRef = useCombinedRefs(ref, defaultRef);

    const roundClass = round ? 'rf-checkbox__check--round' : '';
    const fullWidthClass = fullWidth ? 'rf-checkbox__check--fullWidth' : '';

    const disabledClass = props.disabled || props.readOnly ? 'disabled' : '';
    const readonlyCheckboxClass = props.readOnly ? 'rf-checkbox-readonly' : '';
    const alignClass: Record<string, string> = {
        'flex-start': 'rf-checkbox--flex-start',
        center: 'rf-checkbox--center',
        'flex-end': 'rf-checkbox--flex-end',
    };
    const showIconClass = icon ? '' : 'rf-checkbox__label--no-icon';
    const positionClass = position === 'left' ? 'rf-checkbox--left' : 'rf-checkbox--right';

    const cleanLabelText = typeof label === 'string' ? DOMPurify.sanitize(label, { ADD_ATTR: ['target'] }) : '';

    useEffect(() => {
        if (combinedRef?.current) {
            combinedRef.current.indeterminate = (value === undefined && halfChecked) ?? false;
        }
    }, [combinedRef, halfChecked]);

    useEffect(() => {
        if (combinedRef?.current) {
            combinedRef.current.checked = !!props.defaultChecked;
        }
    }, [props.defaultChecked]);

    return (
        <label
            ref={ref}
            className={clsx(
                'rf-checkbox',
                props.className,
                disabledClass,
                readonlyCheckboxClass,
                alignClass[align],
                positionClass,
                fullWidthClass,
            )}
        >
            <input
                {...props}
                type="checkbox"
                className={clsx('rf-checkbox__input', {
                    'rf-checkbox__input--half-checked': halfChecked && (value === undefined || value === null),
                })}
                value={value === undefined || value === null ? undefined : value}
                readOnly={props.readOnly}
                ref={combinedRef}
            />

            {!!icon && (
                <span className={clsx('rf-checkbox__check', roundClass)}>
                    <span className="rf-checkbox__mark">
                        {halfChecked && (value === undefined || value === null) ? <MdHorizontalRule /> : <MdDone />}
                    </span>
                </span>
            )}

            {label &&
                (typeof label === 'string' ? (
                    <div
                        title={titleAtt}
                        dangerouslySetInnerHTML={{ __html: cleanLabelText }}
                        className={clsx('rf-checkbox__label', showIconClass)}
                        tabIndex={-1}
                    />
                ) : (
                    <div title={titleAtt} className={clsx('rf-checkbox__label', showIconClass)} tabIndex={-1}>
                        {label}
                    </div>
                ))}
        </label>
    );
};

export default Checkbox;
