import React from 'react';
import { IBaseAction } from '@models/actions/IBaseAction';
import { IActionExecutor } from '@organisms/actions/IActionExecutor';
import { DueTimeModeType } from '@/types/DueTimeType';
import { IDueTime } from '@/types/IDueTime';
import { ModalSize } from '@/types/ModalSize';
import { IAddRouteMapProps } from './IAddRouteMapProps';
import { AddRouteMapModal } from './AddRouteMapModal';

export class AddRouteMapExecutor implements IActionExecutor {
    private _modalProps?: IAddRouteMapProps;

    run = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        raiseVisualElement?: () => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            actionKey: action.key,
            activityKey: action.options?.activityKey,
            processKey: action.options?.processKey,
            documentKey: action.options?.documentKey,
            modalSize: modalSize ?? action.options?.modalSize ?? 'l',
            levelKey: action.options?.levelKey ?? '',
            nextlevelKey: action.options?.nextlevelKey ?? '',
            abookOptions: action.options?.abookOptions ?? [],
            prefilledComment: action.options?.prefilledComment ?? '',
            defaultDueTime:
                action.options?.defaultDueTime ?? ({ type: DueTimeModeType[DueTimeModeType.Indefinite] } as IDueTime),
            completeHandler: completeHandler,
            rowData: rowData
        };
    };

    visualElement = () => {
        return this._modalProps ? <AddRouteMapModal {...this._modalProps} /> : <></>;
    };
}
