import './CustomEmailExecutor.scss';
import React, { useState } from 'react';
import MessageDialog, { IMessageDialogProps } from '@molecules/MessageDialog/MessageDialog';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import { ICustomEmailExecutorProps } from './ICustomEmailExecutorProps';

export const CustomEmailExecutorModal: React.FC<ICustomEmailExecutorProps> = (props: ICustomEmailExecutorProps) => {
    const [errorText, setErrorText] = useState<string>();

    const messageBoxProp: IMessageDialogProps = {
        header: props.title,
        message: props.message,
        showOkButton: true,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        size: props.modalSize,
        okClick: () => {
            let service = new DocumentExecutionService(props.docId);
            return service
                .sendCustomEmails(props.actionKey)
                .then(() => {
                    if (props.completeHandler) props.completeHandler(true);
                })
                .catch((error) => setErrorText(error));
        },
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
    };

    return <MessageDialog {...messageBoxProp} errorText={errorText} />;
};
