import React, { FC, useEffect } from 'react';
import AppRouter from '@/components/AppRouter';
import LayoutMain from '@organisms/layouts/LayoutMain';
import LayoutEmpty from '@organisms/layouts/LayoutEmpty';
import { useTypedSelector } from '@hooks/useTypedSelector';
import { useActions } from '@hooks/useActions';
import Navbar from '@molecules/Navbar/Navbar';
import { TITLE_PREFIX } from '@/configuration';

const App: FC = () => {
    const { getCurrentUser } = useActions();
    useEffect(() => {
        getCurrentUser(localStorage.getItem('frontAccessToken') as string);
    }, []);
    const isAuth = useTypedSelector((state) => state.auth.isAuth);

    return (
        <>
            <title>{`${TITLE_PREFIX}`}</title>
            {isAuth ? (
                <LayoutMain header={<Navbar />}>
                    <AppRouter />
                </LayoutMain>
            ) : (
                <LayoutEmpty>
                    <AppRouter />
                </LayoutEmpty>
            )}
        </>
    );
};

export default App;
