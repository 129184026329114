import './DocumentsBatchEditingExecutor.scss';
import React, { useCallback, useEffect, useState } from 'react';
import { IForms } from '@models/Forms/IForms';
import { IFieldElem } from '@models/IFormData';
import { IAttachesCreateModel } from '@models/attaches/IAttachesCreateModel';
import { IMailRemindersBlockModel } from '@models/mailRemindersBlock/IMailRemindersBlockModel';
import { useTypedSelector } from '@hooks/useTypedSelector';
import { FieldValues, useForm } from 'react-hook-form';
import { IDocumentData } from '@models/document/IDocumentData';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import { AttachActionsService } from '@services/actions/AttachActionsService';
import { RemindersService } from '@services/RemindersService';
import {
    checkAttachValidation,
    convertListToMap,
    getEmptyFormDataByScheme,
    getFormDataByScheme,
} from '@utils/documentUtils';
import { DocumentService } from '@services/DocumentService';
import { VariablesManager } from '@utils/managers/VariablesManager';
import { FormulaManager } from '@utils/managers/FormulaManager';
import { ISelectedFiles } from '@atoms/AttachFilesBlock/ISelectedFiles';
import { IMailRemindersOptions } from '@molecules/AddMailReminderBlock/AddMailReminderBlock';
import { ValidatorManager } from '@utils/managers/ValidatorManager';
import { sendErrorMsg } from '@molecules/Errors';
import ModalFormBuilder, { IModalFormBuilderProps } from '@molecules/ModalFormBuilder/ModalFormBuilder';
import Preloader from '@atoms/Preloader';
import { IDocumentsBatchEditingExecutorProps } from './IDocumentsBatchEditingExecutorProps';

export const DocumentsBatchEditingExecutorModal: React.FC<IDocumentsBatchEditingExecutorProps> = (
    props: IDocumentsBatchEditingExecutorProps,
) => {
    const [loading, setLoading] = useState<boolean>();
    const [errorText, setErrorText] = useState<string>();
    const [warningText, setWarningText] = useState<string>();
    const [showOkButton, setShowOkButton] = useState<boolean>(true);
    const [form, setForm] = useState<IForms>();
    const [fieldsData, setFieldsData] = useState<Record<string, IFieldElem>>();
    const [attachesCreateModel, setAttachesCreateModel] = useState<IAttachesCreateModel>();
    const [mailRemindersBlockModel, setMailRemindersBlockModel] = useState<IMailRemindersBlockModel>();
    const user = useTypedSelector((state) => state.auth.user);

    const methods = useForm<IDocumentData>({
        mode: 'onBlur', // "onChange"
    });
    const service = new DocumentExecutionService(props.docId);

    useEffect(() => {
        // Функция для получения модели аттачей
        async function fetchAttachModel() {
            if (props.attachFiles) {
                let attachService = new AttachActionsService(props.docId, '-1');
                const res = await attachService.getAttachInfo(props.actionKey);
                setAttachesCreateModel(res.data);
            }
        }

        // Функция для получения модели блока напоминаний
        async function fetchMailRemindersBlockModel() {
            if (props.addMailReminders) {
                const res = await RemindersService.getMailRemindersBlockModel(props.docId, props.actionKey);
                setMailRemindersBlockModel(res.data);
            }
        }

        // Функция для получения схемы
        async function fetchFormBuilderScheme() {
            try {
                const dto = await service.getFormBuilderScheme(props.actionKey);
                let form = dto.data;
                setForm(form);

                if (!props.showCurrentValues) {
                    let formData = getEmptyFormDataByScheme(form?.view?.rows?.row!);
                    let fields = convertListToMap(formData.fields);

                    return { fields, formData, form };
                }
            } catch (error) {
                setErrorText((error as Error)?.message);
            }
        }

        // Функция для получения данных
        async function fetchDataByAction() {
            if (props.showCurrentValues) {
                try {
                    const result = await DocumentService.getDataByAction(
                        props.actionKey,
                        props.docId,
                        undefined,
                        false,
                        props.showCurrentValues,
                    );
                    const formData = result.data;

                    if (formData) {
                        let f = result.data.fields;

                        // Добавление виртуальных полей
                        if (props.virtualFields) {
                            props.virtualFields.forEach((x) => {
                                f.push({
                                    name: x,
                                    value: undefined,
                                });
                            });
                        }

                        let fields = convertListToMap(f);

                        return { fields, formData };
                    }
                } catch (error) {
                    setErrorText((error as Error)?.message);
                }
            }
        }

        async function fetchData() {
            await fetchAttachModel();
            await fetchMailRemindersBlockModel();
            const formResult = await fetchFormBuilderScheme();
            const dataResult = await fetchDataByAction();

            const fields = dataResult?.fields ?? formResult?.fields;
            const formData = dataResult?.formData ?? formResult?.formData;
            const form = formResult?.form;

            // добавляем Variables
            if (fields) {
                let mng = new VariablesManager(
                    form?.view.variables ?? { variable: [], dictVariable: [] },
                    props.docId,
                    user,
                );
                let variables = await mng.GetData(fields, true, false);
                console.log(variables);
                fields['variables'] = {
                    name: 'variables',
                    value: variables,
                    index: -1,
                };
            }

            setFieldsData(fields);
            methods.reset(formData);
        }

        fetchData().catch((error) => setErrorText(error.message));
    }, []);

    /**
     * Обрабатывает подстановки
     * @param expression
     * @returns
     */
    const processSubstitutionsAsync = async (expression: string): Promise<string | undefined> => {
        let mng = new FormulaManager(expression);
        mng.Init();
        let fields = mng.GetFields();
        if (fields.length > 0) {
            let response = await DocumentService.getFormData(props.docId, mng.GetFields());
            return mng.ReplaceFormulaValues(
                false,
                false,
                response.data.fields.map((field) => field.value),
                true,
            );
        } else {
            return Promise.resolve(expression);
        }
    };

    /**
     * Обрабатывает post-action действия
     * @param actions
     */
    const processPostActions = (actions?: any[]) => {
        if (actions) {
            actions.forEach(async (action) => {
                switch (action.type) {
                    case 'openUrlInNewTab':
                        let url = await processSubstitutionsAsync(action.url);
                        window.open(url, '_blank', 'noopener,noreferrer');
                        break;

                    default:
                        console.log('Неизвестный тип post-action действия');
                        break;
                }
            });
        }
    };

    const onSubmit = useCallback(
        async (data: FieldValues, files?: ISelectedFiles, mailReminderOptions?: IMailRemindersOptions) => {
            setLoading(true);

            // проверка на обязательность наличия аттачей
            if (
                props.attachFiles &&
                props.attachRequired &&
                (files?.files === undefined || files?.files.length === 0)
            ) {
                setWarningText('Необходимо приложить файл');
                setLoading(false);
                return;
            }

            if (props.attachRequiredValidation && props.attachRequiredValidation.length > 0) {
                let checkResult = checkAttachValidation(props.attachRequiredValidation, files);
                if (checkResult.length > 0) {
                    let text = checkResult.join('\n');
                    setWarningText(text);
                    setLoading(false);
                    return;
                }
            }
            let errors: string[] = [];

            if (form?.view?.validators && fieldsData) {
                let validatorManager = new ValidatorManager(fieldsData, methods);
                await validatorManager.validateAllAsync(form?.view.validators?.validator, errors);

                if (errors.length > 0) {
                    sendErrorMsg({
                        message: errors,
                    });
                    setLoading(false);
                    return;
                }
            }

            let val = getFormDataByScheme(form?.view?.rows?.row!, data);

            // Сохранение данных
            service
                .saveDocumentsBatchEditingData(props.docId, props.actionKey, val, files)
                .then((result) => {
                    if (result.data.warnings && result.data.warnings.length > 0) {
                        setWarningText(result.data.warnings.join(', '));
                        setShowOkButton(false);
                    } else {
                        processPostActions(props.postActions);
                        props.completeHandler && props.completeHandler(true);
                    }
                })
                .catch((error) => setErrorText(error))
                .finally(() => {
                    setLoading(false);
                });

            if (mailReminderOptions && mailRemindersBlockModel) {
                let remIds =
                    val.fields.find((item) => item.name === mailRemindersBlockModel.reminderUserIdsField)?.value ?? '';
                let remTargetDate = new Date(
                    val.fields.find((item) => item.name === mailRemindersBlockModel.reminderTargetDateField)
                        ?.value as string,
                );
                let remText =
                    val.fields.find((item) => item.name === mailRemindersBlockModel.reminderTextField)?.value ?? '';

                let dayOfWeek =
                    mailReminderOptions.periodical !== undefined &&
                    mailReminderOptions.periodical.dayOfWeek !== undefined
                        ? mailReminderOptions.periodical.dayOfWeek[0]?.value
                        : '';

                //Создание напоминаний
                await RemindersService.createBlockMailReminders(
                    props.docId,
                    props.actionKey,
                    remText?.toString(),
                    remTargetDate.toISOString(),
                    remIds?.toString(),
                    mailReminderOptions.daysBefore,
                    mailReminderOptions.periodical.weekly === 1 ? 'true' : 'false',
                    mailReminderOptions.periodical.monthly === 1 ? 'true' : 'false',
                    dayOfWeek,
                );
            }
        },
        [form, fieldsData, methods],
    );

    const messageBoxProp: IModalFormBuilderProps = {
        header: props.title,
        showOkButton: showOkButton,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        size: props.modalSize,
        isBusy: loading,
        onSubmit: onSubmit,
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
        errorText: errorText,
        warningText: warningText,
        formMethods: methods,
        fields: fieldsData!,
        rows: form?.view,
        createAttachModel: attachesCreateModel,
        mailRemindersBlockModel: mailRemindersBlockModel,
        showContent: showOkButton,
        docId: props.docId,
    };

    return fieldsData ? <ModalFormBuilder {...messageBoxProp} /> : <Preloader size="l" />;
};
