import React, { JSX, useEffect, useState } from 'react';
import './Avatar.scss';
import { AvatarColor, IIconProps, Size } from '@/types';
import { sizeClass } from '@utils/helpers';
import clsx from 'clsx';

export interface IAvatarProps {
    size?: Size;
    /** Ссылка на фото */
    photo?: string;
    /** Имя */
    firstName?: string;
    /** Фамилия */
    lastName?: string;
    /** Цвет фона аватара */
    backgroundColor?: AvatarColor;
    /** Иконка */
    icon?: (props: IIconProps) => JSX.Element;
}

const Avatar: React.FC<IAvatarProps> = ({
    size = 'm',
    photo = '',
    firstName = '',
    lastName = '',
    backgroundColor = 'default',
    icon: Icon,
}: IAvatarProps) => {
    const [initials, setInitials] = useState<string>('');

    useEffect(() => {
        if (firstName && lastName) {
            let text = '';
            firstName && (text = firstName.charAt(0).toUpperCase());

            // при размере xxs и xs оставляем только одну букву инициалов
            if (size !== 'xxs' && size !== 'xs') {
                lastName && (text += lastName.charAt(0).toUpperCase());
            }

            setInitials(text);
        }
    }, [firstName, lastName]);

    return (
        <div
            className={clsx('rf-avatar', sizeClass[size], `rf-avatar__background_${backgroundColor}`)}
            style={{ backgroundImage: `url("${photo}")` }}
        >
            {Icon ? (
                <div className="rf-avatar__icon-wrapper">
                    <Icon className={clsx('rf-avatar__icon', sizeClass[size])} />
                </div>
            ) : (
                !photo && initials
            )}
        </div>
    );
};

export default Avatar;
