import React from 'react';
import { IActivityAction, IBaseAction } from '@models/actions/IBaseAction';
import { ActivityBaseActionExecutor } from '@organisms/actions/ActivityBaseActionExecutor';
import { getActivityIdFromCompositeId } from '@utils/helpers';
import { ModalSize } from '@/types/ModalSize';
import { ICompleteProps } from './ICompleteProps';
import { CompleteModal } from './CompleteModal';

export class CompleteExecutor extends ActivityBaseActionExecutor {
    private _model?: ICompleteProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        let act = action as IActivityAction;
        this._model = {
            displayName: action.displayName,
            actionKey: action.key ?? '',
            actId: getActivityIdFromCompositeId(objId),
            docId: act.docId,
            commentIsRequired: action.options?.commentIsRequired ?? false,
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'm',
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._model ? <CompleteModal {...this._model} /> : <></>;
    };
}
