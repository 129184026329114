import React, { useCallback, useEffect, useState } from 'react';
import { IOption } from '@/types';
import { useTypedSelector } from '@hooks/useTypedSelector';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import { WIICFreeDateService } from '@services/WIICFreeDateService';
import { IWIICCheckFreeTimeResultDto } from '@models/wiicFreeDate/IWIICCheckFreeTimeResultDto';
import { formatDate } from '@utils/helpers';
import { useForm, useWatch } from 'react-hook-form';
import { IWIICReservTimeResultDto } from '@models/wiicFreeDate/IWIICReservTimeResultDto';
import ModalContainer, { IModalContainerProps } from '@molecules/ModalContainer/ModalContainer';
import Hint from '@atoms/Hint';
import { DatepickerControl, SelectControl } from '@controls/index';
import { IWIICFreeDateProps } from './IWIICFreeDateProps';
import { IWIICFreeDateFormData } from './IWIICFreeDateFormData';

export const WIICFreeDateExecutorModal: React.FC<IWIICFreeDateProps> = (props: IWIICFreeDateProps) => {
    const [errorText, setErrorText] = useState<string>();
    const [loading, setLoading] = useState<boolean>();
    const [minDate, setMinDate] = useState<Date | undefined>();
    const [maxDate, setMaxDate] = useState<Date | undefined>();
    const [timeSlots, setTimeSlots] = useState<IOption[]>();
    const [timeSlotsEnable, setTimeSlotsEnable] = useState<boolean>(false);

    const fields = useTypedSelector((state) => state.document.data?.fields);
    const service = new DocumentExecutionService(props.docId);

    const getTomorrow = () => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);

        return tomorrow;
    };

    const getDateFromField = (fieldName: string | undefined) => {
        let field = fields?.find((_) => _.name === fieldName)?.value?.toString();

        return !field ? getTomorrow() : new Date(field);
    };

    const checkFreeDate = (value: Date | undefined) => {
        setErrorText('');
        setTimeSlots([]);

        WIICFreeDateService.checkFreeTime(props.docId, value ?? getTomorrow())
            .then((dto) => {
                let data = dto.data as IWIICCheckFreeTimeResultDto;
                if (data.mindatetime) {
                    setMinDate(new Date(data.mindatetime));
                }
                if (data.maxdatetime) {
                    setMaxDate(new Date(data.maxdatetime));
                }
                if (
                    data.mindatetime &&
                    new Date(data.mindatetime).getTime() > getDateFromField(props.defaultDateField).getTime()
                ) {
                    formState.setValue('freeDate', new Date(data.mindatetime) as any);
                }
                if (!data.datefree) {
                    let date = formatDate(data.newdate!.toString()).date;
                    setErrorText('Выбранная дата недоступна. Ближайший свободный слот ' + date);
                } else {
                    setTimeSlots(
                        data.slot
                            ?.sort((a, b) => a.timeslot!.toString().localeCompare(b.timeslot!.toString()))
                            .map((f) => {
                                let time = formatDate(f.timeslot!.toString()).time;
                                return {
                                    label: time,
                                    value: time,
                                } as IOption;
                            }),
                    );
                }
            })
            .catch((error) => setErrorText(error));
    };

    const formState = useForm<IWIICFreeDateFormData>({
        mode: 'onBlur', // "onChange"
        defaultValues: {
            freeDate: getDateFromField(props.defaultDateField),
        },
    });

    const watchFreeTime = useWatch({ name: 'freeTime', control: formState.control, defaultValue: [] });

    useEffect(() => {
        disableSubmit(watchFreeTime, timeSlots);
    }, [watchFreeTime, timeSlots]);

    useEffect(() => {
        const date = formState.formState.defaultValues?.freeDate;
        checkFreeDate(date);
    }, []);

    const disableSubmit = useCallback(
        (value?: IOption[], options?: IOption[]) => {
            if (value && value.length > 0 && options && options.length > 0) {
                let val = options.filter((x) => x.value === value[0].value);
                setTimeSlotsEnable(val.length > 0);
            }
        },
        [formState],
    );

    const onSubmit = (form: IWIICFreeDateFormData) => {
        setLoading(true);

        if (form.freeDate && form.freeTime) {
            const partDate = form.freeDate;
            const year = partDate.getFullYear();
            const month = partDate.getMonth();
            const day = partDate.getDate();

            const partTime = form.freeTime[0].value.toString().split(':');
            const hour = Number(partTime[0]);
            const minute = Number(partTime[1]);

            const date = new Date(year, month, day, hour, minute);

            return WIICFreeDateService.reservTime(props.docId, date)
                .then((dto) => {
                    let data = dto.data as IWIICReservTimeResultDto;
                    if (data.confirm) {
                        service
                            .saveDocumentsBatchEditingData(
                                props.docId,
                                props.actionKey,
                                {
                                    fields: [],
                                },
                                undefined,
                            )
                            .then((result) => {
                                props.completeHandler && props.completeHandler(true);
                            })
                            .catch((error) => setErrorText(error));
                    } else {
                        setErrorText('Не пришло подтверждение на выбранное время.');
                    }
                })
                .catch((error) => setErrorText(error))
                .finally(() => setLoading(false));
        }
    };

    const modalContainerProps: IModalContainerProps = {
        header: props.title,
        size: props.modalSize,
        showOkButton: true,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        isBusy: loading,
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
        //errorText: errorText, // Потребовалось выводить сообщение без префикса "Ошибка: "
        formMethods: formState,
        onSubmit: onSubmit,
        okButtonDisabled: !timeSlotsEnable,
    };

    return (
        <ModalContainer {...modalContainerProps}>
            {errorText && <Hint icon="info" title={errorText} variant="red" maxWidth="100%" />}
            <div style={{ display: 'flex' }}>
                <div style={{ width: '55%' }}>
                    <DatepickerControl
                        name="freeDate"
                        className={'rf-input--left'}
                        formState={formState.formState}
                        control={formState.control}
                        minDate={minDate ? minDate : getTomorrow()}
                        maxDate={maxDate}
                        readOnly={false}
                        showClearIcon={false}
                        onChangeVal={checkFreeDate}
                    />
                </div>
                <div style={{ width: '45%' }} className="rf-input--right">
                    <SelectControl
                        multiselect={false}
                        name="freeTime"
                        options={timeSlots ?? []}
                        preloader={loading}
                        formState={formState.formState}
                        control={formState.control}
                        showClearIcon={false}
                        onlySelect={true}
                        readOnly={true}
                        getValue={(options: IOption[], value: any) => {
                            if (options.length > 0 && value.length > 0) {
                                return options.filter((x) => x.value === value[0].value);
                            }
                            return [];
                        }}
                        placeholder="Выберите время"
                    />
                </div>
            </div>
        </ModalContainer>
    );
};
