import React from 'react';
import { IActivityAction, IBaseAction } from '@models/actions/IBaseAction';
import { ActivityBaseActionExecutor } from '@organisms/actions/ActivityBaseActionExecutor';
import { getActivityIdFromCompositeId } from '@utils/helpers';
import { ModalSize } from '@/types/ModalSize';
import { IRequiredValidation } from '@models/actions/IRequiredValidation';
import { IApproveActionModel, IBaseWithSignProps } from './IBaseWithSignProps';
import { BaseWithSignModal } from './BaseWithSignModal';

export abstract class BaseWithSignExecutor extends ActivityBaseActionExecutor {
    private _model?: IBaseWithSignProps;

    protected approveModel?: IApproveActionModel;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        let act = action as IActivityAction;
        if (this.approveModel) {
            this._model = {
                actionKey: action.key,
                displayName: action.displayName,
                approveModel: this.approveModel,
                defaultAlg: action.options?.defaultAlg ?? 'rsa',
                gostCases: action.options?.gostCases ?? [],
                gostCN: action.options?.gostCN ?? [],
                certDataChecks: action.options?.certDataChecks,
                mchdDataChecks: action.options?.mchdDataChecks,
                okButtonText: action.options?.okButtonText ?? 'ОК',
                cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
                modalSize: modalSize ?? action.options?.modalSize ?? 'xxl',
                docFlow: act?.flowName,
                docId: act.docId,
                objId: objId,
                activityId: getActivityIdFromCompositeId(objId),
                actName: act?.actName ?? rowData?.fields?.ActivityName,
                attachFiles: action.options?.attachFiles ?? false,
                previewAttachFiles: action.options?.previewAttachFiles ?? false,
                attachRequired: action.options?.attachRequired ?? false,
                attachRequiredValidation: action.options?.attachRequiredValidation as IRequiredValidation[],
                completeHandler: completeHandler,
            };
        }
    };

    visualElementInner = () => {
        return this._model ? <BaseWithSignModal {...this._model} /> : <></>;
    };
}
