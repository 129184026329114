import './Notification.scss';
import React, { FC } from 'react';
import { MdClose, MdInfoOutline } from 'react-icons/md';
import { INotification } from '../Notifications/INotification';
import Button from '@atoms/Button';
import DOMPurify from 'dompurify';
import clsx from 'clsx';

export interface INotificationProps {
    /** Данные уведомление */
    item: INotification;
    /** Закрыть уведомление */
    remove?: (id: string) => void;
}

const Notification: FC<INotificationProps> = ({ item, remove }: INotificationProps) => {
    /** Удалить уведомление */
    const removeNotification = () => {
        item.id && remove && remove(item.id);
    };

    const Icon = MdInfoOutline;

    const hasOnlyMessage = !item.variant && !item.title && !remove;

    return (
        <div className={clsx('rf-notification', hasOnlyMessage && 'rf-notification--message')}>
            <div className="rf-notification__right-column">
                {!!item.variant && (
                    <div className="rf-notification__icon-wrapper">
                        <Icon
                            size="20"
                            className={clsx('rf-notification__icon', `rf-notification--${item.variant || 'default'}`)}
                        />
                    </div>
                )}
                <div className={clsx('rf-notification__wrap', !item.variant && 'rf-notification__wrap--no-wrap')}>
                    {item.title && (
                        <h3
                            className={clsx(
                                'rf-notification__title',
                                item.message && 'rf-notification__title--withMargin',
                            )}
                        >
                            {item.title}
                        </h3>
                    )}

                    {item.message && (
                        <p
                            className="rf-notification__message"
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.message) }}
                        ></p>
                    )}
                </div>
            </div>

            {remove && (
                <Button
                    onClick={removeNotification}
                    aria-label="Закрыть"
                    startAdornment={<MdClose size="18" />}
                    buttonType="text"
                    textColor="neutral"
                    size="xs"
                />
            )}
        </div>
    );
};

export default Notification;
