import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import {
    IDictionaryDetailedItems,
    IDictionaryGridScheme,
    IDictionaryListItem,
} from '@models/dictionary/IDictionaryDetailedItems';
import { IDictionaryData, IDictionaryGridData } from '@models/dictionary/IDictionaryData';
import { IField } from '@models/IFormData';
import { getLoadOptionsQuery } from '@utils/loadOptionsConverter';
import { IExternalSearchScheme } from '@models/dictionary/IExternalSearchScheme';
import { IAddFormScheme } from '@models/dictionary/IAddFormScheme';

export class DictionariesService {
    static async getItems(
        dictName: string,
        parentItemId: string[],
        options: any,
    ): Promise<AxiosResponse<IDictionaryListItem[]>> {
        let parentIds = parentItemId === undefined ? '' : parentItemId.map((x) => `&parentIds=${x}`).join('');
        return $api.get<IDictionaryListItem[]>(
            `/dictionaries/items/${dictName}?${getLoadOptionsQuery(options)}${parentIds}`,
        );
    }

    static async fetchDictionaryScheme(dictName: string): Promise<AxiosResponse<IDictionaryDetailedItems>> {
        return $api.get<IDictionaryDetailedItems>(`/dictionaries/scheme/${dictName}`);
    }

    static async fetchExternalSearchScheme(searchFormName: string): Promise<AxiosResponse<IExternalSearchScheme>> {
        return $api.get<IExternalSearchScheme>(`/dictionaries/exsearchscheme/${searchFormName}`);
    }

    static async fetchAddFormScheme(searchFormName: string): Promise<AxiosResponse<IAddFormScheme>> {
        return $api.get<IExternalSearchScheme>(`/dictionaries/addformscheme/${searchFormName}`);
    }

    static async fetchFormdataDictionaryGridScheme(dictName: string): Promise<AxiosResponse<IDictionaryGridScheme>> {
        return $api.get<IDictionaryGridScheme>(`/dictionaries/gridFormdataScheme/${dictName}`);
    }
    static async fetchDictionaryGridScheme(dictName: string): Promise<AxiosResponse<IDictionaryGridScheme>> {
        return $api.get<IDictionaryGridScheme>(`/dictionaries/gridScheme/${dictName}`);
    }
    static async getGridFormdataItems(
        docId: string,
        dictName: string,
        loadOptions: any,
    ): Promise<AxiosResponse<IDictionaryGridData>> {
        return $api.get<IDictionaryGridData>(`/dictionaries/gridFormdataItems/${docId}/${dictName}${loadOptions}`);
    }
    static async getTreeItems(
        dictName: string,
        parentItemId: string,
        loadOptions: any,
    ): Promise<AxiosResponse<IDictionaryGridData>> {
        return $api.get<IDictionaryGridData>(`/dictionaries/tree/items/${dictName}/${parentItemId}${loadOptions}`);
    }
    static async getGridItems(dictName: string, loadOptions: any): Promise<AxiosResponse<IDictionaryGridData>> {
        return $api.get<IDictionaryGridData>(`/dictionaries/gridItems/${dictName}${loadOptions}`);
    }

    static async getItem(dictName: string, itemId: string): Promise<AxiosResponse<IDictionaryData>> {
        return $api.get<IDictionaryData>(`/dictionaries/data/${dictName}/${itemId}`);
    }
    static async getNewItem(
        dictName: string,
        structIds: string[],
        parentId?: string,
    ): Promise<AxiosResponse<IDictionaryData>> {
        return $api.get<IDictionaryData>(`/dictionaries/newData/${dictName}/${parentId ?? ''}`, {
            params: {
                subDictKeys: structIds,
            },
        });
    }
    static async getItemByCode(dictName: string, code: string): Promise<AxiosResponse<IDictionaryData>> {
        return $api.get<IDictionaryData>(`/dictionaries/itemByCode/${dictName}/${code}`);
    }
    static async getSubTreeByCode(dictName: string, code: string): Promise<AxiosResponse<Record<string, any>>> {
        return $api.get<Record<string, any>>(`/dictionaries/SubTreeByCode/${dictName}/${code}`);
    }
    static async getItemsByCode(dictName: string, codes: string[]): Promise<AxiosResponse<IDictionaryData[]>> {
        return $api.get<IDictionaryData[]>(`/dictionaries/itemsByCode/${dictName}/${codes.join(',')}`);
    }

    static async getTreeItemsByCode(dictName: string, codes: string[]): Promise<AxiosResponse<IDictionaryData[]>> {
        return $api.get<IDictionaryData[]>(`/dictionaries/treeItemsByCode/${dictName}/${codes.join(',')}`);
    }

    static async getItemByFilter(
        dictName: string,
        dictColumn: string,
        code: string,
        type: string,
    ): Promise<AxiosResponse<IDictionaryData>> {
        return $api.get<IDictionaryData>(`/dictionaries/itemByFilter/${dictName}/${dictColumn}/${code}/${type}`);
    }
    static async deleteItem(dictName: string, itemId: string): Promise<AxiosResponse<void>> {
        return $api.delete(`/dictionaries/data/${dictName}/${itemId}`);
    }

    static async updateItem(
        dictName: string,
        itemId: string,
        attributes: IField[],
        structIds: string[],
    ): Promise<AxiosResponse<void>> {
        return $api.patch(`/dictionaries/data/${dictName}/${itemId}`, attributes, {
            params: {
                subDictKeys: structIds,
            },
        });
    }
    static async addItem(
        dictName: string,
        attributes: IField[],
        structIds: string[],
        parentId?: string,
    ): Promise<AxiosResponse<void>> {
        return $api.post(`/dictionaries/data/${dictName}/${parentId ?? ''}`, attributes, {
            params: {
                subDictKeys: structIds,
            },
        });
    }

    static async getAllDictsItems(
        dictNames: string[],
    ): Promise<AxiosResponse<{ [dictName: string]: IDictionaryData[] }>> {
        return $api.get<{ [dictName: string]: IDictionaryData[] }>(`/dictionaries/getAllItems`, {
            params: {
                dictNames: dictNames,
            },
        });
    }

    static async fetchExternalSearch(searchApiUrl: string, formData: any): Promise<AxiosResponse<any>> {
        return $api.post<any>(`${searchApiUrl}`, formData);
    }

    static async saveExternalSearch(saveApiUrl: string, selectItem: any): Promise<AxiosResponse<any>> {
        return $api.post<any>(`${saveApiUrl}`, selectItem);
    }
}
