import './CustomComponent.scss';
import React, { useEffect } from 'react';
import { CustomComponentTypes } from '@models/Forms/CustomComponentTypes';
import WinnerSelectionTableDemand from './components/WinnerSelection/WinnerSelectionTableDemand/WinnerSelectionTableDemand';
import WinnerSelectionTablePositions from './components/WinnerSelection/WinnerSelectionTablePositions/WinnerSelectionTablePositions';
import WinnerSelectionTableKO from './components/WinnerSelection/WinnerSelectionTableKO/WinnerSelectionTableKO';
import SupplyScheduleTable from './components/SupplySchedule/SupplyScheduleTable/SupplyScheduleTable';
import { ICustomComponentProps } from './ICustomComponentProps';

const CustomComponent = <TFieldValues extends object = object>({ ...props }: ICustomComponentProps<TFieldValues>) => {
    const activated = React.useRef(false);

    useEffect(() => {
        activated.current = true;
        return () => {
            activated.current = false;
        };
    }, []);

    const renderComponent = () => {
        switch (props.component.type) {
            case CustomComponentTypes.Unknown:
                return 'Неизвестный тип компонента';
            case CustomComponentTypes.WinnerSelectionTableDemand:
                return <WinnerSelectionTableDemand {...props} />;
            case CustomComponentTypes.WinnerSelectionTableLot:
                return 'Кастомное представление выбора победителя. Полотовое представление';
            case CustomComponentTypes.WinnerSelectionTablePositions:
                return <WinnerSelectionTablePositions {...props} />;
            case CustomComponentTypes.WinnerSelectionTableMixed:
                return 'Кастомное представление выбора победителя. Смешанное представление';
            case CustomComponentTypes.WinnerSelectionTableKO:
                return <WinnerSelectionTableKO {...props} />;
            case CustomComponentTypes.SupplyScheduleTable:
                return <SupplyScheduleTable {...props} />;
            default:
                return 'Неизвестный тип компонента';
        }
    };

    return (
        <div
            className={'custom-component'}
            data-testid={props.component.id ? `custom-component-${props.component.id}` : undefined}
        >
            {renderComponent()}
        </div>
    );
};

export default CustomComponent;
