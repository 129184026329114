import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { IBaseAction } from '@models/actions/IBaseAction';
import { IAttachesCreateModel, IAttachesProperty } from '@models/attaches/IAttachesCreateModel';
import { IAttachViewSignModel, IAttachWithContentBase64 } from '@models/attaches/IAttachViewSignModel';
import { IDocAttachesVersions } from '@models/attaches/IDocAttachesVersions';
import { IActionExecutor } from '@organisms/actions/IActionExecutor';
import { FilesService } from '@services/FilesService';
import { IDocBasedService } from './IDocBasedService';
import { IAttachListItemModel } from '@models/attaches/IAttachListItemModel';
import { IActionService } from '@services/actions/IActionService';
import { AttachActionsExecFactory } from '@organisms/actions/AttachActionsExecFactory';
import { ISelectedFiles } from '@atoms/AttachFilesBlock/ISelectedFiles';

export class AttachActionsService implements IActionService<IBaseAction>, IDocBasedService {
    documentId: string;
    flowKey: string;

    constructor(docId: string, flowKey: string) {
        this.documentId = docId;
        this.flowKey = flowKey;
    }

    public getActionExecutor(act: IBaseAction): IActionExecutor {
        return AttachActionsExecFactory.GetActionExecutor(act)!;
    }

    //Получает перечень доступных действий для документа и аттача
    async fetchActions(fileId: string): Promise<AxiosResponse<IBaseAction[]>> {
        return $api.get<IBaseAction[]>(`attachment/getAttacheActions/${this.documentId}/${fileId}`);
    }

    //Получает перечень доступных групповых действий для аттачей документа
    async fetchGroupActions(fileIds: string): Promise<AxiosResponse<IBaseAction[]>> {
        return $api.get<IBaseAction[]>(`attachment/getAttacheGroupActions/${this.documentId}/${fileIds}`);
    }

    //Удаляет аттач
    async deleteAttach(attachId: string): Promise<AxiosResponse> {
        return $api.delete(`attachment/${this.documentId}/${attachId}`);
    }

    //загрузка аттачей из модального окна
    async createAttaches(files: ISelectedFiles): Promise<AxiosResponse<IAttachesCreateModel>> {
        const formData = new FormData();
        files.attachProperties.map((prop) => formData.append('attachProps', JSON.stringify(prop)));
        files.files.map((f) => formData.append('attachFiles', f));

        return $api.post(`attachment/createAttaches/${this.documentId}`, formData);
    }

    //Создает версию аттача
    async createAttachVersion(file?: File, attachId?: string): Promise<AxiosResponse> {
        const formData = new FormData();
        if (file) formData.append('attachVersion', file);
        return $api.post(`attachment/createVersion/${this.documentId}/${attachId}`, formData);
    }

    //Получает свойства/метаданные аттача
    async getAttachProperties(
        properties: string[],
        attachId?: string,
    ): Promise<AxiosResponse<{ [index: string]: IAttachesProperty }>> {
        let propsParameters = properties.map((p) => `props=${p}`).join('&');
        return $api.get<{ [index: string]: IAttachesProperty }>(
            `attachment/properties?docId=${this.documentId}&attachId=${attachId}&${propsParameters}`,
        );
    }

    //Получает свойства/метаданные аттача
    async getAttachmentsProperties(
        properties: string[],
    ): Promise<AxiosResponse<{ [index: string]: { [index: string]: IAttachesProperty } }>> {
        let propsParameters = properties.map((p) => `props=${p}`).join('&');
        return $api.get<{ [index: string]: { [index: string]: IAttachesProperty } }>(
            `attachment/properties/${this.documentId}?${propsParameters}`,
        );
    }

    //Редактирует аттач
    async editAttach(attachProperties: any, attachId?: string): Promise<AxiosResponse> {
        return $api.post(`attachment/edit/${this.documentId}/${attachId}`, attachProperties);
    }

    //получает модель таблицы для прикрепления аттачей
    async getAttachInfo(actionKey: string, activityId?: string): Promise<AxiosResponse<IAttachesCreateModel>> {
        return $api.get<IAttachesCreateModel>(
            `attachment/getAttachCreationModel/${this.documentId}/${this.flowKey}/${actionKey}/${activityId ?? ''}`,
        );
    }

    //Возвращает список аттачей для документа (по ид активити)
    public async getDocAttachKeysByActKey(
        actId: string,
        actionKey?: string,
    ): Promise<AxiosResponse<IDocAttachesVersions[]>> {
        return $api.get<IDocAttachesVersions[]>(`/attachment/getDocAttachList/${actId}/${actionKey}`);
    }

    //Возвращает содержимое аттача в base64
    public async getAttachContentInBase64(docId: number, attachId: string): Promise<string> {
        let response = await $api.get<string>(`/attachment/base64Content/${docId}/${attachId}`);
        return response.data;
    }

    //Возвращает все подписи для аттача
    public getAttachSigns(attachId: number): Promise<AxiosResponse<IAttachViewSignModel>> {
        return $api.get<IAttachViewSignModel>(`/attachment/getSigns/${this.documentId}/${attachId}`);
    }

    //Скачать подпись
    public downloadAttachSigns(attachId: number, signId: string): void {
        FilesService.downloadFile(`attachment/downloadSign/${this.documentId}/${attachId}/${signId}`);
    }

    //скачать подпись и содержимое файла в base64 (для проверки)
    public getAttachSignsWithContentBase64(
        docId: string,
        attachId: number,
        signId: string,
    ): Promise<AxiosResponse<IAttachWithContentBase64>> {
        return $api.get<IAttachWithContentBase64>(
            `/attachment/getSignWithContent/${this.documentId}/${attachId}/${signId}`,
        );
    }

    public getDocsAttaches(docIds: number[]): Promise<AxiosResponse<IAttachListItemModel[]>> {
        return $api.get<IAttachListItemModel[]>(
            `/attachment/getDocsAttaches?${docIds.map((x) => `&docIds=${x}`).join('')}`,
        );
    }
}
