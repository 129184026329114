import React from 'react';
import { IActivityAction, IBaseAction } from '@models/actions/IBaseAction';
import { ActivityBaseActionExecutor } from '@organisms/actions/ActivityBaseActionExecutor';
import { getActivityIdFromCompositeId } from '@utils/helpers';
import { ModalSize } from '@/types/ModalSize';
import { IRequiredValidation } from '@models/actions/IRequiredValidation';
import { IActivityBatchEditingProps } from './IActivityBatchEditingProps';
import { ActivityBatchEditingModal } from './ActivityBatchEditingModal';

export class ActivityBatchEditingExecutor extends ActivityBaseActionExecutor {
    private _model?: IActivityBatchEditingProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        let act = action as IActivityAction;
        this._model = {
            displayName: action.displayName,
            actionKey: action.key ?? '',
            docFlow: act?.flowName,
            actId: getActivityIdFromCompositeId(objId),
            docId: act.docId,
            attachFiles: action.options?.attachFiles == true,
            attachRequired: action.options?.attachRequired == true,
            attachRequiredValidation: action.options?.attachRequiredValidation as IRequiredValidation[],
            addMailReminders: action.options?.addMailReminders == true,
            virtualFields: action.options?.virtualFields as string[],
            showCurrentValues: action.options?.showCurrentValues ?? true,
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'xl',
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._model ? <ActivityBatchEditingModal {...this._model} /> : <></>;
    };
}
