import './LoadFromFileExecutor.scss';
import React, { useCallback, useRef, useState } from 'react';
import { Column, DataGrid, Editing } from 'devextreme-react/data-grid';
import { IFileData } from '@/types';
import { v4 as uuidv4 } from 'uuid';
import { RowRemovedEvent } from 'devextreme/ui/data_grid';
import Modal from '@atoms/Modal';
import clsx from 'clsx';
import Button from '@atoms/Button';
import { MdClose } from 'react-icons/md';
import Preloader from '@atoms/Preloader';
import Hint from '@atoms/Hint';
import InputFile from '@atoms/InputFile';
import { MAX_FILE_SIZE } from '@utils/constants';
import DevExpressDataGrid from '@atoms/DevExpress/DataGrid/DevExpressDataGrid';
import { onCellHoverChanged } from '@utils/dataGridUtils';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import { Buffer } from 'buffer';
import { ILoadFromFileExecutorProps } from './ILoadFromFileExecutorProps';

export const LoadFromFileExecutorModal: React.FC<ILoadFromFileExecutorProps> = (props: ILoadFromFileExecutorProps) => {
    const [errorText, setErrorText] = useState<string>();
    const [isBusy, setIsBusy] = useState<boolean>();

    const [uploadingFiles, setUploadingFiles] = useState<any[]>([]);
    const [binaryFiles, setBinaryFiles] = useState<{ [fileId: string]: File }>({});

    const gridRef = useRef<DataGrid>(null);

    const setFile = (files: IFileData[]) => {
        let newBinaryFiles = { ...binaryFiles };

        if (files) {
            setErrorText('');
            setUploadingFiles([]);
            //setBinaryFiles({});
            for (let i = 0; i < files.length; i++) {
                let f = files[i].file;

                let fileGuid = uuidv4();

                //записываем в словарь
                if (newBinaryFiles && f) {
                    newBinaryFiles[fileGuid] = f;
                }

                let fileObj: any = {
                    _fileId: fileGuid,
                    name: f.name,
                };

                setUploadingFiles((uploadingFiles) => [...uploadingFiles, fileObj]);
            }

            setBinaryFiles(newBinaryFiles);
        }

        gridRef.current?.instance.refresh();
    };

    const onRowRemoved = useCallback(
        (e: RowRemovedEvent) => {
            let removedFileId = e.key._fileId;
            let newBinaryFiles = { ...binaryFiles };
            if (removedFileId && binaryFiles[removedFileId]) {
                delete newBinaryFiles[removedFileId];
                setBinaryFiles(newBinaryFiles);
            }
            setUploadingFiles([...uploadingFiles]);
        },
        [binaryFiles, uploadingFiles],
    );

    const submitHandle = useCallback(() => {
        setIsBusy(true);

        let service = new DocumentExecutionService(props.docId);
        service
            .loadFromFile(props.actionKey, binaryFiles[uploadingFiles[0]._fileId])
            .then((response) => {
                const loadData = response.data;
                if (loadData) {
                    // Скачивание файла с ошибками
                    const loadFileName = response.headers['loadfilename'];
                    const loadFileNameDecoded = Buffer.from(loadFileName, 'base64').toString();
                    const loadDataDecoded = Buffer.from(response.data, 'base64');
                    const byteArray = new Uint8Array(loadDataDecoded);

                    let url = window.URL.createObjectURL(
                        new Blob([byteArray], {
                            type: response.headers['content-type'],
                        }),
                    );
                    let link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', loadFileNameDecoded);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }

                const loadSuccess = response.headers['loadsuccess'];
                if (loadSuccess.toLowerCase() == 'true') {
                    if (props.completeHandler) props.completeHandler(true);
                } else {
                    const loadMessage = response.headers['loadmessage'];
                    const loadMessageDecoded = Buffer.from(loadMessage, 'base64').toString();
                    setErrorText(loadMessageDecoded);
                }

                setIsBusy(false);
            })
            .catch((error) => setErrorText(error))
            .finally(() => {
                setIsBusy(false);
            });
    }, [binaryFiles, props, uploadingFiles]);

    return (
        <Modal
            className={clsx('modal-dlg-container')}
            size={props.modalSize}
            header={
                <div className={clsx('box')}>
                    <div>{props.title}</div>
                    <div className={clsx('left')}>
                        <Button
                            buttonType="text"
                            textColor="neutral"
                            size="xs"
                            aria-label="Закрыть окно"
                            onClick={() => {
                                if (props.completeHandler) props.completeHandler(false);
                            }}
                            startAdornment={<MdClose size="24" />}
                        />
                    </div>
                </div>
            }
            footer={
                <div className="modal-dlg-buttons">
                    <Button
                        disabled={!uploadingFiles || uploadingFiles.length === 0}
                        size="s"
                        aria-label={props.okButtonText}
                        onClick={submitHandle}
                    >
                        {props.okButtonText}
                    </Button>
                    <Button
                        buttonType="light"
                        size="s"
                        aria-label={props.cancelButtonText}
                        onClick={() => {
                            if (props.completeHandler) props.completeHandler(false);
                        }}
                    >
                        {props.cancelButtonText}
                    </Button>
                </div>
            }
        >
            {isBusy ? (
                <Preloader size="m" position="static" />
            ) : (
                <div>
                    {errorText && <Hint icon="info" title={`Ошибка: ${errorText}`} variant="red" maxWidth={'100%'} />}

                    <div className="LoadFromFile__AllowedExtensionsGroup">
                        <p className="LoadFromFile__AllowedExtensions">
                            <span>Список допустимых форматов: </span>
                            {props.allowedFileTypes ? (
                                props.allowedFileTypes
                                    ?.toString()
                                    .split(',')
                                    .map((extension, i) => {
                                        return <span key={i}>.{extension}</span>;
                                    })
                            ) : (
                                <span>ограничений нет</span>
                            )}
                        </p>
                    </div>
                    <InputFile
                        className="LoadFromFile__SelectFileInput"
                        setFile={setFile}
                        maxSize={MAX_FILE_SIZE}
                        multiple={false}
                        showChips={false}
                        clearFilesAfterSet={true}
                        accept={props.allowedFileTypes ? props.allowedFileTypes.toString() : '*'}
                    />

                    <DevExpressDataGrid
                        dataSource={uploadingFiles}
                        height={70}
                        remoteOperations={true}
                        hoverStateEnabled={true}
                        columnHidingEnabled={false}
                        showColumnHeaders={true}
                        columnAutoWidth={true}
                        allowColumnReordering={false}
                        allowColumnResizing={true}
                        columnResizingMode="widget"
                        noDataText={'Файл не выбран'}
                        rowAlternationEnabled={true}
                        ref={gridRef}
                        onRowRemoved={onRowRemoved}
                        onCellHoverChanged={onCellHoverChanged}
                    >
                        <Editing
                            mode="cell"
                            allowUpdating={false}
                            allowAdding={false}
                            allowDeleting={true}
                            confirmDelete={false}
                        />
                        <Column
                            key={`col_name`}
                            allowFiltering={true}
                            caption={'Наименование'}
                            dataField={'name'}
                            dataType={'string'}
                            visible={true}
                            allowSorting={false}
                            allowHeaderFiltering={false}
                            filterOperations={[]}
                        />
                    </DevExpressDataGrid>
                </div>
            )}
        </Modal>
    );
};
