import './ApproveListLevel.scss';
import React, { useEffect, useState } from 'react';
import { IDocumentTable } from '@models/Forms/IForms';
import { ILevel } from './IApproveListModels';
import { MdChevronRight, MdOutlineExpandMore } from 'react-icons/md';
import ApproveListItems from './ApproveListItems';
import { IField } from '@models/IFormData';

export interface IApproveListLevelProps {
    level: ILevel;
    table: IDocumentTable;
    defExpand: boolean;
    cellRenderSwitcher: (p: any, column: any, rowParent?: any) => Promise<React.ReactNode>;
    getParentFields: () => IField[];
    docId?: string;
}

const ApproveListLevel: React.FC<IApproveListLevelProps> = ({
    level,
    table,
    defExpand,
    cellRenderSwitcher,
    getParentFields,
    docId,
}: IApproveListLevelProps) => {
    const [expand, setExpand] = useState<boolean>(defExpand);
    useEffect(() => {
        setExpand(defExpand);
    }, [defExpand]);

    return level ? (
        <div className="apl-level">
            <div
                className="apl-level-header"
                onClick={(e) => {
                    setExpand(!expand);
                }}
            >
                <div className="apl-level-graph-icon">
                    {expand ? <MdOutlineExpandMore size="20" /> : <MdChevronRight size="20 " />}
                </div>
                <div className="apl-level-title">{level.title}</div>
            </div>
            {expand ? (
                <div className="apl-level-box">
                    {/* <div className="apl-level-line apl-level-line--Done"></div> */}
                    <div className="apl-level-content">
                        {level.items && (
                            <ApproveListItems
                                items={level.items}
                                table={table}
                                cellRenderSwitcher={cellRenderSwitcher}
                                getParentFields={getParentFields}
                                docId={docId}
                            />
                        )}
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    ) : (
        <></>
    );
};

export default ApproveListLevel;
