import './RegisterModalExecutor.scss';
import React, { useEffect, useState } from 'react';
import { IDocumentModalState } from '@/store/reducers/DocumentReducer';
import { useNavigate } from 'react-router';
import { DocumentService } from '@services/DocumentService';
import { IDocumentData } from '@models/document/IDocumentData';
import DocumentModal from '@organisms/DocumentModal';
import { IRegisterModalExecutorProps } from './IRegisterModalExecutorProps';

export const RegisterModalExecutorModal: React.FC<IRegisterModalExecutorProps> = (
    props: IRegisterModalExecutorProps,
) => {
    const [isLoading, setIsLoading] = useState(true);
    const [documentState, setDocumentState] = useState<IDocumentModalState>();

    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true);
        Promise.all([
            DocumentService.getSchemeByAction(props.actionKey, props.docId),
            DocumentService.getDataByAction(props.actionKey, props.docId),
        ]).then((values) => {
            const scheme = values[0];
            const data = values[1];
            setDocumentState({
                scheme: scheme.data,
                data: data.data,
            });
            setIsLoading(false);
        });
    }, []);

    const onSaveDocumentModal = async (data: IDocumentData) => {
        data.baseDocKey = props.docId || undefined;
        const result = await DocumentService.saveData(data);

        if (props.openInNewTab) {
            window.open(`./document/${result.data.id}`, '_blank');
        } else {
            navigate('/document/' + result.data.id);
        }

        props.completeHandler && props.completeHandler(true);
    };

    const onCancelDocumentModal = () => {
        props.completeHandler && props.completeHandler(false);
    };

    return (
        <DocumentModal
            scheme={documentState?.scheme}
            data={documentState?.data}
            modalSize={props.modalSize}
            preloaderSize={'m'}
            saveButtonText={props.saveButtonText}
            isLoading={isLoading}
            isEdit={false}
            isNew={true}
            onSave={onSaveDocumentModal}
            onCancel={onCancelDocumentModal}
            fullHeight={false}
        />
    );
};
