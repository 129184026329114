import './VoteQuestionExecutor.scss';
import React, { useEffect, useState } from 'react';
import { ParticipantVoteType } from '@models/document/actionsExecution/ParticipantVoteType';
import { useTypedSelector } from '@hooks/useTypedSelector';
import { IAbookOptions } from '@models/Forms/IAbookOptions';
import { IFormValues } from '@models/Forms/IForms';
import { getABookFormValues } from '@utils/abookUtils';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import { IVoteResult } from '@models/document/actionsExecution/IVotingModel';
import { useForm } from 'react-hook-form';
import Modal from '@atoms/Modal';
import clsx from 'clsx';
import Button from '@atoms/Button';
import { MdClose } from 'react-icons/md';
import Preloader from '@atoms/Preloader';
import Hint from '@atoms/Hint';
import FormProviderControl from '@controls/FormProviderControl';
import FormGroup from '@atoms/FormGroup';
import Abookpicker from '@atoms/Abookpicker';
import { IAddressBookData } from '@models/addressbook/IAddressBookData';
import Radio from '@atoms/Radio';
import Textarea from '@atoms/Textarea';
import { IVoteProps } from './IVoteProps';

export const VoteQuestionExecutorModal: React.FC<IVoteProps> = (props: IVoteProps) => {
    const [errorText, setErrorText] = useState<string>();
    const [loading, setLoading] = useState<boolean>();

    const [comment, setComment] = useState<string>();
    const [decision, setDecision] = useState<ParticipantVoteType>();
    const [participantId, setParticipantId] = useState<number>();
    const [participantName, setParticipantName] = useState<string>();
    const user = useTypedSelector((state) => state.auth.user);

    const [abookOptions, setAbookOptions] = useState<IAbookOptions>();
    const [formValues, setFormValues] = useState<string>('{}');
    const [docIds, setDocIds] = useState<string[]>([]);
    const activated = React.useRef(false);

    const getFormValues = async (docId: string, fValues?: IFormValues) => {
        const vals = await getABookFormValues(docId, fValues);
        if (activated.current && vals) {
            setFormValues(vals);
        }
    };

    useEffect(() => {
        setLoading(true);
        activated.current = true;

        let serv = new DocumentExecutionService(props.docId);
        serv.getVotingModel(props.action.key)
            .then((res) => {
                let dict = res.data;
                let docIds = Object.keys(dict);
                let firstDocId = docIds[0];
                let abookOptions = dict[firstDocId].aBookOptions;

                setAbookOptions(abookOptions);
                getFormValues(firstDocId, abookOptions?.formValues);
                setDocIds(docIds);
            })
            .catch((error) => setErrorText(error))
            .finally(() => {
                setLoading(false);
            });

        // Если голосующий Участник комиссии, то берем голос из конфигурации и записываем голосующим текущего пользователя
        if (props.userType === 'participant') {
            switch (props.participantVote) {
                case 'yes':
                    setDecision(ParticipantVoteType.Yes);
                    break;
                case 'no':
                    setDecision(ParticipantVoteType.No);
                    break;
            }

            setParticipantId(+user.id);
            setParticipantName(user.fullName);
        }
        return () => {
            activated.current = false;
        };
    }, []);

    const onDecisionChanged = (e: any) => {
        let num = +e.target.value;
        let vote = num as ParticipantVoteType;
        setDecision(vote);
    };

    const onSubmit = async () => {
        if (decision !== undefined && participantId) {
            try {
                setLoading(true);
                let voteDataRecord: Record<string, IVoteResult> = {};

                docIds.forEach((docId) => {
                    voteDataRecord[docId] = {
                        voteType: decision,
                        comment: comment ?? '',
                        participantId: participantId,
                    };
                });

                let execServ = new DocumentExecutionService(props.docId);
                await execServ.sendVote(props.action.key, voteDataRecord);

                if (props.completeHandler) props.completeHandler(true);
            } finally {
                setLoading(false);
            }
        }
    };

    const formState = useForm<any>({
        mode: 'onBlur',
    });

    return (
        <Modal
            className={clsx('modal-dlg-container')}
            size={props.modalSize}
            header={
                <div className={clsx('box')}>
                    <div>{props.action.displayName}</div>
                    <div className={clsx('left')}>
                        <Button
                            buttonType="text"
                            textColor="neutral"
                            size="xs"
                            aria-label="Закрыть окно"
                            onClick={() => {
                                if (props.completeHandler) props.completeHandler(false);
                            }}
                            startAdornment={<MdClose size="24" />}
                        />
                    </div>
                </div>
            }
            footer={
                <div className="modal-dlg-buttons">
                    <Button
                        disabled={
                            (props.userType === 'secretary' && !participantId) ||
                            decision === undefined ||
                            (decision === 0 && (!comment || comment.length === 0))
                        }
                        size="s"
                        aria-label={props.okButtonText}
                        onClick={async () => {
                            await onSubmit();
                        }}
                    >
                        {props.okButtonText}
                    </Button>

                    <Button
                        buttonType="light"
                        size="s"
                        aria-label={props.cancelButtonText}
                        onClick={() => {
                            if (props.completeHandler) props.completeHandler(false);
                        }}
                    >
                        {props.cancelButtonText}
                    </Button>
                </div>
            }
        >
            {loading ? (
                <Preloader size="m" position="static" />
            ) : (
                <div>
                    {errorText && <Hint icon="info" title={`Ошибка: ${errorText}`} variant="red" maxWidth="100%" />}
                    {docIds?.length > 1 ? (
                        <Hint
                            icon="info"
                            title={`Голос будет применен ко всем выбранным вопросам!`}
                            variant="yellow"
                            maxWidth="100%"
                        />
                    ) : null}
                    <FormProviderControl
                        formMethods={formState}
                        onSubmit={(e) => {
                            console.log(e);
                        }}
                    >
                        {props.userType === 'secretary' ? (
                            <FormGroup label="Участник комиссии" className="frmGroup">
                                <Abookpicker
                                    isMultiple={false}
                                    title="Участник комиссии"
                                    displayValue={participantName ?? ''}
                                    formValues={formValues}
                                    name="participant"
                                    showChips={false}
                                    tabsSettings={abookOptions?.tabs}
                                    onChange={async (value: IAddressBookData[]) => {
                                        if (value && value.length === 1) {
                                            setParticipantId(value[0].key);
                                            setParticipantName(value[0].fullName);
                                        }
                                    }}
                                />
                            </FormGroup>
                        ) : (
                            <></>
                        )}
                        {props.userType === 'secretary' ||
                        (props.userType === 'participant' && props.participantVote === 'selectable') ? (
                            <FormGroup label="Решение" className="frmGroup">
                                <Radio name="decision" label="За" value="1" onChange={onDecisionChanged} />
                                <Radio name="decision" label="Против" value="0" onChange={onDecisionChanged} />
                            </FormGroup>
                        ) : (
                            <></>
                        )}

                        <FormGroup className="frmGroup">
                            <Textarea
                                placeholder={
                                    'Комментарий' + (decision === ParticipantVoteType.No ? ' (обязательно)' : '')
                                }
                                defaultValue={comment}
                                rows={6}
                                required={decision === ParticipantVoteType.No}
                                onChange={(e: any) => {
                                    setComment(e.target.value);
                                }}
                            />
                        </FormGroup>
                    </FormProviderControl>
                </div>
            )}
        </Modal>
    );
};
