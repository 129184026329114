import './RunAutoTaskExecutor.scss';
import React, { useState } from 'react';
import MessageDialog, { IMessageDialogProps } from '@molecules/MessageDialog/MessageDialog';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import { IRunTaskModel } from '@models/document/actionsExecution/IRunTaskModel';
import { IRunAutoTaskExecutorProps } from './IRunAutoTaskExecutorProps';

export const RunAutoTaskExecutorModal: React.FC<IRunAutoTaskExecutorProps> = (props: IRunAutoTaskExecutorProps) => {
    const [errorText, setErrorText] = useState<string>();
    const [successText, setSuccessText] = useState<string>();
    const [loading, setLoading] = useState<boolean>();

    const messageBoxProp: IMessageDialogProps = {
        header: 'Запуск задачи',
        message: props.message,
        showOkButton: true,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        isBusy: loading,
        size: props.modalSize,
        okClick: () => {
            setErrorText('');
            setSuccessText('');
            setLoading(true);

            let service = new DocumentExecutionService(props.docId);
            let runAutoTaskData: IRunTaskModel = {
                actionKey: props.actionKey,
                taskContext: '',
                parameters: [],
            };
            return service
                .runAutoTask(runAutoTaskData)
                .then((dto) => {
                    //setSuccessText(dto.data);
                    props.completeHandler && props.completeHandler(true);
                })
                .catch((error) => {
                    setErrorText(error);
                })
                .finally(() => setLoading(false));
        },
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
    };

    return <MessageDialog {...messageBoxProp} errorText={errorText} successText={successText} />;
};
