import $api from '@/http/index';
import { IDataGridDescriptionResponse } from '@models/response/IDataGridDescriptionResponse';
import { IGridExportSettings } from '@models/response/IGridExportSettings';
import { IGridResponse } from '@models/response/IGridResponse';
import { getLoadOptionsQuery } from '@utils/loadOptionsConverter';
import { IDataGridService } from '@services/IDataGridService';
import { AxiosResponse } from 'axios';
import { IDocumentResult } from '@models/document/IDocumentResult';
import { FilesService } from '@services/FilesService';

export class DocumentLinksService extends IDataGridService {
    documentId: string;
    actionId?: string;

    constructor(docId?: string, actionId?: string) {
        super();
        this.documentId = docId ?? '-1';
        this.actionId = actionId;
    }

    fetchDetailData(data: any, detailData: any, loadOptions: any): Promise<AxiosResponse<IGridResponse, any>> {
        throw new Error('Method not implemented.');
    }

    async fetchScheme(data: any): Promise<AxiosResponse<IDataGridDescriptionResponse, any>> {
        if (this.documentId === '-1') {
            if (!this.actionId) {
                throw new Error('Отсутствует documentId и actionId при получении схемы грида линков');
            }

            return $api.get<IDataGridDescriptionResponse>(`/document/linkSchemeByAction/${this.actionId}/${data}`);
        } else {
            return $api.get<IDataGridDescriptionResponse>(`/document/linkScheme/${this.documentId}/${data}`);
        }
    }

    async fetchData(data: any, loadOptions: any): Promise<AxiosResponse<IGridResponse, any>> {
        return $api.post<IGridResponse>(`/document/linkData/${this.documentId ?? '-1'}/${data}`, loadOptions);
    }

    async fetchCount(tableKey: any): Promise<AxiosResponse<number, any>> {
        return $api.get<number>(`/document/linkCount/${this.documentId ?? '-1'}/${tableKey}`);
    }

    async putData(data: any): Promise<AxiosResponse<IDocumentResult, any>> {
        return $api.put<IDocumentResult>(`/document/linksUpdate`, data);
    }

    async exportData(template: string, options: any, columns: IGridExportSettings[]): Promise<void> {
        const url = `document/exportLinkedDocGrid?docId=${this.documentId ?? '-1'}&gridKey=${template}&${getLoadOptionsQuery(options)}`;
        await FilesService.downloadFilePost(url, columns);
    }
}
