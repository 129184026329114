import { AxiosResponse } from 'axios';
import $api from '@/http/index';

export class EnvironmentService {
    static async offsetWorkDay(date: Date, offset: number): Promise<AxiosResponse<Date>> {
        let d = date.toISOString();
        return $api.get<Date>(`/Environment/OffsetWorkDay/${d}/${offset}`);
    }

    static async lastWorkDayAfterOffset(
        date: Date,
        offset: number,
        allowedWeekDays: number[],
    ): Promise<AxiosResponse<Date>> {
        let d = date.toISOString();
        return $api.get<Date>(`/Environment/LastWorkDayAfterOffset/${d}/${offset}`, {
            params: {
                allowedWeekDays: allowedWeekDays,
            },
        });
    }

    static async getExtendData(key: string, params: string, encrypted: boolean = false): Promise<AxiosResponse<any[]>> {
        return $api.post(`/Environment/GetExtendData/${key}/${encrypted}`, { param: params });
    }
}
