import React, { useEffect, useState } from 'react';
import { IDocumentTable, ISplitRows } from '@models/Forms/IForms';
import './SplitRowsForm.scss';
import { MdClose } from 'react-icons/md';
import Modal from '../../Modal';
import Button from '@atoms/Button';
import { IFieldElem } from '@models/IFormData';
import TableData from '../TableData';
import { ITableDataProps } from '../ITableDataProps';
import clsx from 'clsx';

export interface IGroupResult {
    result: any;
    items: any[];
}

export interface SplitRowsFormProps extends ITableDataProps {
    onClose: (e: any) => void;
    onSubmit: (data: any[]) => Promise<void>;
    title: string;
    settings: ISplitRows;
    items: any[];
}

const SplitRowsForm: React.FC<SplitRowsFormProps> = ({
    table,
    onClose,
    onSubmit,
    title,
    settings,
    calculateRow,
    onChangeCellValue,
    onValidateExternalRowsData,
    onTableRowDeleted,
    onTableRowCopied,
    onFormRowEdited,
    cellRenderSwitcher,
    editCellRenderSwitcher,
    evalTableFormulaValue,
    onInitNewRow,
    onInitCopyRow,
    onChanged,
    getColumnWatches,
    getWatchesByFormula,
    getParentFields,
    setParentField,
    docId,
    getFormValuesAsync,
    getFiltersAsync,
    onSetFormDataNewRow,
}: SplitRowsFormProps) => {
    const [scheme, setScheme] = useState<IDocumentTable>();
    const [data, setData] = useState<any[]>();
    const [data1, setData1] = useState<any[]>();
    let fields: Record<string, IFieldElem> = {};
    const activated = React.useRef(false);

    const InitData = () => {
        let scheme = { key: 'splitRows', tableColumn: settings.columns.tableColumn } as IDocumentTable;
        setScheme(scheme);
        const arr = [] as any[];
        setData1(arr);
    };

    useEffect(() => {
        activated.current = true;
        InitData();
        return () => {
            activated.current = false;
        };
    }, []);

    const onApply = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (data) {
            await onSubmit(data);
        }
        onClose(e);
    };

    const modalHeader = (
        <div className="box">
            {title}
            <div className={clsx('left')}>
                <Button
                    startAdornment={<MdClose size="24" />}
                    buttonType="text"
                    textColor="neutral"
                    size="xs"
                    aria-label="Закрыть окно"
                    onClick={onClose}
                />
            </div>
        </div>
    );

    const modalFooter = (
        <div className={'modal-dlg-buttons'}>
            <Button onClick={onApply} buttonType="primary" size="s">
                Применить
            </Button>
            <Button onClick={onClose} buttonType="light" size="s">
                Отменить
            </Button>
        </div>
    );

    return (
        <Modal size="m" header={modalHeader} footer={modalFooter}>
            <div className="split-rows-form__wrapper">
                <div className="split-rows-form__content">
                    {scheme && (
                        <TableData
                            table={scheme}
                            value={data1}
                            getParentFields={getParentFields}
                            setParentField={setParentField}
                            onTableRowDeleted={onTableRowDeleted}
                            onTableRowCopied={onTableRowCopied}
                            onFormRowEdited={onFormRowEdited}
                            allowUpdating={true}
                            evalTableFormulaValue={evalTableFormulaValue}
                            calculateRow={calculateRow}
                            onInitNewRow={onInitNewRow}
                            onInitCopyRow={onInitCopyRow}
                            showScrollbar={false}
                            onChangeCellValue={onChangeCellValue}
                            cellRenderSwitcher={cellRenderSwitcher}
                            editCellRenderSwitcher={editCellRenderSwitcher}
                            getColumnWatches={getColumnWatches}
                            getWatchesByFormula={getWatchesByFormula}
                            onChanged={async (e, valid) => {
                                setData(e);
                            }}
                            name={'splitRows'}
                            fields={fields}
                        />
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default SplitRowsForm;
