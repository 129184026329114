import React, { useRef } from 'react';
import './DocumentTabLinks.scss';

import { TableTab } from '@models/document/IDocumentScheme';
import DocumentTabLinksTable from './DocumentTabLinksTable';
import { IFieldElem } from '@models/IFormData';
import { UseFormReturn } from 'react-hook-form';
import { IDocumentData } from '@models/document/IDocumentData';
import { DropdownContainerContext } from '@/context/Contexts';
import ScrollToTop from '@atoms/ScrollToTop/ScrollToTop';

export interface IDocumentTabLinksProps {
    /** ИД документа */
    docId: string;
    /** Таблицы */
    tables: TableTab[];

    formMethods: UseFormReturn<IDocumentData>;
    isEdit: boolean;
    isNew: boolean;
    fields: Record<string, IFieldElem>;
}

const DocumentTabLinks = ({ docId, tables, formMethods, isEdit, isNew, fields }: IDocumentTabLinksProps) => {
    const tabRef = useRef<HTMLDivElement>(null);
    return (
        <DropdownContainerContext value={tabRef}>
            <div className="links-tab" ref={tabRef}>
                <ScrollToTop scrollElementRef={tabRef} behavior={'smooth'} />
                {tables.map((element) => {
                    return (
                        <DocumentTabLinksTable
                            key={element.key}
                            linkTable={element}
                            formMethods={formMethods}
                            isEdit={isEdit}
                            isNew={isNew}
                            fields={fields}
                            docId={docId}
                        />
                    );
                })}
            </div>
        </DropdownContainerContext>
    );
};

export default DocumentTabLinks;
