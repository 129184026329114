import { UseFormReturn, useWatch } from 'react-hook-form';
import { IDocumentText } from '@models/Forms/IForms';
import { handlerFieldWatch } from '@utils/fieldWatchUtils';
import './Text.scss';
import { IFieldElem } from '@models/IFormData';
import { FormulaManager } from '@utils/managers/FormulaManager';
import React, { useEffect, useState } from 'react';
import FieldWrapper from '@molecules/formbuilder/controls/FieldWrapper/FieldWrapper';

export interface ITextProps {
    text?: IDocumentText;
    formMethods: UseFormReturn<any>;
    isEdit: boolean;
    isNew: boolean;
    fields: Record<string, IFieldElem>;
}

const Text = ({ text, formMethods, isEdit, isNew, fields, ...props }: ITextProps) => {
    const watchDataDisplayFormat = React.useRef<any[]>([]);
    const displayFormatMng = new FormulaManager(text?.value!);
    displayFormatMng.Init(fields, formMethods);
    const [displayVal, setDisplayVal] = useState<string>('');

    let visibilityRules = text?.visibilityRules;

    const visibilityMng = new FormulaManager(visibilityRules!);

    visibilityMng.Init(fields, formMethods);

    const activated = React.useRef(false);
    const [visibility, setVisibility] = useState<boolean>(false);

    const InitFormulas = async () => {
        let result = await visibilityMng.EvalFormulaValues(isEdit, isNew);
        if (activated.current) {
            setVisibility(result);
        }
        let values = displayFormatMng.GetDefaultValues(isEdit, isNew, true);
        let coll: any[] = [];
        values.forEach((x) => {
            if (x === null) {
                coll.push('');
            } else {
                coll.push(x);
            }
        });
        if (activated.current) {
            if (coll.length === 0 && text?.value) {
                setDisplayVal(text?.value);
            } else {
                let dataDisplay = await displayFormatMng.ReplaceFormulaValues(isEdit, isNew, coll, false);
                if (dataDisplay) {
                    dataDisplay = dataDisplay?.replace(/\'/g, '');
                    dataDisplay = dataDisplay?.replace(/\\n/g, '\n');
                    setDisplayVal(dataDisplay);
                }
            }
        }
    };

    useEffect(() => {
        activated.current = true;
        InitFormulas();
        return () => {
            activated.current = false;
        };
    }, []);

    const handlerDisplayVal = async (watch: any[]) => {
        let coll: any[] = [];
        watch.forEach((x) => {
            if (x === null) {
                coll.push('');
            } else {
                coll.push(x);
            }
        });

        if (activated.current) {
            if (coll.length === 0 && text?.value) {
                setDisplayVal(text?.value);
            } else {
                let dataDisplay = await displayFormatMng.ReplaceFormulaValues(isEdit, isNew, coll, false);
                if (dataDisplay) {
                    dataDisplay = dataDisplay?.replace(/\'/g, '');
                    dataDisplay = dataDisplay?.replace(/\\n/g, '\n');
                    setDisplayVal(dataDisplay);
                }
            }
        }
    };

    const watchDisplayFormat = useWatch({
        name: displayFormatMng.GetWatchFields(),
    });

    useEffect(() => {
        watchDataDisplayFormat.current = watchDisplayFormat;
        handlerDisplayVal(watchDisplayFormat);
    }, [watchDisplayFormat]);

    const watchVisibility = useWatch({
        name: visibilityMng.GetWatchFields(),
    });

    useEffect(() => {
        handlerFieldWatch(watchVisibility, isEdit, isNew, visibilityMng, setVisibility, activated);
    }, [watchVisibility, isEdit, isNew]);

    let style: React.CSSProperties = {};
    if (text?.style) {
        const json = JSON.parse(text?.style.replace(/\'/g, '"'));

        if (json.color) {
            style.color = json.color;
        }
        if (json.fontWeight) {
            style.fontWeight = json.fontWeight;
        }
        if (json.backgroundColor) {
            style.backgroundColor = json.backgroundColor;
        }
        if (json.borderColor) {
            style.borderColor = json.borderColor;
        }
    }

    return text && text.value && visibility ? (
        <div className="form-field" data-testid={text.id ? `text-${text.id}` : undefined}>
            <FieldWrapper inLineFormat={false} label={text.name} title={text.viewTitle}>
                <div
                    className="content-text"
                    style={style}
                    dangerouslySetInnerHTML={{ __html: displayVal?.toString() }}
                />
            </FieldWrapper>
        </div>
    ) : (
        <></>
    );
};

export default Text;
