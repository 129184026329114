import React from 'react';
import { ConfigService } from '@/configuration/services/configService';
import { SAML_LOGIN_TYPE } from '@/types/login';
import { RouteNames } from './router.types';

const Login = React.lazy(() => import('@pages/login/Login'));
const Dashboard = React.lazy(() => import('@pages/dashboard/Dashboard'));
const Documentation = React.lazy(() => import('@pages/documentation/Documentation'));
const Documents = React.lazy(() => import('@pages/documents/Documents'));
const Dictionaries = React.lazy(() => import('@pages/dictionaries/Dictionaries'));
const Reports = React.lazy(() => import('@pages/reports/Reports'));
const Document = React.lazy(() => import('@pages/document/Document'));
const Tasks = React.lazy(() => import('@pages/tasks/Tasks'));
const Search = React.lazy(() => import('@pages/search/Search'));
const Notification = React.lazy(() => import('@pages/notification/Notification'));
const Roles = React.lazy(() => import('@pages/roles/Roles'));
const ReleaseNotes = React.lazy(() => import('@pages/releaseNotes/ReleaseNotes'));
const SetAssistantSimple = React.lazy(() => import('@pages/setAssistants/SetAssistantSimple'));
const NotificationSettings = React.lazy(() => import('@pages/notificationSettings/NotificationSettings'));
const RedirectsAndReplacements = React.lazy(() => import('@pages/RedirectsAndReplacements/RedirectsAndReplacements'));
const SignUp = React.lazy(() => import('@pages/signUp/SignUp'));
const SignUpConfirm = React.lazy(() => import('@pages/signUpConfirm/SignUpConfirm'));
const ChangeEmailConfirm = React.lazy(() => import('@pages/changeEmailConfirm/ChangeEmailConfirm'));
const ResetPasswordRequest = React.lazy(() => import('@pages/resetPasswordRequest/ResetPasswordRequest'));
const ResetPasswordConfirm = React.lazy(() => import('@pages/resetPasswordConfirm/ResetPasswordConfirm'));
const Settings = React.lazy(() => import('@pages/settings/Settings'));
const UsersActivationPage = React.lazy(() => import('@pages/usersActivation/UsersActivationPage'));
const AdfsSamlSsoSigInRedirect = React.lazy(() => import('@pages/login/adfs/saml/SignInRedirect'));
const AdfsSamlSsoSigOutRedirect = React.lazy(() => import('@pages/login/adfs/saml/SignOutRedirect'));
const AdfsSamlSsoSignIn = React.lazy(() => import('@pages/login/adfs/saml/SignIn'));

export interface IRoute {
    path: string;
    component: React.ComponentType;
}

const config = ConfigService.get();

export const publicRoutes: IRoute[] = [{ path: RouteNames.LOGIN, component: Login }];

if (config.application.selfRegistration.restorePasswordEnabled) {
    publicRoutes.push({ path: RouteNames.RESET_PASSWORD_REQUEST, component: ResetPasswordRequest });
    publicRoutes.push({ path: RouteNames.RESET_PASSWORD_CONFIRM, component: ResetPasswordConfirm });
}
if (config.application.selfRegistration.signUpEnabled) {
    publicRoutes.push({ path: RouteNames.SIGN_UP, component: SignUp });
    publicRoutes.push({ path: RouteNames.SIGN_UP_CONFIRM, component: SignUpConfirm });
    publicRoutes.push({ path: RouteNames.CHANGE_EMAIL_CONFIRM, component: ChangeEmailConfirm });
}

export const privateRoutes: IRoute[] = [
    { path: RouteNames.DOCUMENTATION, component: Documentation },
    { path: RouteNames.DASHBOARD, component: Dashboard },
    { path: RouteNames.DOCUMENTS, component: Documents },
    { path: RouteNames.DICTIONARIES, component: Dictionaries },
    { path: RouteNames.REPORTS, component: Reports },
    { path: RouteNames.DOCUMENT, component: Document },
    { path: RouteNames.DOCUMENT_CREATE, component: Document },
    { path: RouteNames.DOCUMENT_CREATE_ON_BASE, component: Document },
    { path: RouteNames.DOCUMENT_CREATE_ON_BASE_TEMPLATE, component: Document },
    { path: RouteNames.DOCUMENT_CREATE_BY_ACTION, component: Document },
    { path: RouteNames.DOCUMENT_CREATE_BY_ACTION_TEMPLATE, component: Document },
    { path: RouteNames.TASKS, component: Tasks },
    { path: RouteNames.SEARCH, component: Search },
    { path: RouteNames.NOTIFICATION, component: Notification },
    { path: RouteNames.ROLES, component: Roles },
    { path: RouteNames.REDIRECTS_AND_REPLACEMENTS, component: RedirectsAndReplacements },
    { path: RouteNames.NOTIFICATIONSETTINGS, component: NotificationSettings },
    { path: RouteNames.SET_ASSISTANT_SIMPLE, component: SetAssistantSimple },
    { path: RouteNames.RELEASENOTES, component: ReleaseNotes },
];

if (config.application.selfRegistration.signUpEnabled) {
    privateRoutes.push({ path: RouteNames.SETTINGS, component: Settings });
    privateRoutes.push({ path: RouteNames.USERS_ACTIVATION, component: UsersActivationPage });
    privateRoutes.push({ path: RouteNames.CHANGE_EMAIL_CONFIRM, component: ChangeEmailConfirm });
}

if (config.application.authentication.controls.enabled.includes(SAML_LOGIN_TYPE)) {
    //
    publicRoutes.push({ path: RouteNames.ADFS_SAML_SSO_SIGN_IN_REDIRECT, component: AdfsSamlSsoSigInRedirect });
    publicRoutes.push({ path: RouteNames.ADFS_SAML_SSO_SIGN_IN, component: AdfsSamlSsoSignIn });
    //
    privateRoutes.push({ path: RouteNames.ADFS_SAML_SSO_SIGN_OUT_REDIRECT, component: AdfsSamlSsoSigOutRedirect });
}
