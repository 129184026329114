import React, { FC, useState } from 'react';
import { MdClose } from 'react-icons/md';
import clsx from 'clsx';
import Button from '@atoms/Button';
import Modal from '@atoms/Modal';
import Preloader from '@atoms/Preloader';

import './MessageDialog.scss';
import Hint from '@atoms/Hint';
import { ModalSize } from '@/types/ModalSize';
import { ButtonType } from '@atoms/Button/Button';

export interface IMessageDialogProps {
    header: string;
    message: string;
    size?: ModalSize;
    showOkButton: boolean;
    showCancelButton: boolean;
    okButtonText?: string;
    cancelButtonText?: string;
    okButtonType?: ButtonType;
    cancelButtonType?: ButtonType;
    okClick: () => Promise<void>;
    cancelClick: () => void;
    isBusy?: boolean;
    errorText?: string;
    successText?: string;
}

const MessageDialog: FC<IMessageDialogProps> = ({
    size = 'm',
    okButtonText = 'ОК',
    cancelButtonText = 'Отмена',
    okButtonType = 'primary',
    cancelButtonType = 'light',
    ...props
}: IMessageDialogProps) => {
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState<boolean>(false);

    return (
        <Modal
            className={clsx('modal-dlg-container')}
            size={size}
            header={
                <div className={clsx('box')}>
                    <div>
                        <span>{props.header}</span>
                    </div>
                    <div className={clsx('left')}>
                        <Button
                            buttonType="text"
                            textColor="neutral"
                            size="xs"
                            aria-label="Закрыть окно"
                            onClick={() => {
                                if (props?.cancelClick) props?.cancelClick();
                            }}
                            startAdornment={<MdClose size="24" />}
                        />
                    </div>
                </div>
            }
            footer={
                <div className="modal-dlg-buttons">
                    {props.showOkButton && (
                        <Button
                            size="s"
                            buttonType={okButtonType}
                            aria-label={okButtonText}
                            onClick={() => {
                                setSubmitButtonDisabled(true);
                                props?.okClick().finally(() => {
                                    setSubmitButtonDisabled(false);
                                });
                            }}
                            disabled={submitButtonDisabled}
                        >
                            {okButtonText}
                        </Button>
                    )}
                    {props.showCancelButton && (
                        <Button
                            buttonType={cancelButtonType}
                            size="s"
                            aria-label={cancelButtonText}
                            onClick={() => {
                                if (props?.cancelClick) props?.cancelClick();
                            }}
                        >
                            {cancelButtonText}
                        </Button>
                    )}
                </div>
            }
        >
            {props.isBusy ? (
                <Preloader size="m" position="static" />
            ) : (
                <div>
                    {props.errorText && (
                        <Hint icon="info" title={`Ошибка: ${props.errorText}`} variant="red" maxWidth="100%" />
                    )}
                    {props.successText && (
                        <Hint icon="info" title={`${props.successText}`} variant="green" maxWidth="100%" />
                    )}
                    <div className="modal-dlg-text" dangerouslySetInnerHTML={{ __html: props.message }} />
                </div>
            )}
        </Modal>
    );
};

export default MessageDialog;
