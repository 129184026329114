import React, { useEffect, useState } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { TableTab } from '@models/document/IDocumentScheme';
import { IFieldElem } from '@models/IFormData';
import { handlerFieldWatch } from '@utils/fieldWatchUtils';
import { FormulaManager } from '@utils/managers/FormulaManager';
import Box from '@atoms/Box';
import './DocumentTabLinksTable.scss';
import DocumentLinksTable from '@organisms/document/DocumentTabBuilder/tabs/DocumentTabLinks/DocumentLinksTable';

export interface IDocumentTabLinksTableProps<TFieldValues extends object = object> {
    linkTable?: TableTab;
    formMethods: UseFormReturn<TFieldValues>;
    isEdit: boolean;
    isNew: boolean;
    fields: Record<string, IFieldElem>;
    docId: string;
}

const DocumentTabLinksTable = <TFieldValues extends object = object>({
    linkTable,
    formMethods,
    isEdit,
    isNew,
    fields,
    docId,
}: IDocumentTabLinksTableProps<TFieldValues>) => {
    let rules = linkTable?.visibilityRules;
    const visibilityMng = new FormulaManager(rules!);
    visibilityMng.Init(fields, formMethods);
    const activated = React.useRef(false);
    const [visibility, setVisibility] = useState<boolean>(false);
    const InitFormulas = async () => {
        let result = await visibilityMng.EvalFormulaValues(isEdit, isNew);
        if (activated.current) {
            setVisibility(result);
        }
    };
    useEffect(() => {
        activated.current = true;
        InitFormulas();
        return () => {
            activated.current = false;
        };
    }, []);
    const watch = useWatch({
        name: visibilityMng.GetWatchFields(),
    });

    useEffect(() => {
        handlerFieldWatch(watch, isEdit, isNew, visibilityMng, setVisibility, activated);
    }, [watch, isEdit, isNew]);

    return linkTable && visibility ? (
        <Box header={linkTable.name} key={`box_${linkTable.key}`}>
            <DocumentLinksTable linkTable={linkTable} docId={docId} />
        </Box>
    ) : (
        <></>
    );
};

export default DocumentTabLinksTable;
