import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { TableTab } from '@models/document/IDocumentScheme';
import { AttachActionsService } from '@services/actions/AttachActionsService';
import { DataGridStateService } from '@services/DataGridStateService';
import { DocumentAttachService } from '@services/DocumentAttachService';
import { FilesService } from '@services/FilesService';
import DevExpressTreeListDynamic from '@atoms/DevExpress/TreeList/DevExpressTreeListDynamic';
import './DocumentAttachTable.scss';
import { API_URL } from '@/http/index';

export interface IDocumentTabAttachTableProps<TFieldValues extends object = object> {
    id?: string;
    attachTable: TableTab;
    docId: string;
}

const DocumentTabAttachTable = <TFieldValues extends object = object>({
    id = undefined,
    attachTable,
    docId,
}: IDocumentTabAttachTableProps<TFieldValues>) => {
    const serviceGrid = new DocumentAttachService(docId);
    const stateService = new DataGridStateService();
    const actionsService = new AttachActionsService(docId, '-1');
    const navigate = useNavigate();
    const activated = React.useRef(false);

    useEffect(() => {
        activated.current = true;
        return () => {
            activated.current = false;
        };
    }, []);

    const onRowClick = (e: any) => {
        if (e.rowType === 'data') {
            if (e.data.key) {
                navigate('/document/' + e.data.key);
            }
        }
    };
    return (
        <div className="attach-table" data-testid={id ? `attach-table-${id}` : undefined}>
            <DevExpressTreeListDynamic
                //key={`tree_${attachTable.key}`}
                stateService={stateService}
                dataKey={attachTable.key}
                gridKey={attachTable.key}
                onRowClick={onRowClick}
                service={serviceGrid}
                actionService={actionsService}
                externalLinkDownload={`${API_URL}/attachment/download/{documentKey}/{key}?access_token=${localStorage.getItem(
                    'frontAccessToken',
                )}`}
                externalLinkPreview={`${API_URL}/attachment/preview/{documentKey}/{key}?access_token=${localStorage.getItem(
                    'frontAccessToken',
                )}`}
                showFilePreviewButton={true}
            />
        </div>
    );
};

export default DocumentTabAttachTable;
