import React from 'react';
import { IActivityAction, IBaseAction } from '@models/actions/IBaseAction';
import { ModalSize } from '@/types/ModalSize';
import { ActivityBaseActionExecutor } from '@organisms/actions/ActivityBaseActionExecutor';
import { IActivityBatchEditingTableProps } from './IActivityBatchEditingTableProps';
import { getActivityIdFromCompositeId } from '@utils/helpers';
import { ActivityBatchEditingTableModal } from './ActivityBatchEditingTableModal';

export class ActivityBatchEditingTableExecutor extends ActivityBaseActionExecutor {
    private _modalProps?: IActivityBatchEditingTableProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        let act = action as IActivityAction;
        this._modalProps = {
            displayName: action.displayName,
            actionKey: action.key ?? '',
            actId: getActivityIdFromCompositeId(objId),
            docId: act.docId,
            title: action.options?.title ?? '',
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'max',
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <ActivityBatchEditingTableModal {...this._modalProps} /> : <div />;
    };
}
