import { IFieldElem } from '@models/IFormData';
import { containsValue, getValueString } from '@utils/documentUtils';
import Chip from '@atoms/Chip';
import React, { FC } from 'react';
import './DocumentModalTitle.scss';
import clsx from 'clsx';

export interface IDocumentModalTitleProps {
    titleText: string | undefined;
    fieldsData: Record<string, IFieldElem>;
}

const DocumentModalTitle: FC<IDocumentModalTitleProps> = ({ titleText, fieldsData }: IDocumentModalTitleProps) => {
    return (
        <div className="doc-modal-header">
            <div className={clsx('doc-modal-header-el', 'doc-modal-header-txt')}>{titleText}</div>
            {containsValue('|Document|Статус', fieldsData!) && getValueString('|Document|Статус', fieldsData!) && (
                <div className={clsx('doc-modal-header-el', 'doc-modal-header-status')}>
                    <Chip type="fill" size="xs" isSelectableText={true}>
                        {getValueString('|Document|Статус', fieldsData!)}
                    </Chip>
                </div>
            )}
        </div>
    );
};

export default DocumentModalTitle;
