import './ApproveListTable.scss';
import React, { useEffect, useState } from 'react';
import { IDocumentTable } from '@models/Forms/IForms';
import ApproveListCycle from './ApproveListCycle';
import { IField } from '@models/IFormData';
import { ICycle, ILevel } from './IApproveListModels';

export interface IApproveListTableProps {
    value?: any[];
    table: IDocumentTable;
    cellRenderSwitcher: (p: any, column: any, rowParent?: any) => Promise<React.ReactNode>;
    getParentFields: () => IField[];
    docId?: string;
}

const ApproveListTable: React.FC<IApproveListTableProps> = ({
    value,
    table,
    cellRenderSwitcher,
    getParentFields,
    docId,
}: IApproveListTableProps) => {
    const [cycles, setCycles] = useState<ICycle[]>([]);

    useEffect(() => {
        setCycles(getCycles(value!).reverse());
    }, []);

    const getCycles = (value: any[]) => {
        const acceptions: any[] = [];
        const xt: any[] = [];
        value?.forEach((item) => {
            xt.push(item['|Этапность'] ? item['|Этапность'] : 1);
        });
        const cycle = Math.max.apply(Math, xt);

        value?.forEach((row: any, index) => {
            //Обработчик УВХД согласования
            let objCopy = { ...row };
            objCopy['|Цикл'] = 'Цикл №' + row['|Этапность'];
            objCopy['|Этапность'] = 'Этап № ' + row['|Этап_по_карте'];
            objCopy['|Родитель'] = row['|Родитель'] == '' ? '-1' : row['|Родитель'];

            acceptions.push(objCopy);
        });

        const cycles: ICycle[] = [];
        for (let i = 1; i <= cycle; i++) {
            let title = 'Цикл №' + i;
            const levels: ILevel[] = [];
            let j = 1;

            let cyclesItems = acceptions.filter((e) => e['|Цикл'] === title);
            if (cyclesItems.length == 0) {
                let levelElement = {
                    id: j,
                    title: 'Нет согласований по циклу',
                } as ILevel;
                levels.push(levelElement);
                j++;
            } else {
                let groupByLevel = cyclesItems.reduce((group, item) => {
                    const level = item['|Этапность'];
                    (group as any)[level] = (group as any)[level] ?? [];
                    (group as any)[level].push(item);
                    return group;
                }, {});

                Object.keys(groupByLevel).forEach((titleLevel, index) => {
                    levels.push({
                        id: j,
                        title: titleLevel,
                        items: (groupByLevel as any)[titleLevel],
                    } as ILevel);
                    j++;
                });
            }

            cycles.push({
                id: i,
                title: title,
                levels: levels,
            } as ICycle);
        }
        return cycles;
    };

    return cycles ? (
        <div className="form-approveListTable" data-testid={table.id ? `table-approve-list-${table.id}` : undefined}>
            {cycles.map((cycle, index) => {
                return (
                    <ApproveListCycle
                        cycle={cycle}
                        table={table}
                        index={index}
                        count={cycles.length}
                        key={index}
                        cellRenderSwitcher={cellRenderSwitcher}
                        getParentFields={getParentFields}
                        docId={docId}
                    />
                );
            })}
        </div>
    ) : (
        <></>
    );
};

export default ApproveListTable;
