import { IBaseAction } from '@models/actions/IBaseAction';
import { DocBaseActionExecutor } from '@organisms/actions/DocBaseActionExecutor';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import { ResultDocumentActionType } from '@models/document/actionsExecution/ICreateDocByTemplateModel';
import { FilesService } from '@services/FilesService';

export class CreateDocByTemplateExecutor extends DocBaseActionExecutor {
    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
    ) => {
        let service = new DocumentExecutionService(objId);
        service.getCreateDocByTemplateModel(action.key).then((res) => {
            if (res.data.result === ResultDocumentActionType.Download) {
                FilesService.downloadFile(`documentExecution/downloadTemplateDoc/${objId}/${action.key}`);
                completeHandler && completeHandler(false);
            } else {
                service.createDocByTemplate(action.key).then(() => {
                    completeHandler && completeHandler(true);
                });
            }
        });
    };
}
