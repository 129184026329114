import React from 'react';
import { IBaseAction } from '@models/actions/IBaseAction';
import { DocBaseActionExecutor } from '@organisms/actions/DocBaseActionExecutor';
import { ModalSize } from '@/types/ModalSize';
import { IAttachFilesExecutorProps } from './IAttachFilesExecutorProps';
import { AttachFilesExecutorModal } from './AttachFilesExecutorModal';

export class AttachFilesExecutor extends DocBaseActionExecutor {
    private _modalProps?: IAttachFilesExecutorProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            docId: objId ?? '',
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'xl',
            action: action,
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <AttachFilesExecutorModal {...this._modalProps} /> : <div />;
    };
}
