import React, { FC, FocusEventHandler, ReactNode, useEffect, useRef } from 'react';
import { IOption } from '@/types';
import clsx from 'clsx';

interface IMenuItemProps {
    children?: ReactNode | undefined;
    autoFocus: boolean;
    option: IOption;
    active: boolean;
    onFocus?: FocusEventHandler<HTMLElement>;
}

const MenuItem: FC<IMenuItemProps> = ({ children, autoFocus, option, active, onFocus }) => {
    const ref = useRef<HTMLLIElement>(null);

    useEffect(() => {
        if (autoFocus) {
            ref.current?.focus({ preventScroll: true });
        }
    }, [autoFocus]);

    return (
        <li
            ref={ref}
            tabIndex={0}
            data-testid={`rf-select__list-element-${option.value}`}
            className={clsx(
                {
                    'rf-select__list-element': true,
                    'rf-select__list-element--disabled': option.disabled,
                    'rf-select__list-element--active': active,
                },
                option.cssClass,
            )}
            onFocus={onFocus}
            aria-disabled={option.disabled ? 'true' : undefined}
        >
            {children}
        </li>
    );
};

export default MenuItem;
