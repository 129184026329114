import React from 'react';
import { IBaseAction } from '@models/actions/IBaseAction';
import { IActionExecutor } from '@organisms/actions/IActionExecutor';
import { ModalSize } from '@/types/ModalSize';
import { IViewAttachSignProps } from './IViewAttachSignProps';
import { ViewAttachSignModal } from './ViewAttachSignModal';

export class ViewSignExecutor implements IActionExecutor {
    private _modalProps?: IViewAttachSignProps;

    run = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        raiseVisualElement?: () => void,
        modalSize?: ModalSize | null,
    ) => {
        let attachId: number = Number(objId);

        this._modalProps = {
            docId: parentId ?? '',
            attachId: attachId,
            rowData: rowData,
            options: action.options,
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'xl',
            completeHandler: completeHandler,
        };
    };

    visualElement = () => {
        return this._modalProps ? <ViewAttachSignModal {...this._modalProps} /> : <div />;
    };
}
