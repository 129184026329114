import React, { useEffect, useState } from 'react';
import DueTimeModeTypeOption, { DueTimeModeType } from '@/types/DueTimeType';
import { RouteMapExecutionService } from '@services/actions/RouteMapExecutionService';
import { getABookFormValues } from '@utils/abookUtils';
import { IRouteMapActionResult } from '@models/document/routeMapActionsExecutors/IRouteMapActionModel';
import DurationSpanTypeOption from '@/types/DurationSpanType';
import { useForm } from 'react-hook-form';
import { IOption } from '@/types';
import ModalContainer, { IModalContainerProps } from '@molecules/ModalContainer/ModalContainer';
import AbookpickerControl from '@controls/AbookpickerControl';
import { IAddressBookData } from '@models/addressbook/IAddressBookData';
import SelectControl from '@controls/SelectControl';
import InputNumberControl from '@controls/InputNumberControl';
import DatepickerControl from '@controls/DatepickerControl';
import TextareaControl from '@controls/TextareaControl';
import { IEditRouteMapFormData } from './IEditRouteMapFormData';
import { IEditRouteMapProps } from './IEditRouteMapProps';

export const EditRouteMapModal: React.FC<IEditRouteMapProps> = (props: IEditRouteMapProps) => {
    const [errorText, setErrorText] = useState<string>();
    const [loading, setLoading] = useState<boolean>();
    const [periodType, setPeriodType] = useState<DueTimeModeType>(
        DueTimeModeType[props.dueTimeMode as keyof typeof DueTimeModeType],
    );
    const [executorDisplayValue, setExecutorDisplayValue] = useState<string>(props.executorDisplayName);
    const [formValues, setFormValues] = useState<string>('{}');
    const activated = React.useRef(false);

    const serv = new RouteMapExecutionService();

    const getFormValues = async () => {
        const vals = await getABookFormValues(props.documentKey, props.abookOptions?.formValues);
        if (activated.current && vals) {
            setFormValues(vals);
        }
    };

    useEffect(() => {
        activated.current = true;
        getFormValues();
        return () => {
            activated.current = false;
        };
    }, []);

    const onSubmit = (form: IEditRouteMapFormData) => {
        if (!form?.executors) {
            setErrorText('Не указаны исполнители');
            return;
        }

        if (!form?.tasktext) {
            setErrorText('Не указан текст задачи');
            return;
        }

        setLoading(true);

        const result = {
            levelKey: props.levelKey,
            nextlevelKey: props.nextlevelKey,
            stepName: '',
            executorsIds: form.executors.split('|').map((n) => parseInt(n, 10)),
            dueTimeMode: (form.periodType[0] ?? DueTimeModeTypeOption[0]).value,
            transitionType: '',
            activityKey: props.activityKey,
            tasktext: form.tasktext,
        } as IRouteMapActionResult;

        if (periodType == DueTimeModeType.Date) {
            if (!form?.periodDate) {
                setErrorText('Не указан срок исполнения');
                return;
            }

            result.periodDate = form.periodDate;
        }

        if (periodType == DueTimeModeType.TimeSpan) {
            result.termType = (form.termType[0] ?? DurationSpanTypeOption[0]).value;
            result.termCount = form.termCount ?? 1;
        }

        return serv
            .edit(props.actionKey, props.activityKey, props.processKey, props.documentKey, result)
            .then((res) => {
                setLoading(false);

                props.completeHandler && props.completeHandler(true);
            })
            .catch((err) => {
                setErrorText(err);
            })
            .finally(() => setLoading(false));
    };

    const formState = useForm<IEditRouteMapFormData>({
        mode: 'onBlur', // "onChange"
    });

    useEffect(() => {
        const periodType = [
            DueTimeModeTypeOption.filter((x) => x.value.toLowerCase() == props.dueTimeMode.toLowerCase())[0] ??
                DueTimeModeTypeOption[0],
        ];
        const termType = [
            DurationSpanTypeOption.filter((x) => x.value.toLowerCase() == props.termType.toLowerCase())[0] ??
                DurationSpanTypeOption[0],
        ];

        formState.reset({
            executors: props.executorId,
            periodType: periodType,
            termCount: props.termCount,
            termType: termType,
            periodDate: props.periodDate,
            tasktext: props.tasktext,
        } as IEditRouteMapFormData);
    }, []);

    const onPeriodTypeChange = (option: IOption[]) => {
        if (option.length == 0) return;
        let type = DueTimeModeType[option[0].value as keyof typeof DueTimeModeType];
        setPeriodType(type);
    };

    const modalContainerProps: IModalContainerProps = {
        header: 'Редактирование этапа',
        size: props.modalSize,
        showOkButton: true,
        showCancelButton: true,
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
        errorText: errorText,
        formMethods: formState,
        onSubmit: onSubmit,
        okButtonDisabled: false,
    };

    return (
        <ModalContainer {...modalContainerProps}>
            <AbookpickerControl
                label="Исполнитель"
                placeholder="Выберите исполнителя"
                isMultiple={false}
                title="Исполнитель"
                displayValue={executorDisplayValue!}
                formValues={formValues}
                name="executors"
                tabsSettings={props.abookOptions?.tabs}
                externalSearch={props.abookOptions?.externalSearch}
                required={true}
                rules={{ required: 'Обязательное поле' }}
                formState={formState.formState}
                control={formState.control}
                showChips={false}
                onSetValues={(value: IAddressBookData[]) => {
                    setExecutorDisplayValue(value?.map((x) => x.fullName)?.join(','));
                }}
            />

            <SelectControl
                name="periodType"
                label="Срок исполнения"
                multiselect={false}
                readOnly
                required={true}
                rules={{ required: 'Обязательное поле' }}
                placeholder="Укажите срок исполнения"
                options={DueTimeModeTypeOption}
                preloader={loading}
                formState={formState.formState}
                control={formState.control}
                onChangeVal={onPeriodTypeChange}
            />

            {periodType == DueTimeModeType.TimeSpan && (
                <>
                    <InputNumberControl
                        name="termCount"
                        defaultValue={props.termCount}
                        min={1}
                        max={9999}
                        floatPoints={0}
                        required={true}
                        rules={{ required: 'Обязательное поле' }}
                        formState={formState.formState}
                        control={formState.control}
                    />

                    <SelectControl
                        name="termType"
                        multiselect={false}
                        readOnly
                        required={true}
                        rules={{ required: 'Обязательное поле' }}
                        options={DurationSpanTypeOption}
                        preloader={loading}
                        formState={formState.formState}
                        control={formState.control}
                        onChangeVal={() => {}}
                    />
                </>
            )}

            {periodType == DueTimeModeType.Date && (
                <DatepickerControl
                    name="periodDate"
                    required={true}
                    rules={{ required: 'Обязательное поле' }}
                    formState={formState.formState}
                    control={formState.control}
                />
            )}

            <TextareaControl
                label="Текст задачи"
                readOnly={false}
                required={true}
                rules={{ required: 'Обязательное поле' }}
                name="tasktext"
                formState={formState.formState}
                control={formState.control}
                onValueChange={(val: any) => {}}
            />
        </ModalContainer>
    );
};
