import React, { useImperativeHandle, useState } from 'react';
import { MdContentCopy } from 'react-icons/md';
import Button from '@atoms/Button';
import './SplitRowsButton.scss';
import DataSource from 'devextreme/data/data_source';
import { IDocumentTable, ISplitRows } from '@models/Forms/IForms';
import SplitRowsForm from './ModalForms/SplitRowsForm';
import { ITableDataProps } from './ITableDataProps';

export interface ISplitRowsButtonProps extends ITableDataProps {
    refButton?: React.Ref<ISplitRowButtonRefActions>;
    table: IDocumentTable;
    settings: ISplitRows;
    ds: DataSource;
    onSplit: (rowData: any) => Promise<void>;
    docId?: string;
}

export interface ISplitRowButtonRefActions {
    toggleEnable: (value: boolean) => void;
}

const SplitRowsButton: React.FC<ISplitRowsButtonProps> = (props: ISplitRowsButtonProps) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [enable, setEnable] = useState<boolean>(false);

    useImperativeHandle(props.refButton, () => ({
        toggleEnable: (value: boolean) => {
            setEnable(value);
        },
    }));
    const onClick = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setShowModal(!showModal);
    };

    return (
        <>
            <Button
                buttonType="secondary"
                textColor="neutral"
                size="s"
                disabled={!enable}
                aria-label="Тиражирование"
                startAdornment={<MdContentCopy size="20" />}
                onClick={onClick}
            >
                Тиражирование
            </Button>
            {showModal && (
                <SplitRowsForm
                    items={[]}
                    {...props}
                    onClose={onClick}
                    onSubmit={props.onSplit}
                    title={'Тиражирование'}
                />
            )}
        </>
    );
};

export default SplitRowsButton;
