import { ActFinishType } from '@/types/ActFinishType';
import { BaseWithSignExecutor } from '../BaseWithSign/BaseWithSignExecutor';

export class RejectWithSignExecutor extends BaseWithSignExecutor {
    constructor() {
        super();

        this.approveModel = {
            commentIsRequired: true,
            actType: ActFinishType.Refuse,
        };
    }
}
