import React from 'react';
import { IBaseAction } from '@models/actions/IBaseAction';
import { ActivityBaseActionExecutor } from '@organisms/actions/ActivityBaseActionExecutor';
import { getActivityIdFromCompositeId } from '@utils/helpers';
import { ModalSize } from '@/types/ModalSize';
import { IRollbackPerformerProps } from './IRollbackPerformerProps';
import { RollbackPerformerModal } from './RollbackPerformerModal';

export class RollbackPerformerExecutor extends ActivityBaseActionExecutor {
    private _modalProps?: IRollbackPerformerProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            activityId: getActivityIdFromCompositeId(objId),
            actionKey: action.key,
            title: action.options?.title ?? '',
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'm',
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <RollbackPerformerModal {...this._modalProps} /> : <></>;
    };
}
