import './DeleteDocumentExecutor.scss';
import React, { useState } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router';
import MessageDialog, { IMessageDialogProps } from '@molecules/MessageDialog/MessageDialog';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import { RouteNames } from '@/router/router.types';
import { IDeleteDocumentExecutorProps } from './IDeleteDocumentExecutorProps';

export const DeleteDocumentExecutorModal: React.FC<IDeleteDocumentExecutorProps> = (
    props: IDeleteDocumentExecutorProps,
) => {
    const [errorText, setErrorText] = useState<string>();
    const navigate = useNavigate();
    const location = useLocation();

    const messageBoxProp: IMessageDialogProps = {
        header: props.title,
        message: props.message,
        showOkButton: true,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        size: props.modalSize,
        okClick: () => {
            let service = new DocumentExecutionService(props.docId);
            return service
                .deleteDocument(props.actionKey)
                .then(() => {
                    if (matchPath(RouteNames.DOCUMENT, location.pathname)) {
                        navigate(RouteNames.DASHBOARD);
                    } else {
                        props.completeHandler && props.completeHandler(true);
                    }
                })
                .catch((error) => setErrorText(error));
        },
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
    };

    return <MessageDialog {...messageBoxProp} errorText={errorText} />;
};
