import React from 'react';
import { IBaseAction } from '@models/actions/IBaseAction';
import { DocBaseActionExecutor } from '@organisms/actions/DocBaseActionExecutor';
import { ModalSize } from '@/types/ModalSize';
import { IVoteProps } from './IVoteProps';
import { VoteQuestionExecutorModal } from './VoteQuestionExecutorModal';

export class VoteQuestionExecutor extends DocBaseActionExecutor {
    private _modalProps?: IVoteProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            docId: objId ?? '',
            action: action,
            userType: action.options?.userType ?? 'secretary',
            participantVote: action.options?.participantVote,
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'xl',
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <VoteQuestionExecutorModal {...this._modalProps} /> : <div />;
    };
}
