import { IBaseAction } from '@models/actions/IBaseAction';
import { IActionExecutor } from './IActionExecutor';
import { AttachActionTypes } from '@models/actions/AttachActionTypes';
import { DownloadExecutor } from './attachExecutors/Download/DownloadExecutor';
import { DeleteExecutor } from './attachExecutors/Delete/DeleteExecutor';
import { CreateAttachVersionExecutor } from './attachExecutors/CreateAttachVersion/CreateAttachVersionExecutor';
import { EditExecutor } from './attachExecutors/Edit/EditExecutor';
import { ViewSignExecutor } from './attachExecutors/ViewSign/ViewSignExecutor';

export class AttachActionsExecFactory {
    public static allExecutors: { [actType: string]: () => IActionExecutor } = {
        [AttachActionTypes[AttachActionTypes.Download]]: () => new DownloadExecutor(),
        [AttachActionTypes[AttachActionTypes.Delete]]: () => new DeleteExecutor(),
        [AttachActionTypes[AttachActionTypes.CreateVersion]]: () => new CreateAttachVersionExecutor(),
        [AttachActionTypes[AttachActionTypes.Edit]]: () => new EditExecutor(),
        [AttachActionTypes[AttachActionTypes.ViewSign]]: () => new ViewSignExecutor(),
    };

    static GetActionExecutor(act: IBaseAction, throwError: boolean = true): IActionExecutor | undefined {
        let createExecutor = this.allExecutors[act.type];
        if (!createExecutor) {
            if (!throwError) return undefined;

            throw new Error(`Не обнаружен ActionExecutor для типа ${act.type}`);
        }

        return createExecutor();
    }
}
