import { NotificationSettingsService } from '@services/NotificationSettingsService';
import { NotificationSettingsSlice } from '@/store/reducers/NotificationSettingsReducer';
import { AppDispatch } from '@/store/index';
import { INotificationRequest } from '@models/notificationSettings/INotificationSettings';

export const NotificationSettingsCreator = {
    fetchNotificationSettings: () => async (dispatch: AppDispatch) => {
        try {
            const response = await NotificationSettingsService.fetch();
            dispatch(NotificationSettingsSlice.actions.successLoadData(response.data));
        } catch (e) {
            console.log(e);
            let error = e as Error;
            dispatch(NotificationSettingsSlice.actions.setError(error.message));
        } finally {
            dispatch(NotificationSettingsSlice.actions.setIsLoading(false));
        }
    },

    editNotificationSettings: (request: INotificationRequest) => async (dispatch: AppDispatch) => {
        try {
            await NotificationSettingsService.edit(request);
        } catch (e) {
            console.log(e);
            let error = e as Error;
            dispatch(NotificationSettingsSlice.actions.setError(error.message));
        } finally {
            dispatch(NotificationSettingsSlice.actions.setIsLoading(false));
        }
    },

    setDocumentIsLoading: (flag: boolean) => async (dispatch: AppDispatch) => {
        dispatch(NotificationSettingsSlice.actions.setIsLoading(flag));
    },
};
