import React, { FC, useEffect, useState } from 'react';
import StatusBar, { IStatusBarItem, IStatusBarParentItem } from '@atoms/StatusBar/StatusBar';
import { IDocumentStatusBar } from '@models/document/IDocumentStatusBar';

export interface IDocumentStatusBarTabProps {
    /** ИД документа */
    docId: string;
    statusBar?: IDocumentStatusBar;
}

const DocumentStatusBarTab: FC<IDocumentStatusBarTabProps> = ({ docId, statusBar }: IDocumentStatusBarTabProps) => {
    const [statusBarItems, setStatusBarItems] = useState<IStatusBarParentItem[]>();

    useEffect(() => {
        let items: IStatusBarParentItem[] = [];
        statusBar?.levels?.forEach((level, index) => {
            let children: IStatusBarItem[] = [];
            level.childs.forEach((item, index) => {
                children.push({
                    title: item.title,
                    status: item.status,
                });
            });
            items.push({
                title: level.title,
                status: level.status,
                children: children,
            });
        });
        setStatusBarItems(items);
    }, [statusBar]);

    return statusBarItems ? <StatusBar items={statusBarItems} /> : <></>;
};

export default DocumentStatusBarTab;
