import { IAuthResponse } from '@models/response/IAuthResponse';
import { AxiosInstance, AxiosResponse } from 'axios';
import { ConfigService } from '@/configuration/services/configService';
import $api from '@/http';

export const samlApiUrl: string = ConfigService.get().application.authentication.saml.apiUrl;
export const samlForceAuthn: boolean = ConfigService.get().application.authentication.saml.forceAuthn;

export class SamlAuthService {
    static getSender(): AxiosInstance {
        return $api;
    }

    static async getSignInRedirectLocationAsync(): Promise<string> {
        let response = await this.getSender().post<void>('/Account/saml/signin-request', null, {
            params: {
                ForceAuthn: samlForceAuthn,
            },
        });
        if (response.status === 200) {
            let location = response.headers['location'];
            if (location) {
                return Promise.resolve(location);
            }
        }
        return Promise.reject();
    }

    static async getSignOutRedirectLocationAsync(): Promise<string> {
        let response = await this.getSender().post<void>('/Account/saml/signout-request');
        if (response.status === 200) {
            let location = response.headers['location'];
            if (location) {
                return Promise.resolve(location);
            }
        }
        return Promise.reject();
    }

    static async loginAsync(): Promise<AxiosResponse<IAuthResponse>> {
        return this.getSender().post<IAuthResponse>('/Account/saml/signin');
    }
}
