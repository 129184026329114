export const getLoadOptionsParameters = (options: any) => {
    let params: string[] = [];
    [
        'skip',
        'take',
        'requireTotalCount',
        'requireGroupCount',
        'sort',
        'filter',
        'totalSummary',
        'group',
        'groupSummary',
    ].forEach((i) => {
        if (i in options && options[i] !== undefined && options[i] !== null && options[i] !== '') {
            params.push(`${i}=${JSON.stringify(options[i]).replaceAll('+', '%2b')}`);
        }
    });
    return params;
};

export const getLoadOptionsQuery = (options: any, ampersandPrefix: boolean = false) => {
    let params: string[] = getLoadOptionsParameters(options);

    let query = '';

    if (params.length > 0) query = (ampersandPrefix ? '&' : '') + params.join('&');

    return query;
};
