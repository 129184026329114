export enum RouteNames {
    DOCUMENTATION = '/documentation',
    LOGIN = '/login',
    RESET_PASSWORD_REQUEST = '/resetPasswordRequest',
    RESET_PASSWORD_CONFIRM = '/resetPasswordConfirm',
    SETTINGS = '/settings',
    USERS_ACTIVATION = '/usersActivation',
    SET_ASSISTANT_SIMPLE = '/setassistanssimple',
    SIGN_UP = '/signup',
    SIGN_UP_CONFIRM = '/signUpConfirm',
    CHANGE_EMAIL_CONFIRM = '/changeEmailConfirm',
    DASHBOARD = '/',
    DOCUMENTS = '/documents',
    DICTIONARIES = '/dictionaries',
    REPORTS = '/reports',
    DOCUMENT = '/document/:id',
    DOCUMENT_CREATE = '/document/create/:type',
    DOCUMENT_CREATE_ON_BASE = '/document/createOnBase/:baseDocId/:actId',
    DOCUMENT_CREATE_ON_BASE_TEMPLATE = '/document/createOnBase/:baseDocId/:actId/:templateId',
    DOCUMENT_CREATE_BY_ACTION = '/document/createByAction/:actId',
    DOCUMENT_CREATE_BY_ACTION_TEMPLATE = '/document/createByAction/:actId/:templateId',
    TASKS = '/tasks',
    SEARCH = '/search',
    NOTIFICATION = '/notification',
    ROLES = '/roles',
    RELEASENOTES = '/releasenotes',
    REDIRECTS_AND_REPLACEMENTS = '/redirects-and-replacements',
    NOTIFICATIONSETTINGS = '/notification-settings',
    ADFS_SAML_SSO_SIGN_IN_REDIRECT = '/sso/saml/redirect/signin',
    ADFS_SAML_SSO_SIGN_OUT_REDIRECT = '/sso/saml/redirect/signout',
    ADFS_SAML_SSO_SIGN_IN = '/sso/saml/signin',
}
