import React, { ReactNode } from 'react';

import '../styles/iproc-scheme.css';
import './DevExpressDataGrid.scss';

import DataGrid, { IDataGridOptions } from 'devextreme-react/data-grid';
import { initMessages } from '@utils/devextreme-react/dataGridUtils';

export interface IDevExpressDataGridOptions extends IDataGridOptions {
    /**
     * Ссылка на грид
     */
    ref?: React.Ref<DataGrid>;
    children?: ReactNode;
}

const DevExpressDataGrid: React.FC<IDevExpressDataGridOptions> = ({
    ref,
    children,
    showBorders = true,
    showRowLines = false,
    rowAlternationEnabled = true,
    ...props
}) => {
    initMessages();

    return (
        <DataGrid
            showBorders={showBorders}
            showRowLines={showRowLines}
            rowAlternationEnabled={rowAlternationEnabled}
            {...props}
            ref={ref}
        >
            {children}
        </DataGrid>
    );
};

export default DevExpressDataGrid;
