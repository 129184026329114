import { AxiosResponse } from 'axios';
import { IGridResponse } from '@models/response/IGridResponse';
import { ITreeListDescriptionResponse } from '@models/response/ITreeListDescriptionResponse';
import { IGridExportSettings } from '@models/response/IGridExportSettings';

export abstract class IDataTreeService {
    abstract fetchScheme(data: any): Promise<AxiosResponse<ITreeListDescriptionResponse>>; // Mandatory
    abstract fetchData(data: any, parentData: any, loadOptions: any): Promise<AxiosResponse<IGridResponse>>; // Mandatory
    abstract exportData(template: string, options: any, columns: IGridExportSettings[]): Promise<void>;
}
