import './AbookpickerModal.scss';
import React, { FC, useState } from 'react';
import { MdClose } from 'react-icons/md';
import clsx from 'clsx';
import Button from '@atoms/Button';
import Modal from '@atoms/Modal';
import { IAbookTab, IExternalSearchAbook } from '@models/Forms/IForms';
import { IAddressBookData } from '@models/addressbook/IAddressBookData';
import SearchModeControl from './ModeControls/SearchModeControl';
import { AddressBookService } from '@services/AddressBookService';

export interface IAbookExternalSearchModalProp {
    settings: IExternalSearchAbook;
    onCloseModal: () => void;
    onSubmitModal: (data: IAddressBookData[]) => void;
    currentTab: IAbookTab;
    getParams: (options: any) => string;
}

const AbookExternalSearchModal: FC<IAbookExternalSearchModalProp> = (p: IAbookExternalSearchModalProp) => {
    const [selectedRowItems, setSelectedRowItems] = useState<IAddressBookData[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<number[]>([]);
    const ModalFooter = ({
        onClose,
        onSubmit,
    }: {
        onClose: () => void;
        onSubmit: (data: IAddressBookData[]) => void;
    }) => (
        <div className={'abookModal_footer'}>
            <Button
                onClick={() => {
                    if (selectedRowItems.length > 0) {
                        AddressBookService.saveExternalSearch(p.settings.submitApiUrl, selectedRowItems[0]).then(
                            (response) => {
                                onSubmit(selectedRowItems);
                                onClose();
                            },
                        );
                    } else {
                        onClose();
                    }
                }}
                size="s"
            >
                Выбрать
            </Button>
            <Button onClick={onClose} buttonType="light" size="s">
                Отменить
            </Button>
        </div>
    );

    return (
        <Modal
            className={clsx('abookExternalSearchModal', 'dx-dropdowneditor-overlay')}
            size="content"
            footer={<ModalFooter onClose={p.onCloseModal} onSubmit={p.onSubmitModal} />}
            header={
                <div className={clsx('box')}>
                    <div>
                        <div>{p.settings.buttonName}</div>
                    </div>
                    <div className={clsx('left')}>
                        <Button
                            buttonType="text"
                            textColor="neutral"
                            size="xs"
                            aria-label="Закрыть окно"
                            onClick={() => {
                                p.onCloseModal();
                            }}
                            startAdornment={<MdClose size="24" />}
                        />
                    </div>
                </div>
            }
        >
            <div className="abook-container">
                <div className="abook-grid">
                    <SearchModeControl
                        currentTab={p.currentTab}
                        searchApiUrl={p.settings.searchApiUrl}
                        onSelectedRowItems={(val: IAddressBookData[]) => {
                            setSelectedRowItems(val);
                        }}
                        onSelectedKeys={(value: number[]) => {
                            setSelectedKeys(value);
                        }}
                        getParams={p.getParams}
                        isMultiple={false}
                        selected={[]}
                        title={''}
                        formValues={''}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default AbookExternalSearchModal;
