import './CopyDocumentExecutor.scss';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import MessageDialog, { IMessageDialogProps } from '@molecules/MessageDialog/MessageDialog';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import { ICopyDocumentExecutorProps } from './ICopyDocumentExecutorProps';

export const CopyDocumentExecutorModal: React.FC<ICopyDocumentExecutorProps> = (props: ICopyDocumentExecutorProps) => {
    const [errorText, setErrorText] = useState<string>();
    const [isBusy, setIsBusy] = useState<boolean>();
    const navigate = useNavigate();

    const messageBoxProp: IMessageDialogProps = {
        header: props.title,
        message: props.message,
        showOkButton: true,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        size: props.modalSize,
        okClick: () => {
            setIsBusy(true);
            let service = new DocumentExecutionService(props.docId);
            return service
                .copyDocument(props.actionKey)
                .then((result) => {
                    if (result.data) {
                        navigate('/document/' + result.data);
                        props.completeHandler && props.completeHandler(true);
                    }
                })
                .catch((error) => setErrorText(error))
                .finally(() => {
                    setIsBusy(false);
                });
        },
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
    };

    return <MessageDialog {...messageBoxProp} errorText={errorText} isBusy={isBusy} />;
};
