import './ActionConfirmationModal.scss';
import React, { FC } from 'react';
import { ModalSize } from '@/types/ModalSize';
import MessageDialog from '@molecules/MessageDialog/MessageDialog';

export interface IActionConfirmationModalProps {
    title: string;
    message: string;
    size?: ModalSize;
    okButtonText?: string;
    cancelButtonText?: string;
    onOkClick: () => Promise<void>;
    onCancelClick: () => void;
}

const ActionConfirmationModal: FC<IActionConfirmationModalProps> = ({
    title,
    message,
    size = 'xl',
    okButtonText = 'ОК',
    cancelButtonText = 'Отмена',
    onOkClick,
    onCancelClick,
}: IActionConfirmationModalProps) => {
    return (
        <MessageDialog
            header={title}
            size={size}
            showOkButton={true}
            showCancelButton={true}
            okButtonText={okButtonText}
            cancelButtonText={cancelButtonText}
            message={message}
            okClick={onOkClick}
            cancelClick={onCancelClick}
        />
    );
};

export default ActionConfirmationModal;
