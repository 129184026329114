import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { ICopyAndEditAttachesData, ICopyAndEditAttachesScheme } from '@/models/attaches/ICopyAndEditAttaches';

export class CopyAndEditAttachesService {
    static async fetchScheme(): Promise<AxiosResponse<ICopyAndEditAttachesScheme, any>> {
        return $api.get<ICopyAndEditAttachesScheme>(`/document/copyAndEditAttachScheme`);
    }

    static async fetchData(
        docId: string,
        baseDocId: string,
        flowName: string,
    ): Promise<AxiosResponse<ICopyAndEditAttachesData, any>> {
        var documentId = docId ?? '-1';
        return $api.post<ICopyAndEditAttachesData>(
            `/document/copyAndEditAttachData/${documentId}/${baseDocId}/${flowName}`,
        );
    }
}
