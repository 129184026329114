import './SendToEisExecutor.scss';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IEisAsyncSendStatusModel } from '@models/document/eis/IEisAsyncSendStatusModel';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import { DocumentLoadEisLogService } from '@services/DocumentLoadEisLogService';
import { ISendToEisModel } from '@models/document/actionsExecution/ISendToEisModel';
import { ActionResultStatus } from '@models/actions/ActionResultStatus';
import { EisAsyncSendStatus } from '@models/document/eis/EisAsyncSendStatus';
import ModalContainer, { IModalContainerProps } from '@molecules/ModalContainer/ModalContainer';
import InputControl from '@controls/InputControl';
import Hint from '@atoms/Hint';
import { ISendToEisExecutorProps } from './ISendToEisExecutorProps';
import { ISendToEisFormData } from './ISendToEisFormData';

export const SendToEisExecutorModal: React.FC<ISendToEisExecutorProps> = (props: ISendToEisExecutorProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>();
    const [successText, setSuccessText] = useState<string>();
    const [authRequired, SetAuthRequired] = useState<boolean>();
    const formState = useForm<ISendToEisFormData>({
        mode: 'onBlur', // "onChange"
    });
    const [asyncSendExecuteFlag, setAsyncSendExecuteFlag] = useState<boolean>(false);
    const [asyncSendFlag, setAsyncSendFlag] = useState<boolean>(false);
    const [asyncSendModel, setAsyncSendModel] = useState<IEisAsyncSendStatusModel>();

    const service = new DocumentExecutionService(props.docId);
    const eisService = new DocumentLoadEisLogService();

    useEffect(() => {
        // Модель данных
        setLoading(true);
        service
            .getSendToEisModel(props.actionKey)
            .then((result) => {
                if (result.data) {
                    SetAuthRequired(result.data.authRequired);
                    formState.reset({
                        login: result.data.login,
                        password: result.data.password,
                    } as ISendToEisFormData);
                }
            })
            .catch((error) => setErrorText(error))
            .finally(() => setLoading(false));
    }, []);

    const onSubmit = (form: ISendToEisFormData) => {
        // Отправить данные
        setLoading(true);
        setErrorText('');
        setSuccessText('');
        let sendToEisData: ISendToEisModel = {
            login: form.login,
            password: form.password,
        };

        return service
            .sendToEis(props.actionKey, sendToEisData)
            .then((dto) => {
                setSuccessText(dto.data.message);
                setSuccess(true);
                if (dto.data.status === ActionResultStatus.Executing) {
                    setAsyncSendFlag(true);
                    setAsyncSendExecuteFlag(true);
                }
                //props.completeHandler && props.completeHandler(true);
            })
            .catch((error) => setErrorText(error))
            .finally(() => setLoading(false));
    };

    let interval: number;

    const processEisAsyncSendStatus = () => {
        eisService.getEisAsyncSendStatus(props.docId).then((response) => {
            if (
                response.data.statusEnum === EisAsyncSendStatus.Success ||
                response.data.statusEnum === EisAsyncSendStatus.Error
            ) {
                setAsyncSendExecuteFlag(false);
            }
            setAsyncSendModel(response.data);
        });
    };

    const startEisAsyncSendStatusMonitoring = () => {
        interval = window.setInterval(() => {
            processEisAsyncSendStatus();
        }, 1000);
    };

    const stopEisAsyncSendStatusMonitoring = () => {
        clearInterval(interval);
    };

    useEffect(() => {
        if (asyncSendExecuteFlag) {
            startEisAsyncSendStatusMonitoring();
        } else {
            stopEisAsyncSendStatusMonitoring();
        }
        return () => stopEisAsyncSendStatusMonitoring();
    }, [asyncSendExecuteFlag]);

    const modalContainerProps: IModalContainerProps = {
        header: props.message,
        size: props.modalSize,
        showOkButton: !success,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        cancelClick: () => {
            props.completeHandler && props.completeHandler(success);
        },
        errorText: errorText,
        successText: successText,
        formMethods: formState,
        onSubmit: onSubmit,
        okButtonDisabled: false,
        isBusy: loading,
    };

    return (
        <ModalContainer {...modalContainerProps}>
            {!success && authRequired && (
                <InputControl
                    label="Логин"
                    name="login"
                    autoComplete="off"
                    required={authRequired}
                    formState={formState.formState}
                    control={formState.control}
                />
            )}

            {!success && authRequired && (
                <InputControl
                    label="Пароль"
                    name="password"
                    autoComplete="off"
                    type="password"
                    required={authRequired}
                    formState={formState.formState}
                    control={formState.control}
                />
            )}

            {asyncSendFlag && asyncSendModel?.statusEnum === EisAsyncSendStatus.InProgress && (
                <Hint icon="info" title={`${asyncSendModel?.statusText}`} variant="blue" maxWidth="100%" />
            )}
            {asyncSendFlag && asyncSendModel?.statusEnum === EisAsyncSendStatus.Error && (
                <Hint icon="info" title={`${asyncSendModel?.statusText}`} variant="red" maxWidth="100%" />
            )}
            {asyncSendFlag && asyncSendModel?.statusEnum === EisAsyncSendStatus.Success && (
                <Hint icon="info" title={`${asyncSendModel?.statusText}`} variant="green" maxWidth="100%" />
            )}
            {asyncSendFlag && asyncSendModel?.statusEnum === EisAsyncSendStatus.Queued && (
                <Hint icon="info" title={`${asyncSendModel?.statusText}`} variant="blue" maxWidth="100%" />
            )}
        </ModalContainer>
    );
};
