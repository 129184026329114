import React, { ReactNode } from 'react';

import { IControlProps, IPartialFormGroup } from '@controls/types';
import FormControl from '../FormControl';
import Abookpicker from '@atoms/Abookpicker';
import { IAbookpickerProps } from '@atoms/Abookpicker/Abookpicker';
import { useHookFormController } from '@controls/HookFormProvider/HookFormProvider';
import { IAddressBookData } from '@models/addressbook/IAddressBookData';

export type IAbookControlProps = IAbookpickerProps &
    IControlProps & {
        formGroupProps?: IPartialFormGroup;
        label?: ReactNode;
        tooltip?: ReactNode;
        required?: boolean;
        /** Функция изменения значения */
        onSetValues: (value: IAddressBookData[]) => Promise<void> | void;
        onAbookValueChanged?: (value?: any) => void;
    };

const AbookpickerControl = ({
    name,
    rules,
    displayValue,
    onSetValues,
    onAbookValueChanged,
    setValues,
    control,
    shouldUnregister,
    formState,
    label,
    tooltip,
    required,
    formGroupProps,
    ...props
}: IAbookControlProps) => {
    const Controller = useHookFormController();

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            shouldUnregister={shouldUnregister}
            render={({ field: { ref, onChange, value, ...fieldProps } }) => {
                return (
                    <FormControl
                        name={name}
                        label={label}
                        tooltip={tooltip}
                        required={required}
                        formState={formState}
                        {...formGroupProps}
                    >
                        <Abookpicker
                            {...fieldProps}
                            {...props}
                            defaultValue={value}
                            displayValue={displayValue}
                            onChange={async (e) => {
                                if (e) {
                                    if (props.source?.table) {
                                        let rows: any[] = [];
                                        e.forEach((item, itemIndex) => {
                                            let row: any = {};
                                            row[props.source?.key!] = item.key;
                                            props.source?.table.columns.forEach((column, columnIndex) => {
                                                let value = '';
                                                if (column.attr === '{currentRowNumber}') {
                                                    value = (itemIndex + 1).toString();
                                                } else {
                                                    value = (item as any)[column.attr];
                                                }
                                                row[column.key] = value;
                                            });
                                            rows.push(row);
                                        });
                                        onChange(rows);
                                    } else {
                                        let items = e.map((item) => item.key.toString()).join('|');
                                        onChange(items);
                                    }
                                }
                                await onSetValues(e);
                                if (onAbookValueChanged) {
                                    if (e && e.length > 0) {
                                        let result =
                                            e.length > 1
                                                ? e
                                                      .map((item) => {
                                                          return item.id;
                                                      })
                                                      .join('|')
                                                : e[0].id;

                                        onAbookValueChanged(result);
                                    } else {
                                        onAbookValueChanged(null);
                                    }
                                }
                            }}
                        />
                    </FormControl>
                );
            }}
        />
    );
};

export default AbookpickerControl;
