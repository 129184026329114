import './DictpickerModal.scss';
import React, { FC, useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import Button from '@atoms/Button';
import Modal from '../../Modal';
import { IAddDictForm, IForms } from '@models/Forms/IForms';
import { DictionariesService } from '@services/DictionariesService';
import { sendErrorMsg } from '@molecules/Errors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { IField, IFieldElem, IFormData } from '@models/IFormData';
import { convertListToMap, getFieldKeysByScheme } from '@utils/documentUtils';
import FormProviderControl from '@controls/FormProviderControl';
import HookFormProvider from '@controls/HookFormProvider';
import FormBuilder from '@molecules/formbuilder/FormBuilder';
import clsx from 'clsx';

export interface IDictAddFormModalProp {
    settings: IAddDictForm;
    onClose: () => void;
    onSubmit?: (data: IFormData) => Promise<void>;
}

const DictAddFormModal: FC<IDictAddFormModalProp> = (p: IDictAddFormModalProp) => {
    const [form, setForm] = useState<IForms>();
    const methods = useForm<IFormData>({
        mode: 'onBlur', // "onChange"
    });
    const [fieldsData, setFieldsData] = useState<Record<string, IFieldElem>>();
    let fieldsName: number[] = [];
    useEffect(() => {
        DictionariesService.fetchAddFormScheme(p.settings.customForm).then((response) => {
            const keys = getFieldKeysByScheme(response.data.form.view.rows.row);
            const uniqueKeys = keys.filter((v, i, a) => v !== undefined && a.indexOf(v) === i);
            let formFields: IField[] = [];
            uniqueKeys.forEach((key) => {
                formFields.push({ name: key, value: null } as IField);
            });
            let data = { fields: formFields } as IFormData;

            methods.reset(data);
            setFieldsData(convertListToMap(data.fields, ''));
            setForm(response.data.form);
        });
    }, [p.settings.customForm]);

    const onSubmitHandler: SubmitHandler<IFormData> = async (data: IFormData) => {
        p.onSubmit && (await p.onSubmit(data));
    };

    const ModalHeader = ({ onClose }: { onClose: () => void }) => (
        <div className="box">
            <div className={clsx('left')}>
                <Button
                    startAdornment={<MdClose size="24" />}
                    buttonType="text"
                    textColor="neutral"
                    size="xs"
                    aria-label="Закрыть окно"
                    onClick={onClose}
                />
            </div>
        </div>
    );

    const ModalFooter = ({ onClose }: { onClose: () => void }) => (
        <div className="dictModal_footer">
            <Button type="submit" form="add-dict-form" size="s">
                Сохранить
            </Button>
            <Button onClick={onClose} buttonType="light" size="s">
                Отменить
            </Button>
        </div>
    );

    return (
        <Modal
            size="xl"
            className={clsx('dictExternalSearchModal', 'dx-dropdowneditor-overlay')}
            header={<ModalHeader onClose={p.onClose} />}
            footer={<ModalFooter onClose={p.onClose} />}
        >
            <FormProviderControl
                id="add-dict-form"
                formMethods={methods}
                className="doc-form"
                onSubmit={onSubmitHandler}
            >
                <HookFormProvider controller={Controller}>
                    <div className="dict-add-form__wrapper">
                        <div className="dict-add-form__content">
                            <FormBuilder
                                fields={fieldsData!}
                                setError={(errors?: string[]) => {
                                    if (errors) {
                                        sendErrorMsg({
                                            message: errors,
                                        });
                                    }
                                }}
                                rows={form?.view}
                                isEdit={false}
                                isNew={true}
                                formMethods={methods}
                                includedFields={fieldsName}
                                uniqueKey={'add-dict-form'}
                            />
                        </div>
                    </div>
                </HookFormProvider>
            </FormProviderControl>
        </Modal>
    );
};

export default DictAddFormModal;
