import '../ActivityActions.scss';
import React, { useCallback, useState } from 'react';
import { ActivityExecutionService } from '@services/actions/ActivityExecutionService';
import Modal from '@atoms/Modal';
import clsx from 'clsx';
import Button from '@atoms/Button';
import { MdClose } from 'react-icons/md';
import Preloader from '@atoms/Preloader';
import Hint from '@atoms/Hint';
import Textarea from '@atoms/Textarea';
import { ICompleteProps } from './ICompleteProps';

export const CompleteModal: React.FC<ICompleteProps> = (props: ICompleteProps) => {
    const [comment, setComment] = useState<string>();
    const [errorText, setErrorText] = useState<string>();
    const [loading, setLoading] = useState<boolean>();
    const service = new ActivityExecutionService();

    const onSubmit = useCallback(() => {
        setLoading(true);

        // Сохранение данных
        service
            .activityComplete(props.actionKey, props.actId, comment)
            .then(() => {
                props.completeHandler && props.completeHandler(true);
            })
            .catch((error) => setErrorText(error))
            .finally(() => {
                setLoading(false);
            });
    }, [comment]);

    return (
        <Modal
            className={clsx('modal-dlg-container')}
            size={props.modalSize}
            header={
                <div className={clsx('box')}>
                    <div>
                        <span>{props.displayName}</span>
                    </div>
                    <div className={clsx('left')}>
                        <Button
                            buttonType="text"
                            textColor="neutral"
                            size="xs"
                            aria-label="Закрыть окно"
                            onClick={() => {
                                if (props?.completeHandler) props?.completeHandler(false);
                            }}
                            startAdornment={<MdClose size="24" />}
                        />
                    </div>
                </div>
            }
            footer={
                <div className="modal-dlg-buttons">
                    <Button
                        onClick={onSubmit}
                        size="s"
                        aria-label={props.okButtonText}
                        disabled={props.commentIsRequired && (comment == undefined || comment?.length == 0)}
                    >
                        {props.okButtonText}
                    </Button>
                    <Button
                        buttonType="light"
                        size="s"
                        aria-label={props.okButtonText}
                        onClick={() => {
                            if (props.completeHandler) props.completeHandler(false);
                        }}
                    >
                        {props.cancelButtonText}
                    </Button>
                </div>
            }
        >
            {loading ? (
                <Preloader size="m" position="static" />
            ) : (
                <div className="actForm">
                    {errorText && <Hint icon="info" title={`Ошибка: ${errorText}`} variant="red" maxWidth={'100%'} />}

                    <Textarea
                        className={clsx(
                            'action-modal-dlg-input',
                            props.commentIsRequired && (comment == undefined || comment.length == 0) && 'invalid',
                        )}
                        placeholder={'Комментарий' + (props.commentIsRequired ? ' (обязательно)' : '')}
                        rows={6}
                        name="commentText"
                        required={props.commentIsRequired}
                        onChange={(e: any) => {
                            setComment(e.target.value);
                        }}
                    />
                </div>
            )}
        </Modal>
    );
};
