import React, { FC, useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import clsx from 'clsx';
import Button from '../Button';
import Modal from '../Modal';
import { TechSupportService } from '@services/TechSupportService';
import Preloader from '@atoms/Preloader';

export interface ISupportModalProp {
    onCloseModal: () => void;
}

const SupportModal: FC<ISupportModalProp> = (p: ISupportModalProp) => {
    const [textInfo, setTextInfo] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        TechSupportService.getTextInfo()
            .then((value) => {
                setTextInfo(value.data);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return (
        <Modal
            size="content"
            header={
                <div className={clsx('box')}>
                    <div>
                        <div>Техническая поддержка</div>
                    </div>
                    <div className={clsx('left')}>
                        <Button
                            buttonType="text"
                            textColor="neutral"
                            size="xs"
                            aria-label="Закрыть окно"
                            onClick={() => {
                                p.onCloseModal();
                            }}
                            startAdornment={<MdClose size="24" />}
                        />
                    </div>
                </div>
            }
        >
            {isLoading ? <Preloader size="l" /> : <div dangerouslySetInnerHTML={{ __html: textInfo }} />}
        </Modal>
    );
};

export default SupportModal;
