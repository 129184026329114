import { IMessage, IMessageWithCondition } from '@models/Forms/IForms';
import { IFieldElem } from '@models/IFormData';
import { UseFormReturn } from 'react-hook-form';
import { FormulaManager } from '@utils/managers/FormulaManager';

export class NotificationsManager {
    private _fieldsData: Record<string, IFieldElem>;
    private _methods: UseFormReturn<any>;

    constructor(fieldsData: Record<string, IFieldElem>, methods: UseFormReturn<any>) {
        this._fieldsData = fieldsData;
        this._methods = methods;
    }

    private getValue(name: string, rowData?: any, rowParent?: any) {
        if (name) {
            if (name.includes('|Document') || name.startsWith('#links')) {
                if (this._fieldsData) {
                    let field = this._fieldsData[name];
                    let fname = ('fields.[' + field.index + '].value') as any;
                    let val = this._methods.getValues(fname);
                    return val as any;
                }
            } else if (name.includes('|Parent') && rowParent) {
                let fname = name.replace('|Parent', '');
                type ObjectKey = keyof typeof rowParent;
                const attrNAme = fname as ObjectKey;
                return rowParent[attrNAme] as any;
            } else {
                if (rowData) {
                    type ObjectKey = keyof typeof rowData;
                    const attrNAme = name as ObjectKey;
                    return rowData[attrNAme] as any;
                }
            }
        }
    }

    private async getNotificationTextAsync(message: IMessage, texts: string[], rowData?: any) {
        let manager = new FormulaManager(message.value);
        manager.Init(this._fieldsData!, this._methods);
        let values = manager.GetFields().map((field) => this.getValue(field, rowData));
        let text = (await manager.ReplaceFormulaValues(true, true, values)) ?? '';
        if (text != undefined && text != null && text != '' && text != "''" && text != "'null'" && text != 'null') {
            let txt = text.startsWith("'") && text.endsWith("'") ? text.slice(1, -1) : text;
            texts.push(txt);
        }
    }

    private async checkNotificationsConditionAsync(notify: IMessageWithCondition, rowData?: any): Promise<boolean> {
        let manager = new FormulaManager(notify.condition);
        manager.Init(this._fieldsData!, this._methods);
        let values = manager.GetFields().map((field) => this.getValue(field, rowData));
        return await manager.EvalFormulaValues(true, true, values);
    }

    public async notificationsAllAsync(
        notifications: IMessageWithCondition[] | undefined,
        texts: string[],
        rowData?: any,
    ) {
        if (notifications) {
            for (const notification of notifications) {
                if (await this.checkNotificationsConditionAsync(notification, rowData)) {
                    await this.getNotificationTextAsync(notification, texts, rowData);
                }
            }
        }
    }
}
