import React, { FC, useEffect, useRef, useState } from 'react';
import './DocumentTabAttach.scss';

import { useActions } from '@/hooks/useActions';
import { useTypedSelector } from '@/hooks/useTypedSelector';
import { CopyAndEditAttachFilesBlock } from '@/components/molecules/AttachFilesBlock/CopyAndEditAttachFilesBlock';
import { ICopyAndEditAttachesData, ICopyAndEditAttachesItem } from '@/models/attaches/ICopyAndEditAttaches';

export interface IDocumentTabCopyAttachProps {
    baseDocId: string;
}

const DocumentTabCopyAttach: FC<IDocumentTabCopyAttachProps> = ({ baseDocId }: IDocumentTabCopyAttachProps) => {
    const tabRef = useRef<HTMLDivElement | null>(null);

    const doc = useTypedSelector((state) => state.document);
    const [data, setData] = useState<ICopyAndEditAttachesData>();

    const { setPartsVersionToCopy } = useActions();

    useEffect(() => {
        if (doc.newDocCopyAttachesData) {
            // Получаем данные из state документа
            let data = {
                data: doc.newDocCopyAttachesData.data.map((data) => {
                    return {
                        key: data.key,
                        fields: Object.assign({}, data.fields),
                        isExpanded: data.isExpanded,
                        isSelected: data.isSelected,
                        isReadOnly: data.isReadOnly,
                    } as ICopyAndEditAttachesItem;
                }),
                totalCount: doc.newDocCopyAttachesData.totalCount,
            } as ICopyAndEditAttachesData;

            setData(data);
        }
    }, [doc.newDocCopyAttachesData]);

    return (
        <div className="copy-attach-table">
            {data && (
                <CopyAndEditAttachFilesBlock
                    scheme={doc.newDocCopyAttachesScheme!}
                    data={data!}
                    onFilesSelected={(files) => {
                        setPartsVersionToCopy(files);
                    }}
                />
            )}
        </div>
    );
};

export default DocumentTabCopyAttach;
