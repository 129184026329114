import { IBaseAction } from '@models/actions/IBaseAction';
import { DocBaseActionExecutor } from '@organisms/actions/DocBaseActionExecutor';
import { FilesService } from '@services/FilesService';

export class DownloadLinkedDocumentsAttachmentsExecutor extends DocBaseActionExecutor {
    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
    ) => {
        FilesService.downloadFile(`documentExecution/downloadLinkedDocumentsAttachments/${objId}/${action.key}`);
        completeHandler && completeHandler(false);
    };
}
