import './CreateDocumentTemplateExecutor.scss';
import React, { useState } from 'react';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import { sendNotification } from '@molecules/Notifications';
import { useForm } from 'react-hook-form';
import ModalContainer, { IModalContainerProps } from '@molecules/ModalContainer/ModalContainer';
import { InputControl } from '@controls/index';
import { ICreateDocumentTemplateExecutorProps } from './ICreateDocumentTemplateExecutorProps';
import { ICreateDocumentTemplateFormData } from './ICreateDocumentTemplateFormData';

export const CreateDocumentTemplateExecutorModal: React.FC<ICreateDocumentTemplateExecutorProps> = (
    props: ICreateDocumentTemplateExecutorProps,
) => {
    const [templateName, setTemplateName] = useState<string>('');
    const [errorText, setErrorText] = useState<string>();
    const [loading, setLoading] = useState<boolean>();

    let service = new DocumentExecutionService(props.docId);

    const onSubmit = (form: ICreateDocumentTemplateFormData) => {
        setLoading(true);

        return service
            .createDocumentTemplate(props.actionKey, templateName)
            .then((result) => {
                props.completeHandler && props.completeHandler(false);
                sendNotification({
                    title: 'Шаблон документа успешно создан',
                    message: `<a href=/document/${result.data}>Перейти к документу</a>`,
                    variant: 'green',
                });
                formState.reset({ templateName: '' } as ICreateDocumentTemplateFormData);
            })
            .catch((error) => setErrorText(error))
            .finally(() => {
                setLoading(false);
            });
    };

    const formState = useForm<ICreateDocumentTemplateFormData>({
        mode: 'onBlur', // "onChange"
    });

    const modalContainerProps: IModalContainerProps = {
        header: props.title,
        size: props.modalSize,
        showOkButton: true,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        isBusy: loading,
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
        errorText: errorText,
        formMethods: formState,
        onSubmit: onSubmit,
        okButtonDisabled: false,
    };

    return (
        <ModalContainer {...modalContainerProps}>
            <InputControl
                label="Название шаблона"
                readOnly={false}
                required={true}
                rules={{ required: 'Обязательное поле' }}
                name="templateName"
                formState={formState.formState}
                control={formState.control}
                onValueChange={(val: any) => {
                    setTemplateName(val);
                }}
            />
        </ModalContainer>
    );
};
