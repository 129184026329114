import React, { FC, useEffect, useRef, useState } from 'react';

import './DictpickerSelect.scss';
import { DictionariesService } from '@services/DictionariesService';
import { IDictionaryData } from '@models/dictionary/IDictionaryData';
import { IDictFilter } from '@models/Forms/IForms';
import { Node } from 'devextreme/ui/tree_list';
import SelectMenu from '@atoms/Select/Select.Menu';
import DropdownContent from '@atoms/DropdownContent';
import { IOption } from '@/types';
import { IDictSettings } from '../IDictSettings';
import { filterGridRowsByScript, filterResponseVoid } from '@utils/dataGridUtils';
import { FloatingContext } from '@floating-ui/react';

export interface ISelectFromDictDialogProp extends IDictSettings {
    ref?: React.Ref<HTMLDivElement>;
    widthDropdown?: number;
    onCloseModal: () => void;
    onSubmitModal: (data: IDictionaryData[]) => Promise<void>;
    floatingContext: FloatingContext;
    getFloatingProps?: (userProps?: React.HTMLProps<HTMLElement>) => Record<string, unknown>;
    floatingStyles: React.CSSProperties;
}

const DictpickerSelect: FC<ISelectFromDictDialogProp> = (p: ISelectFromDictDialogProp) => {
    const selectDropDownRef = useRef<HTMLUListElement>(null);
    const [dictAttributes, setDictAttributes] = useState<string[]>();
    const [highlightedStr, setHighlightedStr] = useState<string>('');
    const [options, setOptions] = useState<IOption[]>();
    const [selectedOptions, setSelectedOptions] = useState<IOption[]>();
    const [filterResponse, setFilterResponse] = useState<IDictFilter>();

    const getFilter = async () => {
        setFilterResponse(await p.getFiltersAsync());
    };

    useEffect(() => {
        if (!dictAttributes) {
            getFilter().then(() => {
                if (p.isFormData) {
                    DictionariesService.fetchFormdataDictionaryGridScheme(p.dictName).then((response) => {
                        setDictAttributes(response.data.attributes);
                    });
                } else {
                    DictionariesService.fetchDictionaryGridScheme(p.dictName).then((response) => {
                        setDictAttributes(response.data.attributes);
                    });
                }
            });
        }
    }, [p.dictName]);

    const InitOptions = async () => {
        let params = '';
        params += '?formValues=' + (await p.getFormValuesAsync());
        let response: IDictionaryData[] = [];
        let result: IOption[] = [];
        let selected: IOption[] = [];
        if (p.isFormData) {
            response = await DictionariesService.getGridFormdataItems(p.docId!, p.dictName, params).then((response) => {
                if (filterResponse) {
                    let _data: IDictionaryData[] = [];
                    _data = filterResponseVoid(response.data.data, filterResponse);
                    if (filterResponse?.script) {
                        _data = filterGridRowsByScript(_data, filterResponse?.script);
                    }
                    response.data.data = _data;
                }
                let exDs = p.getExternalDataSource();
                if (exDs.length > 0) {
                    let data: IDictionaryData[] = [];
                    for (let index = 0; index < response.data.data.length; index++) {
                        const element = response.data.data[index];
                        if (
                            exDs.findIndex((x) => {
                                return x.code == element.code;
                            }) == -1
                        ) {
                            data.push(element);
                        }
                    }
                    exDs.forEach((element) => {
                        data.push(element);
                    });
                    response.data.data = data;
                }
                return response.data.data;
            });
        } else {
            response = await DictionariesService.getGridItems(p.dictName, params).then((response) => {
                if (filterResponse) {
                    let _data: IDictionaryData[] = [];
                    _data = filterResponseVoid(response.data.data, filterResponse);
                    if (filterResponse?.script) {
                        _data = filterGridRowsByScript(_data, filterResponse?.script);
                    }
                    response.data.data = _data;
                }
                return response.data.data;
            });
        }
        response.forEach((item) => {
            let name =
                item.fields.find((x) => x.name === 'Наименование') === undefined
                    ? item.code
                    : item.fields.find((x) => x.name === 'Наименование')?.value;
            if (p.selected && p.selected.indexOf(item.code) > -1) {
                selected.push({
                    label: name,
                    value: item.code,
                    payload: item,
                } as IOption);
                setHighlightedStr(name as string);
            }
            result.push({
                label: name,
                value: item.code,
                payload: item,
            } as IOption);
        });
        setOptions(result);
        setSelectedOptions(selected);
    };

    useEffect(() => {
        if (dictAttributes) {
            InitOptions();
        }
    }, [dictAttributes]);

    const setNodesVisibility = (nodes?: Node[], visibility?: boolean) => {
        nodes?.forEach((node) => {
            setNodesVisibility(node.children, visibility);
            node.visible = visibility ?? false;
        });
    };

    return dictAttributes && options !== undefined && selectedOptions !== undefined ? (
        <DropdownContent
            ref={p.ref}
            floatingContext={p.floatingContext}
            getFloatingProps={p.getFloatingProps}
            show={true}
            style={{ ...p.floatingStyles, width: 'auto', minWidth: p.widthDropdown }}
        >
            <SelectMenu
                ref={selectDropDownRef}
                options={options}
                selected={selectedOptions}
                //multiselect={multiselect}
                menuVariantSize="m"
                isAsync={false}
                preloader={false}
                onOptionClick={async (item) => {
                    await p.onSubmitModal([item.payload]);
                    p.onCloseModal();
                }}
                multiselect={false}
                selectId={0}
                query={highlightedStr}
                onClose={p.onCloseModal}
                // onScroll={onScroll}
            />
        </DropdownContent>
    ) : (
        <></>
    );
};

export default DictpickerSelect;
