import './RemoveFromControlExecutor.scss';
import React, { useState } from 'react';
import { ProcessExecutionService } from '@services/actions/ProcessExecutionService';
import Modal from '@atoms/Modal';
import clsx from 'clsx';
import Button from '@atoms/Button';
import { MdClose } from 'react-icons/md';
import Preloader from '@atoms/Preloader';
import Hint from '@atoms/Hint';
import Textarea from '@atoms/Textarea';
import { IRemoveFromControlProps } from './IRemoveFromControlProps';

export const RemoveFromControlModal: React.FC<IRemoveFromControlProps> = (props: IRemoveFromControlProps) => {
    const [loading, setLoading] = useState<boolean>();
    const [errorText, setErrorText] = useState<string>();
    const [comment, setComment] = useState<string>();

    const onSubmit = () => {
        let service = new ProcessExecutionService();

        setLoading(true);
        service
            .removeFromControl(props.objId, comment ?? '')
            .then((x) => {
                if (x.status == 200 && props?.completeHandler) props.completeHandler(true);
            })
            .catch((reason) => {
                setErrorText(reason);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Modal
            className={clsx('modal-dlg-container')}
            size={props.modalSize}
            header={
                <div className={clsx('box')}>
                    <div>
                        <span>Снять с контроля</span>
                    </div>
                    <div className={clsx('left')}>
                        <Button
                            buttonType="text"
                            textColor="neutral"
                            size="xs"
                            aria-label="Закрыть окно"
                            onClick={() => {
                                if (props?.completeHandler) props?.completeHandler(false);
                            }}
                            startAdornment={<MdClose size="24" />}
                        />
                    </div>
                </div>
            }
            footer={
                <div className="modal-dlg-buttons">
                    <Button
                        onClick={() => {
                            onSubmit();
                        }}
                        aria-label={props.okButtonText}
                        size="s"
                    >
                        {props.okButtonText}
                    </Button>
                    <Button
                        buttonType="light"
                        size="s"
                        aria-label={props.cancelButtonText}
                        onClick={() => {
                            props.completeHandler && props.completeHandler(false);
                        }}
                    >
                        {props.cancelButtonText}
                    </Button>
                </div>
            }
        >
            {loading ? (
                <Preloader size="m" position="static" />
            ) : (
                <div className="remove-from-control__form">
                    {errorText && <Hint icon="info" title={`Ошибка: ${errorText}`} variant="red" maxWidth="100%" />}

                    <Textarea
                        className="calendarInput"
                        placeholder="Комментарий"
                        rows={6}
                        onChange={(e: any) => {
                            setComment(e.target.value);
                        }}
                    />
                </div>
            )}
        </Modal>
    );
};
