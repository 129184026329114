import { ISearchData } from '@models/search/ISearchData';
import { ISearchExtraBaseFilter, ISearchExtraFlowSetting } from '@models/search/ISearchExtraFilters';
import { SearchService } from '@services/SearchService';
import { AppDispatch } from '@/store/index';
import { SearchSlice } from '@/store/reducers/SearchReducer';
import { IFieldElem } from '@models/IFormData';
import { IForms } from '@models/Forms/IForms';

export const SearchActionCreators = {
    searchDocumentsGlobal: (searchData: ISearchData) => async (dispatch: AppDispatch) => {
        try {
            dispatch(SearchSlice.actions.setGlobalSearchLoading(true));
            dispatch(SearchSlice.actions.setSearchingData(searchData));

            dispatch(SearchSlice.actions.resetAllSearchResults());

            const response = await SearchService.searchDocuments(searchData);
            dispatch(SearchSlice.actions.setDocumentSearchResult(response.data));
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(SearchSlice.actions.setGlobalSearchLoading(false));
        }
    },
    setSearchBaseRequisitesFields:
        (fields: Record<string, IFieldElem>, forms: IForms) => async (dispatch: AppDispatch) => {
            dispatch(SearchSlice.actions.setBaseRequisitesFields({ fields: fields, forms: forms }));
        },
    searchDocumentsPager: (searchData: ISearchData) => async (dispatch: AppDispatch) => {
        try {
            dispatch(SearchSlice.actions.setDocsSearchLoading(true));
            dispatch(SearchSlice.actions.setDocsPagerChanged(searchData));
            const response = await SearchService.searchDocuments(searchData);
            dispatch(SearchSlice.actions.setDocumentSearchResult(response.data));
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(SearchSlice.actions.setDocsSearchLoading(false));
        }
    },
    searchDicts: (searchData: ISearchData) => async (dispatch: AppDispatch) => {
        try {
            dispatch(SearchSlice.actions.setDictsSearchLoading(true));
            dispatch(SearchSlice.actions.setDictsPagerChanged(searchData));
            const response = await SearchService.searchDicts(searchData);
            dispatch(SearchSlice.actions.setDictsSearchResult(response.data));
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(SearchSlice.actions.setDictsSearchLoading(false));
        }
    },
    searchReports: (searchData: ISearchData) => async (dispatch: AppDispatch) => {
        try {
            dispatch(SearchSlice.actions.setReportsSearchLoading(true));
            dispatch(SearchSlice.actions.setReportPagerChanged(searchData));
            const response = await SearchService.searchReports(searchData);
            dispatch(SearchSlice.actions.setReportsSearchResult(response.data));
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(SearchSlice.actions.setReportsSearchLoading(false));
        }
    },
    loadExtraFilterFlows: () => async (dispatch: AppDispatch) => {
        try {
            dispatch(SearchSlice.actions.setExtraFlowsFilterLoading(true));
            const flowsDataResponse = await SearchService.loadExtraFilterFlowsData();
            dispatch(SearchSlice.actions.setExtraFlowsFilterData(flowsDataResponse.data));
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(SearchSlice.actions.setExtraFlowsFilterLoading(false));
        }
    },
    selectExtraFilterFlow: (selectedFlow: ISearchExtraFlowSetting | undefined) => async (dispatch: AppDispatch) => {
        dispatch(SearchSlice.actions.selectExtraFilterFlow(selectedFlow));
    },
    clearExtraFilters: (state: ISearchExtraBaseFilter) => async (dispatch: AppDispatch) => {
        dispatch(SearchSlice.actions.clearExtraFilters(state));
    },
};
