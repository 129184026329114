import './AttachFilesExecutor.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IAttachesCreateModel } from '@models/attaches/IAttachesCreateModel';
import { AttachFilesBlock, IAttachFilesBlockRefActions } from '@atoms/AttachFilesBlock/AttachFilesBlock';
import { AttachActionsService } from '@services/actions/AttachActionsService';
import Modal from '@atoms/Modal';
import clsx from 'clsx';
import Button from '@atoms/Button';
import { MdClose } from 'react-icons/md';
import Preloader from '@atoms/Preloader';
import Hint from '@atoms/Hint';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import { IAttachFilesExecutorProps } from './IAttachFilesExecutorProps';

export const AttachFilesExecutorModal: React.FC<IAttachFilesExecutorProps> = (props: IAttachFilesExecutorProps) => {
    const [isBusy, setIsBusy] = useState<boolean>(true);
    const [errorText, setErrorText] = useState<string>();
    const [attachesCreateModel, setAttachesCreateModel] = useState<IAttachesCreateModel>();
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
    const childFilesBlock = useRef<IAttachFilesBlockRefActions | null>(null);

    useEffect(() => {
        setIsBusy(true);
        let service = new AttachActionsService(props.docId, '-1');
        service.getAttachInfo(props.action.key).then((res) => {
            setAttachesCreateModel(res.data);
            setIsBusy(false);
        });
    }, []);

    const handleSubmit = useCallback(() => {
        if (!childFilesBlock.current?.isValid()) return;

        setIsBusy(true);
        let uploadingFiles = childFilesBlock.current.getData();

        let service = new DocumentExecutionService(props.docId);
        service
            .attachFiles(props.action.key, uploadingFiles.attachProperties, uploadingFiles.files)
            .then(() => {
                if (props.completeHandler) props.completeHandler(true);
            })
            .catch((error) => setErrorText(error))
            .finally(() => {
                setIsBusy(false);
            });
    }, [props]);

    return (
        <Modal
            className={clsx('modal-dlg-container')}
            size={props.modalSize}
            header={
                <>
                    <div className={clsx('box')}>
                        <div>Присоединить файлы</div>
                        <div className={clsx('left')}>
                            <Button
                                buttonType="text"
                                textColor="neutral"
                                size="xs"
                                aria-label="Закрыть окно"
                                onClick={() => {
                                    if (props.completeHandler) props.completeHandler(false);
                                }}
                                startAdornment={<MdClose size="24" />}
                            />
                        </div>
                    </div>
                </>
            }
            footer={
                <div className="modal-dlg-buttons">
                    <Button size="s" aria-label={props.okButtonText} disabled={submitDisabled} onClick={handleSubmit}>
                        {props.okButtonText}
                    </Button>
                    <Button
                        buttonType="light"
                        size="s"
                        aria-label={props.cancelButtonText}
                        onClick={() => {
                            if (props.completeHandler) props.completeHandler(false);
                        }}
                    >
                        {props.cancelButtonText}
                    </Button>
                </div>
            }
        >
            {isBusy ? (
                <Preloader size="m" position="static" />
            ) : (
                <div>
                    {errorText && <Hint icon="info" title={`Ошибка: ${errorText}`} variant="red" maxWidth={'100%'} />}
                    {attachesCreateModel ? (
                        <AttachFilesBlock
                            ref={childFilesBlock}
                            model={attachesCreateModel}
                            onChanged={(data) => {
                                let uploadingFiles = childFilesBlock.current?.getData();
                                setSubmitDisabled(uploadingFiles?.files?.length === 0);
                            }}
                        />
                    ) : (
                        <Hint
                            icon="info"
                            title="Не удалось загрузить конфигурацию действия для данного типа документов"
                            variant="red"
                            maxWidth="100%"
                        />
                    )}
                </div>
            )}
        </Modal>
    );
};
