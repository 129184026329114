import React from 'react';
import { IBaseAction } from '@models/actions/IBaseAction';
import { DocBaseActionExecutor } from '@organisms/actions/DocBaseActionExecutor';
import ActionConfirmationModal from '@organisms/actions/ActionConfirmationModal/ActionConfirmationModal';

export class RegisterExecutor extends DocBaseActionExecutor {
    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
    ) => {
        if (!action.options?.confirmation) {
            this.doAction(action, completeHandler);
        }
    };

    private doAction(action: IBaseAction, completeHandler: ((isSucceed: boolean) => void) | undefined) {
        if (this.navigate) {
            if (action.options?.newTab) {
                window.open(`/document/createByAction/${action.key}`, '_blank', 'noopener,noreferrer');
            } else {
                if (window.location.href.indexOf('/document/createByAction/') > -1) {
                    let st = this.location?.state as any;
                    if (st && st.fromPathname) {
                        this.navigate(`/document/createByAction/${action.key}`, {
                            state: {
                                fromPathname: st.fromPathname,
                                fromSearch: st.fromSearch,
                                fromHash: st.fromHash,
                            },
                        });
                    } else {
                        this.navigate(`/document/createByAction/${action.key}`);
                    }
                } else {
                    this.navigate(`/document/createByAction/${action.key}`, {
                        state: {
                            fromPathname: this.location?.pathname,
                            fromSearch: this.location?.search,
                            fromHash: this.location?.hash,
                        },
                    });
                }
            }
        } else {
            window.location.href = `./document/createByAction/${action.key}`;
        }
        completeHandler && completeHandler(false);
    }

    visualElementInner = () => {
        return this._action?.options?.confirmation ? (
            <ActionConfirmationModal
                title={this._action.options.confirmation.title}
                message={this._action.options.confirmation.message}
                okButtonText={this._action.options.confirmation.okButtonText}
                cancelButtonText={this._action.options.confirmation.cancelButtonText}
                onOkClick={() => {
                    if (this._action) {
                        this.doAction(this._action, this._completeHandler);
                    }

                    return new Promise<void>((resolve, reject) => {
                        resolve();
                    });
                }}
                onCancelClick={() => {
                    this._completeHandler && this._completeHandler(false);
                }}
            />
        ) : (
            <></>
        );
    };
}
