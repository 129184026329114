import React from 'react';
import { IBaseAction } from '@models/actions/IBaseAction';
import { DocBaseActionExecutor } from '@organisms/actions/DocBaseActionExecutor';
import { ModalSize } from '@/types/ModalSize';
import { IRequiredValidation } from '@models/actions/IRequiredValidation';
import { IDocumentsBatchEditingExecutorProps } from './IDocumentsBatchEditingExecutorProps';
import { DocumentsBatchEditingExecutorModal } from './DocumentsBatchEditingExecutorModal';

export class DocumentsBatchEditingExecutor extends DocBaseActionExecutor {
    private _modalProps?: IDocumentsBatchEditingExecutorProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            docId: objId ?? '',
            actionKey: action.key ?? '',
            title: action.options?.title ?? '',
            message: action.options?.message ?? '',
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'xl',
            attachFiles: action.options?.attachFiles == true,
            attachRequired: action.options?.attachRequired == true,
            attachRequiredValidation: action.options?.attachRequiredValidation as IRequiredValidation[],
            addMailReminders: action.options?.addMailReminders == true,
            virtualFields: action.options?.virtualFields as string[],
            showCurrentValues: action.options?.showCurrentValues ?? true,
            postActions: action.options?.postActions as any[],
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <DocumentsBatchEditingExecutorModal {...this._modalProps} /> : <div />;
    };
}
