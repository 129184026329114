import { useEffect, useState } from 'react';

export const useUserScripts = (onRowPreparedUrl?: string, onCellPreparedUrl?: string) => {
    const [onRowPreparedScript, setOnRowPreparedScript] = useState<string>();
    const [onCellPreparedScript, setOnCellPreparedScript] = useState<string>();

    useEffect(() => {
        // Handle onRowPrepared script
        if (onRowPreparedUrl) {
            fetch(onRowPreparedUrl).then((response) => {
                console.log(`onRowPrepared. Загружен скрипт: ${onRowPreparedUrl}`);
                const contentType = response.headers.get('Content-Type');
                if (contentType === 'text/javascript' || contentType === 'application/javascript') {
                    response.text().then((script) => {
                        setOnRowPreparedScript(script);
                    });
                } else {
                    console.log(
                        `onRowPrepared. Файл ${onRowPreparedUrl} не является JS файлом (ContentType: ${contentType}). Скрипт не будет исполнен.`,
                    );
                }
            });
        } else {
            setOnRowPreparedScript(undefined);
        }

        // Handle onCellPrepared script
        if (onCellPreparedUrl) {
            fetch(onCellPreparedUrl).then((response) => {
                console.log(`onCellPrepared. Загружен скрипт: ${onCellPreparedUrl}`);
                const contentType = response.headers.get('Content-Type');
                if (contentType === 'text/javascript' || contentType === 'application/javascript') {
                    response.text().then((script) => {
                        setOnCellPreparedScript(script);
                    });
                } else {
                    console.log(
                        `onCellPrepared. Файл ${onCellPreparedUrl} не является JS файлом (ContentType: ${contentType}). Скрипт не будет исполнен.`,
                    );
                }
            });
        } else {
            setOnCellPreparedScript(undefined);
        }
    }, [onRowPreparedUrl, onCellPreparedUrl]);

    return { onRowPreparedScript, onCellPreparedScript };
};
