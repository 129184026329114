import { FormulaManager } from '@utils/managers/FormulaManager';
import React from 'react';

export const handlerFieldWatch = async (
    watch: any[],
    isEdit: boolean,
    isNew: boolean,
    manager: FormulaManager,
    setMethod: (value: React.SetStateAction<boolean>) => void,
    isActivatedComponent: React.RefObject<boolean>,
) => {
    let result = true;
    if (watch.length != 0) {
        if (!watch.every((element) => element === undefined)) {
            result = await manager.EvalFormulaValues(isEdit, isNew, watch);
            if (isActivatedComponent.current) setMethod(result);
        }
    } else {
        result = await manager.EvalFormulaValues(isEdit, isNew);
        if (isActivatedComponent.current) setMethod(result);
    }
    return result;
};
export const handlerFieldReplaceWatch = async (
    watch: any[],
    isEdit: boolean,
    isNew: boolean,
    manager: FormulaManager,
    setMethod: (value: React.SetStateAction<any | undefined>) => void,
) => {
    if (watch.length != 0) {
        if (!watch.every((element) => element === undefined)) {
            let coll: any[] = [];
            watch.forEach((x) => {
                if (x === null) {
                    coll.push('');
                } else {
                    coll.push(x);
                }
            });
            let d = await manager.ReplaceFormulaValues(isEdit, isNew, coll, false);

            if (d) {
                setMethod(d);
            }
        }
    }
};

export interface IWatchHandlerResult {
    watch: any[];
    result: any;
}

export const handlerFieldDisplayWatch = async (
    watch: any[],
    isEdit: boolean,
    isNew: boolean,
    manager: FormulaManager,
) => {
    if (watch.length != 0) {
        if (!watch.every((element) => element === undefined)) {
            let result = await manager.ReplaceFormulaValues(isEdit, isNew, watch, true);

            return {
                watch: watch,
                result: result ? result : '',
            } as IWatchHandlerResult;
        }
    }
    return null;
};
