import React, { RefObject, useEffect, useImperativeHandle, useState } from 'react';
import clsx from 'clsx';
import { DataGrid } from 'devextreme-react/data-grid';

export interface DeleteRowMultiButtonProps {
    ref?: React.Ref<IDeleteRowMultiButtonRefActions>;
    onClick: (e: React.MouseEvent) => void;
    gridRef: RefObject<DataGrid | null>;
    canRemoveRows: boolean;
}

export interface IDeleteRowMultiButtonRefActions {
    toggleVisibility: (value: boolean) => void;
}

const DeleteRowMultiButton: React.FC<DeleteRowMultiButtonProps> = ({ ref, onClick, gridRef, canRemoveRows }) => {
    const [visible, setVisible] = useState<boolean>(false);

    useEffect(() => {
        const visibility = canRemoveRows && (gridRef.current?.instance.getSelectedRowKeys()?.length ?? 0) > 0;
        setVisible(visibility);
    }, [canRemoveRows, gridRef]);

    useImperativeHandle(ref, () => ({
        toggleVisibility: (value: boolean) => {
            setVisible(value);
        },
    }));

    return (
        <a
            href="#"
            className={clsx('dx-link', 'dx-link-delete', 'dx-icon-trash', 'dx-link-icon', {
                'dx-state-invisible': !visible,
            })}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClick(e);
            }}
            title="Удалить выбранные строки"
            aria-label="Удалить выбранные строки"
        />
    );
};

export default DeleteRowMultiButton;
