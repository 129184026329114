import './DateTimePicker.scss';
import React, { useState } from 'react';
import Datepicker from '@atoms/Datepicker';
import { IDatepickerProps } from '@atoms/Datepicker/Datepicker';
import Timepicker from '@atoms/Timepicker/Timepicker';
import { getValueDates, getValueTimes } from '@utils/documentUtils';
import { getTime } from '@utils/helpersDatePicker';
import clsx from 'clsx';

export type IDateTimePickerProps = IDatepickerProps & {
    isNoSecFormat?: boolean;
    onChangeDate?: (value: Date) => void;
};

const DateTimePicker: React.FC<IDateTimePickerProps> = ({
    name = 'datetimepicker',
    defaultValue,
    minDate,
    maxDate,
    invalid = false,
    disabled = false,
    readOnly = false,
    onChangeDate,
    range = false,
    showDayOfWeek = false,
    position = 'bottom-start',
    disableWeekDays = [],
    isNoSecFormat,
    ...restProps
}: IDateTimePickerProps) => {
    const [result, setResult] = useState<Date>(getValueDates(defaultValue));

    const setByDate = (prev: Date, val: Date | undefined): Date | undefined => {
        let res = val;
        if (prev != undefined && res) {
            res.setHours(prev.getHours(), prev.getMinutes(), prev.getSeconds(), prev.getMilliseconds());
        }

        return res;
    };
    const setByTime = (prev: Date, val: string): Date => {
        let res = prev;
        let time = getTime(val);
        if (time.length > 0 && res) {
            res.setHours(Number(time[0]));
            res.setMinutes(time.length > 1 ? Number(time[1]) : 0);
        }

        return res;
    };

    return (
        <div className={clsx('rf-datetimepicker', restProps.className)}>
            <div className="rf-datetimepicker__date">
                <Datepicker
                    name={name}
                    minDate={minDate}
                    maxDate={maxDate}
                    disabled={disabled}
                    readOnly={readOnly}
                    range={range}
                    invalid={invalid}
                    disableWeekDays={disableWeekDays}
                    position={position}
                    showDayOfWeek={showDayOfWeek}
                    className={'rf-input--left'}
                    defaultValue={getValueDates(defaultValue)}
                    onChange={(e) => {
                        if (readOnly) return;

                        const val = e.date.utc;
                        const res = setByDate(result, val);

                        if (res) {
                            setResult(res);
                            onChangeDate && onChangeDate(res);
                        }
                    }}
                    showTailIcon={false}
                />
            </div>
            <div className="rf-datetimepicker__time">
                <Timepicker
                    name={name + 'timer'}
                    disabled={disabled}
                    readOnly={readOnly}
                    className={'rf-input--right'}
                    initialValue={getValueTimes(defaultValue)}
                    isNoSecFormat={isNoSecFormat}
                    showTailIcon={isNoSecFormat}
                    invalid={invalid}
                    onChangeValue={(e) => {
                        if (readOnly) return;

                        const val = e;
                        const prev = result;
                        const res = setByTime(prev, val);
                        setResult(res);
                        onChangeDate && onChangeDate(res);
                    }}
                />
            </div>
        </div>
    );
};

export default DateTimePicker;
