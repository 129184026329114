import React, { useState } from 'react';
import MessageDialog, { IMessageDialogProps } from '@molecules/MessageDialog/MessageDialog';
import { AttachActionsService } from '@services/actions/AttachActionsService';
import { IDeleteAttachProps } from './IDeleteAttachProps';

export const DeleteAttachModal: React.FC<IDeleteAttachProps> = (props: IDeleteAttachProps) => {
    const [isBusy, setIsBusy] = useState<boolean>();
    const [errorText, setErrorText] = useState<string>();

    const getMessage = () => {
        let attachIdsSplit = props.attachId.split(',').filter((item) => item);
        if (attachIdsSplit.length <= 1) {
            let parts = props.attachId.split('_');
            let rowData = Array.isArray(props.rowData) ? props.rowData[0] : props.rowData;
            return parts.length === 1
                ? `Удалить файл '${rowData.fields.AttachmentTitle ?? rowData.fields.AttachmentFileName}'?`
                : `Удалить версию файла ${rowData.fields.AttachmentTitle ?? rowData.fields.AttachmentFileName} (версия ${parts[1]})?`;
        } else {
            return 'Удалить выбранные файлы?';
        }
    };

    const messageString: string = getMessage();

    const messageBoxProp: IMessageDialogProps = {
        header: 'Удаление',
        message: messageString,
        showOkButton: true,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        size: props.modalSize,
        okClick: () => {
            setIsBusy(true);
            let serv = new AttachActionsService(props.docId, '-1');
            return serv
                .deleteAttach(props.attachId)
                .then(() => {
                    props.completeHandler && props.completeHandler(true);
                })
                .catch((error) => setErrorText(error))
                .finally(() => {
                    setIsBusy(false);
                });
        },
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
    };

    return <MessageDialog {...messageBoxProp} isBusy={isBusy} errorText={errorText} />;
};
