import React from 'react';
import { IBaseAction } from '@models/actions/IBaseAction';
import { IActionExecutor } from '@organisms/actions/IActionExecutor';
import { ModalSize } from '@/types/ModalSize';
import { IDeleteAttachProps } from './IDeleteAttachProps';
import { DeleteAttachModal } from './DeleteAttachModal';

export class DeleteExecutor implements IActionExecutor {
    private _modalProps?: IDeleteAttachProps;

    run = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        raiseVisualElement?: () => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            docId: parentId ?? '',
            attachId: objId,
            rowData: rowData,
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'l',
            completeHandler: completeHandler,
        };
    };

    visualElement = () => {
        return this._modalProps ? <DeleteAttachModal {...this._modalProps} /> : <div />;
    };
}
