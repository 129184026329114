import React from 'react';
import { IBaseAction } from '@models/actions/IBaseAction';
import { DocBaseActionExecutor } from '@organisms/actions/DocBaseActionExecutor';
import { ModalSize } from '@/types/ModalSize';
import { IRemoveLinkExecutorProps } from './IRemoveLinkExecutorProps';
import { RemoveLinkExecutorModal } from './RemoveLinkExecutorModal';

export class RemoveLinkExecutor extends DocBaseActionExecutor {
    private _modalProps?: IRemoveLinkExecutorProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        let parts = this?._objId?.split('/');
        if (parts === undefined || parts.length != 2)
            throw new Error(`Не удалось распарсить ид документа и родители линка {parts}`);
        let ownerDocId = parts[0];
        let linkedDocId = parts[1];

        this._modalProps = {
            actKey: action.key,
            ownerDocId: ownerDocId,
            linkedDocId: linkedDocId,
            title: action.options?.title ?? '',
            message: action.options?.message ?? '',
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'l',
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <RemoveLinkExecutorModal {...this._modalProps} /> : <div />;
    };
}
