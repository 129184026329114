import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { IReportData } from '@models/reports/IReportData';
import { IReportScheme } from '@models/reports/IReportScheme';
import { IReportFormats } from '@models/reports/IReportFormats';
import { FilesService } from '@services/FilesService';

export class ReportsService {
    static async getData(folder: string, name: string): Promise<AxiosResponse<IReportData>> {
        return $api.get(`reports/data/get`, {
            params: {
                folder: folder,
                name: name,
            },
        });
    }
    static async getScheme(formName: string): Promise<AxiosResponse<IReportScheme>> {
        return $api.get(`reports/scheme/get`, {
            params: {
                name: formName,
            },
        });
    }
    static async getFormats(folder: string, name: string): Promise<AxiosResponse<IReportFormats>> {
        return $api.get(`reports/formats/get`, {
            params: {
                folder: folder,
                name: name,
            },
        });
    }
    static async build(folder: string, name: string, format: string, data: IReportData): Promise<void> {
        const config = {
            params: {
                folder: folder,
                name: name,
                format: format,
            },
        };
        await FilesService.downloadFilePost(`reports/build`, data, config);
    }
}
