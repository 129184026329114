import React, { useState } from 'react';
import MessageDialog, { IMessageDialogProps } from '@molecules/MessageDialog/MessageDialog';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import { IRemoveLinkExecutorProps } from './IRemoveLinkExecutorProps';

export const RemoveLinkExecutorModal: React.FC<IRemoveLinkExecutorProps> = (props: IRemoveLinkExecutorProps) => {
    const [errorText, setErrorText] = useState<string>();
    const [isBusy, setIsBusy] = useState<boolean>();

    const messageBoxProp: IMessageDialogProps = {
        header: props.title,
        message: props.message,
        showOkButton: true,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        size: props.modalSize,
        okClick: () => {
            setIsBusy(true);
            let service = new DocumentExecutionService(props.ownerDocId);
            return service
                .removeLink(props.linkedDocId, props.actKey)
                .then((res) => {
                    setIsBusy(false);
                    if (props.completeHandler) props.completeHandler(true);
                })
                .catch((error) => {
                    setErrorText(error);
                })
                .finally(() => {
                    setIsBusy(false);
                });
        },
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
    };

    return <MessageDialog {...messageBoxProp} errorText={errorText} isBusy={isBusy} />;
};
