import { IDictionaryData } from '@models/dictionary/IDictionaryData';
import { checkGridRowsByScript } from '@utils/dataGridUtils';
import { sendErrorMsg } from '@molecules/Errors';
import { IDictLoadedDataValidators } from '@models/Forms/IForms';

export const validateLoadedData = (data: IDictionaryData[], dataValidators?: IDictLoadedDataValidators) => {
    if (dataValidators && dataValidators.validators && dataValidators.validators.length > 0) {
        let messages: string[] = [];

        dataValidators.validators.forEach((validator) => {
            if (!checkGridRowsByScript(data, validator.script)) {
                messages.push(validator.message);
            }
        });

        if (messages.length > 0) {
            sendErrorMsg({
                message: messages,
            });
        }
    }
};
