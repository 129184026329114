import './RedirectExecutor.scss';
import React, { useState } from 'react';
import MessageDialog, { IMessageDialogProps } from '@molecules/MessageDialog/MessageDialog';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import { IRedirectExecutorProps } from './IRedirectExecutorProps';

export const RedirectExecutorModal: React.FC<IRedirectExecutorProps> = (props: IRedirectExecutorProps) => {
    const [errorText, setErrorText] = useState<string>();
    const [loading, setLoading] = useState<boolean>();

    const messageBoxProp: IMessageDialogProps = {
        header: `${props.title}`,
        message: `${props.message}`,
        showOkButton: true,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        size: props.modalSize,
        isBusy: loading,
        okClick: () => {
            setLoading(true);
            let service = new DocumentExecutionService(props.docId);
            return service
                .redirect(props.actionKey)
                .then(() => {
                    props.completeHandler && props.completeHandler(true);
                })
                .catch((error) => setErrorText(error))
                .finally(() => setLoading(false));
        },
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
    };

    return <MessageDialog {...messageBoxProp} errorText={errorText} />;
};
