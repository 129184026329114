import { sendNotification } from '@molecules/Notifications';
import { IFileData } from '@/types';

export const downloadWithLoading = ({ file, base64, id }: IFileData, setLoading: (f: boolean) => void) => {
    let url = id || base64;
    setLoading(true);
    fetch(url)
        .then((res) => res.blob())
        .then((blob: Blob) => {
            const ext = file.name.split('.');

            let fileUrl;

            if (ext[1] === 'pdf') {
                blob = blob.slice(0, blob.size, `application/${ext[1]}`);
                fileUrl = window.URL.createObjectURL(blob);

                window.open(fileUrl, '_blank');
            } else {
                const a = document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = file.name;
                a.target = '_blank';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        })
        .catch((e) => {
            sendNotification({
                message: 'Файл не обнаружен.',
                variant: 'red',
            });
        })
        .finally(() => {
            setLoading(false);
        });
};
