import { Column, DataGrid, FilterRow, Paging, Scrolling, Selection } from 'devextreme-react/data-grid';
import React, { FC, useImperativeHandle, useMemo, useRef } from 'react';
import DevExpressDataGrid from '@atoms/DevExpress/DataGrid/DevExpressDataGrid';
import CustomStore from 'devextreme/data/custom_store';
import { AddressBookService } from '@services/AddressBookService';
import { IAbookTab } from '@models/Forms/IForms';
import { IAddressBookData } from '@models/addressbook/IAddressBookData';
import { InitializedEvent as GridInitializedEvent } from 'devextreme/ui/data_grid';
import { simulateMouseClick } from '@utils/helpers';
import { IAbookSettings } from '@atoms/Abookpicker/AbookpickerModal/IAbookSettings';
import { IAbookpickerRefActions } from '@atoms/Abookpicker/IAbookpickerRefActions';
import { onCellHoverChanged } from '@utils/dataGridUtils';
import { ConfigService } from '@/configuration/services/configService';

export interface IListModeControlProp extends Omit<IAbookSettings, 'showChips'> {
    currentTab: IAbookTab;
    onSelectedRowItems: (value: IAddressBookData[]) => void;
    onSelectedKeys: (value: number[]) => void;
    getParams: (options: any) => string;
    getSelectedKeys?: (selected: number[]) => Promise<number[]>;
    controlRef: React.Ref<IAbookpickerRefActions>;
}

const ListModeControl: FC<IListModeControlProp> = (p: IListModeControlProp) => {
    const config = ConfigService.get();
    const applyFilterOnClick = config.application.applyGridFilterOnClick ?? false;

    const gridRef = useRef<DataGrid>(null);

    useImperativeHandle(p.controlRef, () => ({
        reset: () => {
            gridRef.current?.instance.refresh();
        },
        unSelect: (item: IAddressBookData) => {
            const keys = gridRef.current?.instance.getSelectedRowKeys();
            if (keys) {
                const index = keys.indexOf(item.key);
                if (index > -1) {
                    keys.splice(index, 1);
                }
                gridRef.current?.instance.selectRows(keys, false);
            }
        },
    }));

    const resetGridControlState = () => {
        gridRef?.current?.instance.state({});
    };

    const resetControlsState = () => {
        resetGridControlState();
    };

    const gridStore = useMemo(() => {
        resetControlsState();
        return new CustomStore({
            key: 'key',
            load(options: any) {
                return AddressBookService.fetchListData(p.currentTab.cache, p.selected, p.getParams(options)).then(
                    (response) => {
                        return response.data;
                    },
                );
            },
        });
    }, [p.currentTab]);

    const onGridInitialized = async (e: GridInitializedEvent<IAddressBookData, string>) => {
        if (p.selected) {
            let selectedKeys = p.selected;
            if (p.getSelectedKeys) {
                selectedKeys = await p.getSelectedKeys(p.selected);
            }

            e.component?.selectRows(
                selectedKeys.map((key: number) => key.toString()),
                true,
            );
        }
    };

    const onGridEditorPreparing = (e: any) => {
        if (applyFilterOnClick && e.parentType === 'filterRow') {
            e.editorOptions.onEnterKey = function () {
                // применение фильтра по нажатию Enter
                simulateMouseClick(e.element.querySelector('.dx-apply-button')!);
            };
        }
    };

    const onSelectionChanged = (e: any) => {
        if (!p.isMultiple) {
            if (e.currentSelectedRowKeys.length > 0) {
                let data = e.selectedRowsData.pop();
                let key = e.selectedRowKeys.pop();
                e.component.deselectRows(e.selectedRowKeys);
                p.onSelectedKeys([key]);
                p.onSelectedRowItems([data]);
            } else if (e.selectedRowKeys.length == 0) {
                p.onSelectedKeys([]);
                p.onSelectedRowItems([]);
            }
        } else {
            p.onSelectedKeys(e.selectedRowKeys!);
            p.onSelectedRowItems(e.selectedRowsData);
        }
    };

    const onRowClick = (e: any) => {
        if (e.component.getSelectedRowKeys().indexOf(e.key) > -1) {
            e.component.deselectRows([e.key]);
        } else {
            e.component.selectRows([e.key], true);
        }
    };

    const dataGridControl = (
        <DevExpressDataGrid
            key="abookGrid"
            showRowLines={true}
            columnAutoWidth={true}
            columnMinWidth={30}
            dataSource={gridStore}
            remoteOperations={true}
            hoverStateEnabled={true}
            columnHidingEnabled={false}
            showColumnHeaders={true}
            allowColumnResizing={true}
            columnResizingMode="widget"
            ref={gridRef}
            onInitialized={onGridInitialized}
            onSelectionChanged={onSelectionChanged}
            onEditorPreparing={onGridEditorPreparing}
            onRowClick={onRowClick}
            onCellHoverChanged={onCellHoverChanged}
        >
            <Selection mode="multiple" allowSelectAll={p.isMultiple} selectAllMode="page" />

            {p.currentTab.columns &&
                p.currentTab.columns.map((column, index) => {
                    return (
                        <Column
                            caption={column.name}
                            key={index}
                            dataField={column.isAttribute ? `attributes.${column.key}` : column.key}
                            width={column.width}
                            allowSorting={column.allowSort}
                            allowFiltering={column.allowFiltering}
                            filterOperations={['contains']}
                        />
                    );
                })}

            <FilterRow visible={true} applyFilter={applyFilterOnClick ? 'onClick' : 'auto'} />
            <Scrolling mode="virtual" />
            <Paging enabled={true} defaultPageSize={10} />
        </DevExpressDataGrid>
    );

    return dataGridControl;
};

export default ListModeControl;
