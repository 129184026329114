import './ScrollToTop.scss';
import React, { useEffect, useState } from 'react';
import { MdKeyboardArrowUp } from 'react-icons/md';
import Button from '@atoms/Button';
import { IButtonProps } from '@atoms/Button/Button';
import clsx from 'clsx';

export interface IScrollToTopProps extends IButtonProps {
    scrollElementRef?: React.RefObject<HTMLElement | null>;
    top?: number;
    behavior?: ScrollBehavior;
}

function scrollToTop(scrollElement: HTMLElement, behavior?: ScrollBehavior) {
    scrollElement.scrollTo({ top: 0, behavior: behavior });
}

const ScrollToTop: React.FC<IScrollToTopProps> = ({ scrollElementRef, top = 20, behavior = 'auto', ...props }) => {
    const [visible, setVisible] = useState(false);

    const scrollElement = scrollElementRef?.current ?? document.documentElement;

    useEffect(() => {
        const onScroll = () => {
            setVisible(scrollElement.scrollTop >= top);
        };
        onScroll();
        scrollElement.addEventListener('scroll', onScroll);

        return () => scrollElement.removeEventListener('scroll', onScroll);
    }, [top, scrollElement]);

    return (
        <div className={clsx('scroll-to-top', props.className)}>
            {visible && (
                <Button
                    className="scroll-to-top__button"
                    aria-label="Scroll to top"
                    buttonType="round"
                    size="l"
                    startAdornment={<MdKeyboardArrowUp size="32" />}
                    onClick={() => scrollToTop(scrollElement, behavior)}
                    {...props}
                />
            )}
        </div>
    );
};

export default ScrollToTop;
