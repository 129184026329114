import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICanView, IUser } from '@models/IUser';

export interface IAuthState {
    isAuth: boolean;
    isLoading: boolean;
    isLoggingIn: boolean;
    isSmsOtp: boolean;
    error: string;
    user: IUser;
    accessToken: string | null;
    refreshToken: string | null;
    returnUrl: string | null;
}

const initialState: IAuthState = {
    isAuth: false,
    returnUrl: null,
    isLoading: true,
    isLoggingIn: false,
    isSmsOtp: false,
    error: '',
    user: {
        canView: {} as ICanView,
    } as IUser,
    accessToken: null,
    refreshToken: null,
};

export const AuthSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsLoggingIn: (state, action: PayloadAction<boolean>) => {
            state.isLoggingIn = action.payload;
        },
        setIsSmsOtp: (state, action: PayloadAction<boolean>) => {
            state.isSmsOtp = action.payload;
        },
        setError: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
        },
        successLogin: (state, action: PayloadAction<IAuthState>) => {
            state.refreshToken = action.payload.refreshToken;
            state.accessToken = action.payload.accessToken;
            state.isAuth = true;
            state.returnUrl = action.payload.returnUrl;
            state.error = '';
            state.user.id = action.payload.user.id;
            state.user.employeeId = action.payload.user.employeeId;
            state.user.newEmployeeId = action.payload.user.newEmployeeId;
            state.user.employeeIksId = action.payload.user.employeeIksId;
            state.user.fullName = action.payload.user.fullName;
            state.user.name = action.payload.user.name;
            state.user.firstName = action.payload.user.firstName;
            state.user.lastName = action.payload.user.lastName;
            state.user.middleName = action.payload.user.middleName;
            state.user.position = action.payload.user.position;
            state.user.department = action.payload.user.department;
            state.user.departmentId = action.payload.user.departmentId;
            state.user.canUserDepartmentEdit = action.payload.user.canUserDepartmentEdit;
            state.user.canUserDepartmentView = action.payload.user.canUserDepartmentView;
            state.user.subdivision = action.payload.user.subdivision;
            state.user.subdivisionInn = action.payload.user.subdivisionInn;
            state.user.workGroups = action.payload.user.workGroups;
            state.user.accessGroups = action.payload.user.accessGroups;
            state.user.email = action.payload.user.email;
            state.user.username = action.payload.user.username;
            state.user.login = action.payload.user.login;
            state.user.role = action.payload.user.role;
            state.user.phone = action.payload.user.phone;
            state.user.canView.settings = action.payload.user.canView.settings;
            state.user.canView.usersActivation = action.payload.user.canView.usersActivation;
            state.user.canView.adminPanel = action.payload.user.canView.adminPanel;
            state.user.canView.documentation = action.payload.user.canView.documentation;
            state.user.canView.support = action.payload.user.canView.support;
            state.user.canView.signOut = action.payload.user.canView.signOut;
            state.user.canView.editWidgets = action.payload.user.canView.editWidgets;
            state.user.canView.setAssistantSimple = action.payload.user.canView.setAssistantSimple;
            state.user.canView.notificationFiltering = action.payload.user.canView.notificationFiltering;
            state.user.canView.accessGroups = action.payload.user.canView.accessGroups;
            state.user.canView.mailDeputy = action.payload.user.canView.mailDeputy;
            state.user.canView.adminPanel = action.payload.user.canView.adminPanel;
            state.user.canView.auditor = action.payload.user.canView.auditor;
            state.user.canView.readonlyAuditor = action.payload.user.canView.readonlyAuditor;
        },
        updateUserDepartment: (state, action: PayloadAction<{ depName: string; depId: string }>) => {
            state.user.department = action.payload.depName;
            state.user.departmentId = action.payload.depId;
        },
        successLogout: (state) => {
            state.isAuth = false;
            state.accessToken = null;
            state.refreshToken = null;
            state.user = {} as IUser;
            state.user.canView = {} as ICanView;
        },
        resetReturnUrl: (state) => {
            state.returnUrl = null;
        },
    },
});

export const { setIsLoading, setIsLoggingIn, successLogin, updateUserDepartment, successLogout, setError } =
    AuthSlice.actions;

export default AuthSlice.reducer;
