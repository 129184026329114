import React from 'react';
import { IActivityAction, IBaseAction } from '@models/actions/IBaseAction';
import { ActivityBaseActionExecutor } from '@organisms/actions/ActivityBaseActionExecutor';
import { getActivityIdFromCompositeId } from '@utils/helpers';
import { ModalSize } from '@/types/ModalSize';
import { IRequiredValidation } from '@models/actions/IRequiredValidation';
import { IApproveActionModel, IBaseApproveProps } from './IBaseApproveProps';
import { BaseApproveModal } from './BaseApproveModal';

export abstract class BaseApproveExecutor extends ActivityBaseActionExecutor {
    private _model?: IBaseApproveProps;

    protected approveModel?: IApproveActionModel;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        let act = action as IActivityAction;
        if (this.approveModel) {
            this._model = {
                actionKey: action.key,
                displayName: action.displayName,
                approveModel: this.approveModel,

                okButtonText: action.options?.okButtonText ?? 'ОК',
                cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
                modalSize: modalSize ?? action.options?.modalSize ?? 'xxl',
                docFlow: act?.flowName,
                docId: act.docId,
                objId: objId,
                activityId: getActivityIdFromCompositeId(objId),
                actName: act?.actName ?? rowData?.fields?.ActivityName,
                attachFiles: action.options?.attachFiles ?? false,
                attachRequired: action.options?.attachRequired ?? false,
                attachRequiredValidation: action.options?.attachRequiredValidation as IRequiredValidation[],
                completeHandler: completeHandler,
            };
        }
    };

    visualElementInner = () => {
        return this._model ? <BaseApproveModal {...this._model} /> : <></>;
    };
}
