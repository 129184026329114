import { ActivityActionTypes } from '@models/actions/ActivityActionTypes';
import { IBaseAction } from '@models/actions/IBaseAction';
import { IActionExecutor } from './IActionExecutor';
import { TakeToWorkExecutor } from './activityExecutors/TakeToWork/TakeToWorkExecutor';
import { ApproveWithSignExecutor } from './activityExecutors/ApproveWithSign/ApproveWithSignExecutor';
import { ReworkWithSignExecutor } from './activityExecutors/ReworkWithSign/ReworkWithSignExecutor';
import { RejectWithSignExecutor } from './activityExecutors/RejectWithSign/RejectWithSignExecutor';
import { ApproveExecutor } from './activityExecutors/Approve/ApproveExecutor';
import { ReworkExecutor } from './activityExecutors/Rework/ReworkExecutor';
import { RejectExecutor } from './activityExecutors/Reject/RejectExecutor';
import { CompleteExecutor } from './activityExecutors/Complete/CompleteExecutor';
import { RequestAddApprovalExecutor } from './activityExecutors/RequestAddApproval/RequestAddApprovalExecutor';
import { ActivityBatchEditingExecutor } from './activityExecutors/ActivityBatchEditing/ActivityBatchEditingExecutor';
import { ActivityBatchEditingTableExecutor } from './activityExecutors/ActivityBatchEditingTable/ActivityBatchEditingTableExecutor';
import { ReplacePerformerExecutor } from './activityExecutors/ReplacePerformer/ReplacePerformerExecutor';
import { RollbackPerformerExecutor } from './activityExecutors/RollbackPerformer/RollbackPerformerExecutor';
import { DelegateExecutor } from './activityExecutors/Delegate/DelegateExecutor';
import { RollbackDelegateExecutor } from './activityExecutors/RollbackDelegate/RollbackDelegateExecutor';
import { ResolveExceptionExecutor } from './activityExecutors/ResolveException/ResolveExceptionExecutor';
import { ChangePerformerExecutor } from './activityExecutors/ChangePerformer/ChangePerformerExecutor';

export class ActivityActionsExecFactory {
    public static allExecutors: { [actType: number]: () => IActionExecutor } = {
        [ActivityActionTypes[ActivityActionTypes.TakeToWork]]: () => new TakeToWorkExecutor(),
        [ActivityActionTypes[ActivityActionTypes.ApproveWithSign]]: () => new ApproveWithSignExecutor(),
        [ActivityActionTypes[ActivityActionTypes.ReworkWithSign]]: () => new ReworkWithSignExecutor(),
        [ActivityActionTypes[ActivityActionTypes.RejectWithSign]]: () => new RejectWithSignExecutor(),
        [ActivityActionTypes[ActivityActionTypes.Approve]]: () => new ApproveExecutor(),
        [ActivityActionTypes[ActivityActionTypes.Rework]]: () => new ReworkExecutor(),
        [ActivityActionTypes[ActivityActionTypes.Reject]]: () => new RejectExecutor(),
        [ActivityActionTypes[ActivityActionTypes.Complete]]: () => new CompleteExecutor(),
        [ActivityActionTypes[ActivityActionTypes.RequestAdditionalApproval]]: () => new RequestAddApprovalExecutor(),
        [ActivityActionTypes[ActivityActionTypes.ActivityBatchEditing]]: () => new ActivityBatchEditingExecutor(),
        [ActivityActionTypes[ActivityActionTypes.ActivityBatchEditingTable]]: () =>
            new ActivityBatchEditingTableExecutor(),
        [ActivityActionTypes[ActivityActionTypes.ReplacePerformer]]: () => new ReplacePerformerExecutor(),
        [ActivityActionTypes[ActivityActionTypes.RollbackPerformer]]: () => new RollbackPerformerExecutor(),
        [ActivityActionTypes[ActivityActionTypes.Delegate]]: () => new DelegateExecutor(),
        [ActivityActionTypes[ActivityActionTypes.RollbackDelegate]]: () => new RollbackDelegateExecutor(),
        [ActivityActionTypes[ActivityActionTypes.ResolveException]]: () => new ResolveExceptionExecutor(),
        [ActivityActionTypes[ActivityActionTypes.ChangePerformer]]: () => new ChangePerformerExecutor(),
    };

    static GetActionExecutor(act: IBaseAction, throwError: boolean = true): IActionExecutor | undefined {
        let createExecutor = this.allExecutors[act.type];
        if (!createExecutor) {
            if (!throwError) return undefined;

            throw new Error(`Не обнаружен ActionExecutor для типа ${act.type}`);
        }

        return createExecutor();
    }
}
