import { ActFinishType } from '@/types/ActFinishType';
import { BaseWithSignExecutor } from '../BaseWithSign/BaseWithSignExecutor';

export class ApproveWithSignExecutor extends BaseWithSignExecutor {
    constructor() {
        super();

        this.approveModel = {
            commentIsRequired: false,
            actType: ActFinishType.Approve,
        };
    }
}
