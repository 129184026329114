import React from 'react';
import { IBaseAction } from '@models/actions/IBaseAction';
import { IActionExecutor } from '@organisms/actions/IActionExecutor';
import { ModalSize } from '@/types/ModalSize';
import { IEditAttachProps } from './IEditAttachProps';
import { EditAttachModal } from './EditAttachModal';

export class EditExecutor implements IActionExecutor {
    private _modalProps?: IEditAttachProps;

    run = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        raiseVisualElement?: () => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            docId: parentId ?? '',
            attachId: objId,
            rowData: rowData,
            options: action.options,
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'm',
            completeHandler: completeHandler,
        };
    };

    visualElement = () => {
        return this._modalProps ? <EditAttachModal {...this._modalProps} /> : <div />;
    };
}
