import '../ActivityActions.scss';
import React, { useEffect, useState } from 'react';
import { IAddressBookData } from '@models/addressbook/IAddressBookData';
import { IAbookOptions } from '@models/Forms/IAbookOptions';
import { ActivityExecutionService } from '@services/actions/ActivityExecutionService';
import { IFormValues } from '@models/Forms/IForms';
import { getABookFormValues } from '@utils/abookUtils';
import { useForm } from 'react-hook-form';
import ModalContainer, { IModalContainerProps } from '@molecules/ModalContainer/ModalContainer';
import { IDelegateFormData } from './IDelegateFormData';
import { IDelegateProps } from './IDelegateProps';
import AbookpickerControl from '@controls/AbookpickerControl';
import TextareaControl from '@controls/TextareaControl';

export const DelegateModal: React.FC<IDelegateProps> = (props: IDelegateProps) => {
    const [comment, setComment] = useState<string>();
    const [executor, setExecutor] = useState<IAddressBookData>();
    const [abookOptions, setAbookOptions] = useState<IAbookOptions>();
    const [errorText, setErrorText] = useState<string>();
    const [loading, setLoading] = useState<boolean>();
    const [formValues, setFormValues] = useState<string>('{}');
    const activated = React.useRef(false);

    const serv = new ActivityExecutionService();

    const getFormValues = async (fValues?: IFormValues) => {
        const vals = await getABookFormValues(props.docId, fValues);
        if (activated.current && vals) {
            setFormValues(vals);
        }
    };

    const onSubmit = (form: IDelegateFormData) => {
        if (!form?.executor) {
            setErrorText('Не указан исполнитель');
            return;
        }

        setLoading(true);
        return serv
            .saveDelegate(props.actionKey, props.activityId, form?.executor, form?.comment)
            .then((res) => {
                setLoading(false);

                props.completeHandler && props.completeHandler(true);
            })
            .catch((err) => {
                setErrorText(err);
            })
            .finally(() => setLoading(false));
    };

    const formState = useForm<IDelegateFormData>({
        mode: 'onBlur', // "onChange"
    });

    useEffect(() => {
        setLoading(true);
        activated.current = true;

        serv.getDelegateModel(props.actionKey, props.activityId)
            .then((res) => {
                setAbookOptions(res.data);
                getFormValues(res.data?.formValues);
                setLoading(false);
            })
            .catch((err) => {
                setErrorText('Ошибка получения данных от сервера.');
            })
            .finally(() => setLoading(false));
        return () => {
            activated.current = false;
        };
    }, []);

    const modalContainerProps: IModalContainerProps = {
        header: props.title,
        size: props.modalSize,
        showOkButton: true,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        isBusy: loading,
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
        errorText: errorText,
        formMethods: formState,
        onSubmit: onSubmit,
        okButtonDisabled: false,
    };

    return (
        <ModalContainer {...modalContainerProps}>
            <AbookpickerControl
                label="Исполнитель"
                placeholder="Исполнитель"
                isMultiple={false}
                title="Исполнитель"
                displayValue={executor?.fullName ?? ''}
                formValues={formValues}
                name="executor"
                tabsSettings={abookOptions?.tabs}
                externalSearch={abookOptions?.externalSearch}
                required
                rules={{ required: 'Обязательное поле' }}
                formState={formState.formState}
                control={formState.control}
                showChips={false}
                onSetValues={(value: IAddressBookData[]) => {
                    setExecutor(value[0]);
                }}
            />

            <TextareaControl
                label="Комментарий"
                rows={6}
                readOnly={false}
                required={true}
                rules={{ required: 'Обязательное поле' }}
                name="comment"
                formState={formState.formState}
                control={formState.control}
                onValueChange={(val: any) => {
                    setComment(val);
                }}
            />
        </ModalContainer>
    );
};
